import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		pagination: { data: [], current_page: 1 },
		current: {},
		loadings: { index: false, show: false, store: false, update: false, delete: false }
	},
	mutations: {
		SET_PAGINATED_SECTORSS: (state, pagination) => state.pagination = pagination,
		SET_SECTORS: (state, current) => state.current = current,
		TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
		DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
	},
	actions: {
		index({ commit }, page = {}) {
			commit('TOGGLE_LOADING', 'index')
			return this.$repository.sectors
				.index(page)
				.then(({ data }) => {
					commit('SET_PAGINATED_SECTORSS', data)
					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'index'))
		},
		show({ commit }, id) {
			commit('TOGGLE_LOADING', 'show')
			return this.$repository.sectors
				.show(id)
				.then(({ data }) => {
					commit('SET_SECTORS', data)
					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'show'))
		},
		store({ commit }, body) {
			commit('TOGGLE_LOADING', 'store')
			return this.$repository.sectors
				.store(body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful registration`,
						text: `Successful registration`,
						description: `Successful registration`,
						position: `top-right`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'store'))
		},
		async update({ commit }, body) {
			commit('TOGGLE_LOADING', 'update')
			return await this.$repository.sectors
				.update(body.id, body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful update`,
						text: `Successful update`,
						description: `Successful update`,
						position: `top-right`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'update'))
		},
		async delete({ dispatch, commit }, { id }) {
			if (confirm('¿Do you want to delete this?')) {
				commit('TOGGLE_LOADING', 'delete')
				return await this.$repository.sectors
					.delete(id)
					.then(({ data }) => {
						dispatch('fetchSectors', {})
						return data
					})
					.finally(() => commit('TOGGLE_LOADING', 'delete'))

			} else {
				return false
			}
		}
	},
	getters: {
	}
}