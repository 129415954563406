<template>
    <div>
        <div class="flex justify-end mb-4">
            <sim-button align="right" :to="{ name: 'users.create' }">
                <i class="material-icons">add</i> {{l.app.create_user}}
            </sim-button>
        </div>
        <sim-table
            :loading="loading"
            :columns="columns"
            :toolbar-per-page="$route.query.limit"
            @toolbar:per-page="searchData({ limit: $event })"
            @sort-change="orderBy({ order_by: `${$event.key},${$event.direction}` })"
            :source="pagination.data">
            <template #filter:profile>
                <sim-input v-model="profile" />
            </template>
            <template #filter:email>
              <sim-input v-model="email"/>
            </template>
            <!--@input="searchData({ plan: $event.target.value })" $event.target.value-->
            <template #filter:paying>
                <select
                    class="py-3 px-4 appearance-none font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    @change="handleSearch($event.target.value)">
                    <option value="">-- All --</option>
                    <option value="paying">Paying</option>
                    <option value="notpaying">Not Paying</option>
                </select>
            </template>

            <template #td:profile="{ record }">
                <div v-if="record.item.profile !== null" style="display: flex;">
                    <sim-img-blog
                        :img_url="record.item.profile.avatar_url" 
                        @editImg="addImg($event, record.item.profile.id ,record.item.profile.avatar_url)" 
                        @editImgVie="addImgPV($event, record.item.profile.id,record.item.profile.avatar_url)" 
                        :custom_class="'u-iten-img'"
                        :custom_class2="'PP_circular_gradient'"
                        :detailTex="''"/>
                   
                    <span class="u-iten-text-user row" style="width: 100%;">
                        <span class="name col-12" v-if="record.item.profile !== undefined && record.item.profile !== null">
                            {{ record.item.profile.name }} {{ record.item.profile.last_name }}
                            <small class="col-12" style="font-weight: normal;">
                                {{ record.item.profile.email ? record.item.profile.email :'' }}
                            </small>
                        </span>
                    </span> 
                </div>
                <div v-else class="flex space-x-2 flex-wrap">
                    -- Unregistered profile yet --
                </div>
            </template>
            <template #td:paying="{ record }">
              <div v-if="record.item.current_plan !== null">
                {{ 'Paying' }}
              </div>
              <div v-else>
                {{ 'Not Paying' }}
              </div>
            </template>
          <template #td:allStars="{ record }">
            <div v-if="record.item.profile !== null">
              {{ record.item.profile.is_featured ? 'All Stars' : '---' }}
            </div>
            <div v-else>
              {{ '---' }}
            </div>
          </template>
          <template #td:price="{ record }">
            <div v-if="record.item.current_plan !== null">
              {{ record.item.current_plan ? `${record.item.current_plan.price_e2 / 100}${record.item.current_plan.currency.symbol}` : '---' }}
            </div>
            <div v-else>
              {{ '---' }}
            </div>
          </template>
          <template #td:actions="{ record }">
              <div class="flex space-x-2">
                  <label class="btn btn-label-primary text-uppercase" @click="handleUser(record.item)" :title="l.app.Show">
                      <i class="material-icons">visibility</i>
                  </label>
                  <router-link :to="{ name: 'users.edit', params: { id: record.item.id } }" class="btn btn-label-warning text-uppercase" :title="l.app.Edit">
                      <i class="material-icons">edit</i>
                  </router-link>
                  <label @click="deleteUser(record.item.id).then(() => searchData())" class="btn btn-label-danger text-uppercase" :title="l.app.Remove">
                      <i class="material-icons">delete</i>
                  </label>
                  <label v-if="record.item.role !== 'admin'" :title="l.app.user_to_admin"  @click="makeAdmin(record.item)" class="btn btn-label-primary text-uppercase" >
                      <i class="material-icons">account_circle</i>
                  </label>
              </div>
          </template>
        </sim-table>
       
        <sim-pagination v-if="pagination.data.length > 0" :data="pagination" @change="changePage" />

        <sim-Modal :show="modal" @close="modal = false" type="a-modal view view-modal">
          <h3 slot="header">

            <b
                v-if="!getUserValue.name & !getUserValue.last_name"
                class="col-12 title-detail-transaction"
                style="font-size: 20px; margin-bottom: 20px;">
              -- Unregistered profile yet --
            </b>
            <b v-else class="col-12 title-detail-transaction" style="font-size: 26px; margin-bottom: 20px;">
              {{ (getUserValue.name ? getUserValue.name : '') + ' ' + (getUserValue.last_name ? getUserValue.last_name : '') }}
            </b>
          </h3>
          <div>
            <div class="row">
              <div class="col">
                <div class="mb-4" v-if="getUserValue.work_email">
                  <h5 class="text-bold">Work email</h5>
                  <div class="ml-2">{{ getUserValue.work_email }}</div>
                </div>
                <div class="mb-4" v-if="getUserValue.email">
                  <h5 class="text-bold">Personal email</h5>
                  <div class="ml-2">{{ getUserValue.email }}</div>
                </div>
                <div class="mb-4" v-if="getUserValue.job_title">
                  <h5 class="text-bold">Current job title</h5>
                  <div class="ml-2">{{ getUserValue.job_title }}</div>
                </div>
                <div class="mb-4" v-if="getUserValue.self_enployed">
                  <h5 class="text-bold">Self-employed</h5>
                  <div class="ml-2">{{ getUserValue.self_enployed ? 'Yes' : 'No' }}</div>
                </div>
                <div class="mb-4" v-if="getUserValue.employer && !getUserValue.self_enployed">
                  <h5 class="text-bold">Current employer</h5>
                  <div class="ml-2">{{ getUserValue.employer }}</div>
                </div>
                <div class="mb-4" v-if="getUserValue.charity">
                  <h5 class="text-bold">Charity</h5>
                  <div class="ml-2">{{ getUserValue.charity }}</div>
                </div>
              </div>
              <div class="col-auto">
                <div style="width: 100px; height: 100px; border-radius: 50%;" class="mr-2 default_img">
                  <img
                      v-if="getUserValue.avatar_url"
                      :src="getUserValue.avatar_url"
                      alt="profile_img"
                      style="overflow: hidden; border-radius: 50%;"
                  />
                </div>
              </div>
            </div>
          </div>
        </sim-Modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
    data: () => ({
        modal: false,
        picture:'',
        email: '',
        profile: '',
        limit: 10,
        search:'',
        query: {
            page: 1,
            limit: 10,
            with: 'currentPlan',
            plan: 'premium',
            // currentPlan: 'has:1',
            // 'currentPlan-price_e2': 'gtr:0'
        },
        selectedUser: {},
        searchDebounce: false,
    }),
    computed: {
        ...mapState({
            pagination: state => state.users.pagination,
            loading: state => state.users.loadings.index,
            session: state => state.session.user
        }),
        ...mapGetters({
            hasError: "hasError",
            error: "error",
            l: "lang",
        }),
        columns() {
            return [
                { title: this.l.app.profile, slot: 'profile', key: 'profile', filter: true, sortable: true, alignment: 'right', width: '242px' },
                { title: this.l.app.work_email, key: 'email', filter: true, sortable: true },
                { title: 'paying', key: 'paying', slot: 'paying', filter: true },
                { title: 'All Stars', key: 'allStars', slot: 'allStars', filter: false },
                { title: 'plan price', key: 'price', slot: 'price', filter: false },
                { title: this.l.app.Actions, slot: 'actions', alignment: 'right', width: '242px' }
            ]
        },
        getUserValue() {
          return {
            work_email: this.selectedUser?.email,
            email: this.selectedUser?.profile?.email,
            name: this.selectedUser?.profile?.name,
            last_name: this.selectedUser?.profile?.last_name,
            avatar_url: this.selectedUser?.profile?.avatar_url,
            employer: this.selectedUser?.profile?.employer,
            job_title: this.selectedUser?.profile?.job_title,
            charity: this.selectedUser?.profile?.charity?.name,
            allStars: this.selectedUser?.profile?.is_featured,
            self_enployed: this.selectedUser?.profile?.preferences?.is_self_employed
          }
        }
    },
    methods: {
        ...mapActions({
            fetchUsers: 'users/index',
            fetchUsersProfiles: 'users/profiles',
            updateUsers: 'users/updateDataUser',
            deleteUser: 'users/delete',
            updateProfilePicture: 'users/updateProfilePicture'
        }),
        handleUser(user) {
          console.log(user)
          this.selectedUser = user
          this.modal = true
        },
        handleSearch(input) {
          switch (input) {
            case 'paying': {
              this.query = { ...this.$route.query, plan: 'premium' }
              this.fetchUsers({ page: 1, limit: 10, with: 'currentPlan', plan: 'premium' })
              // currentPlan: 'has:1',
              // 'currentPlan-price_e2': 'gtr:0'
              break
            }
            case 'notpaying': {
              this.query = { ...this.$route.query, plan: 'public' }
              this.fetchUsers({ page: 1, limit: 10, with: 'currentPlan', plan: 'public' })
              break
            }
            default: {
              this.query = { page: 1, limit: 10, with: 'currentPlan' }
              // const query = Object.assign({}, this.$route.query)
              // query.page = 1
              // query.limit = 10
              // query.with = 'currentPlan'
              // console.log(this.$route.query)
              this.$router.push({ query: { page: 1, limit: 10, with: 'currentPlan', plan: 'premium' }})
              // this.$route.query = { ...this.query }
              // this.fetchUsers({ page: 1, limit: 10, with: 'currentPlan' })
              this.fetchUsers()
            }
          }
        },
        clearFilter(){
            this.fetchUsers(this.$queryUtils.withoutInvalidValues({}))
        },
        makeAdmin(user){
            if (confirm('convert this user to admin?')) {
                this.updateUsers({ 
                        id: user.id,  
                        role: 'admin'
                    }).then(() => this.searchData())
            }
        },
        addImg(img,id/*, picture*/){
            const data = new FormData();
            data.append('image', img);
            this.updateProfilePicture(
                {
                    id:id,
                    img:data 
                }
            )
        },
        addImgPV(img,id/*, picture*/){
            let userUpdate = this.pagination.data.findIndex($0 => parseInt($0.profile.id,10) === parseInt(id,10))
            this.pagination.data[userUpdate].profile.avatar_url= img
        },
        changePage(page) {
            const query = this.$queryUtils.withoutInvalidValues({ ...this.query, page: page});
            // this.$route.query.page = query.page
            this.query = query;
            this.$router.push({ query: { ...query }})
            this.fetchUsers(query);
        },
        orderBy(_query) {
            const query = this.$queryUtils.withoutInvalidValues({ ...this.query, order_by:_query,page: 1});
          this.query = query;
          this.fetchUsers(query);
        },
        searchData(_query = {}) {
           // const query = this.$queryUtils.withoutInvalidValues({ ...this.query, search: e ? e : this.search,page: 1});
            const query = this.$queryUtils.withoutInvalidValues({
                ...this.query,
                ..._query,
                page: 1
            });
            this.query = query;

            this.fetchUsers(query).then(() => {
                this.$router.replace({ query })
                    .catch(() => null)
            });
        },
        searchData2(_query = {}) {
            console.log("aa",_query)
           // const query = this.$queryUtils.withoutInvalidValues({ ...this.query, search: e ? e : this.search,page: 1});
           /* const query = this.$queryUtils.withoutInvalidValues({
                ...this.query,
                ..._query,
                page: 1
            });
            this.query = query;


            
            this.fetchUsers(query).then(() => {
                this.$router.replace({ query })
                    .catch(() => null)
            });*/
        },
    },
    mounted() {
        this.searchData();
    },
    created() {
        this.query = {...this.query, ...this.$route.query, query: ''}
    },
    watch: {
        email(e){
            if(this.searchDebounce) {
              clearTimeout(this.searchDebounce)
            }

            this.searchDebounce = setTimeout(() => {
              this.fetchUsersProfiles({ 'user-email': `like:${e}`, page: 1, with:'user' });
            }, 600)
            //this.searchData({ email: e });
            //this.searchData({ search: e, page: 1 });
        },
        profile( e ){
            if(this.searchDebounce) {
              clearTimeout(this.searchDebounce)
            }

            this.searchDebounce = setTimeout( () => {
              this.fetchUsersProfiles({ search: e, page: 1, with:'user' })
            }, 600)
        }
    },
}
</script>

<style lang="scss">
.view-modal {
  width: 50% !important;
  height: 50% !important;
  top: 20% !important;
  left: 25% !important;
}
.default_img {
  background: linear-gradient(97deg, rgb(27, 112, 183) 0%, rgb(229, 29, 138) 100%);
  //width: 100px;
  //height: 100px;
  //border-radius: 50%;
}
</style>

