<template>
    <div class="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="75" style="">
     <router-link :to="'/dashboard'" >
            <div class="kt-aside__brand-logo">
                <slot />
            </div>
       </router-link>
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `sidebar-logo`,
    props: {},
    data: () => ({
        prefix
    })
}
</script>