import { Role } from '@/constants';
import router from '@/router';
import Vue from 'vue';
const { teacher } = Role
//
export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        all: [],
        user: {
            profile: {
                name: "",
                last_name: "",
                address: "",
                job_title: "",
                employer: "",
            },
        },
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_USERS: (state, pagination) => state.pagination = pagination,
        SET_USER: (state, user) => state.user = user,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        index({ commit }, page ) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.users
                .index(page)
                .then(({ data }) => {
                    commit('SET_PAGINATED_USERS', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        profiles({ commit }, page ) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.users.profiles(page)
                .then(({data}) => {
                    let aux = [];
                    data.data.forEach( user => {
                        let data = user.user;
                        aux.push ( data );
                    });
                    data.data = aux;
                    commit('SET_PAGINATED_USERS', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        show({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.users
                .show(id)
                .then(({ data }) => {
                    commit('SET_USER', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, new_user) {
            commit('TOGGLE_LOADING', 'store')
            this.$repository.users
                .store(new_user)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el administrador con éxito`,
                        text: `Se ha creado el administrador con éxito`,
                        description: `Se ha creado el administrador con éxito`,
                        position: `top-right`
                    })
                    router.push({ name: 'users' })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        },
        updateDataUser({ commit }, new_user) {
            commit('TOGGLE_LOADING', 'update')
            return this.$repository.users
                .updateDataUser(new_user.id, new_user)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `The user has been updated successfully`,
                        text: `The user has been updated successfully`,
                        description: `The user has been updated successfully`,
                        position: `top-right`
                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        create({ commit }, new_user) {
            commit('TOGGLE_LOADING', 'create')
            this.$repository.users
                .create( new_user)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `The user has been create successfully`,
                        text: `The user has been create successfully`,
                        description: `The user has been create successfully`,
                        position: `top-right`
                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'create'))
        },
        createProfile({ commit }, new_user) {
            commit('TOGGLE_LOADING', 'createProfile')
            this.$repository.users
                .createProfile(new_user)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `The manager has been create successfully`,
                        text: `The manager has been create successfully`,
                        description: `The manager has been create successfully`,
                        position: `top-right`
                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'createProfile'))
        },
        async update({ commit }, new_user) {
            commit('TOGGLE_LOADING', 'update')
            return await this.$repository.users
                .update(new_user.id, new_user)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `The user has been updated successfully`,
                        text: `The user has been updated successfully`,
                        description: `The user has been updated successfully`,
                        position: `top-right`
                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        updateProfilePicture({ commit }, user ) {
            commit('TOGGLE_LOADING', 'updateImg')
            this.$repository.users
                .updateImg(user.id, user.img)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `The user has been updated successfully`,
                        text: `The user has been updated successfully`,
                        description: `The user has been updated successfully`,
                        position: `top-right`
                    })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'updateImg'))
        },
        delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                if (confirm('Do you want to remove this administrator?')) {
                    commit('TOGGLE_LOADING', 'delete')
                    return this.$repository.users
                        .delete(id)
                        .then(({ data }) => resolve(data))
                        .catch(err => reject(err))
                        .finally(() => commit('TOGGLE_LOADING', 'delete'))
                } else {
                    reject()
                }
            })
        }
    },
    getters: {
        teachers: state => state.pagination.data.filter(user => user.type === teacher),
        userCanDo: (state, getters, rootState, rootGetters) => action => {
            return rootGetters["session/isAuthenticated"]
            && rootState.session.user.permissions.hasOwnProperty(action)
            && rootState.session.user.permissions[action]
        }
    }
}