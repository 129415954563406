<template>
    <sim-layout id="app" class="app_wrapper">
        <sim-navbar>
            <ul class="kt-menu__nav kt-header-menu">
                <li v-for="(section, n) in breadcrumbs" :key="n" :class="['kt-menu__item', 'kt-menu__item--submenu', 'kt-menu__item--rel', n+1 === breadcrumbs.length && 'kt-menu__item--active']" data-ktmenu-submenu-toggle="click" aria-haspopup="true">
                    <router-link :to="section.route" class="kt-menu__link kt-menu__toggle">
                        <span class="kt-menu__link-text upper">
                            {{ section.loading ? 'Buscando...' : section.label }}
                        </span>
                        <i  v-if="n+1 < breadcrumbs.length && breadcrumbs.length > 0"  class="kt-menu__hor-arrow material-icons">keyboard_arrow_right</i>
                    </router-link>
                </li>
            </ul>
            <div style="display: flex; align-items: center;">
                <div style="margin-right: 10px;">
                </div>
                <sim-dropdown right width="240px" offset-top="25px">
                    <div class="d-flex d-flex-row" style="border-left: 1px solid #ccc; padding-left: 10px;">
                        <div class="mr-2 text-right">
                            <b class="user-dropdown-name v-align-super">{{ user.email }}</b> <br>
                            <span class="user-dropdown-role v-align-super">Administrador</span>
                        </div>
                    </div>
                    <template #menu>
                        <sim-dropdown-item style="" @click="mylang = 'es'">
                            <template #icon>
                                <i v-if="l === 'es'" class="material-icons" style="color: #4CAF50;">g_translate</i>
                                <i v-else class="material-icons" style="color: #d9e0da;">g_translate</i>
                            </template>
                            <template #label>
                                {{lang.app.languages[0].name}}
                            </template>
                        </sim-dropdown-item>
                        <sim-dropdown-item @click="mylang = 'en'">
                            <template #icon>
                                <i v-if="l === 'en'" class="material-icons" style="color: #4CAF50;">g_translate</i>
                                <i v-else class="material-icons" style="color: #d9e0da;">g_translate</i>
                            </template>
                            <template #label>
                                {{lang.app.languages[1].name}}
                            </template>
                        </sim-dropdown-item>
                    </template>
                    <template #footer>
                        <label  @click="logout" class="btn btn-label-danger text-uppercase">
                            <i class="material-icons">power_settings_new</i> 
                            {{lang.app.sign_off}}
                        </label>
                    </template>
                </sim-dropdown>
            </div>
        </sim-navbar>


        <sim-sidebar accordion >
            <sim-sidebar-logo>
                <playpen-logo />
            </sim-sidebar-logo>
            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" class="kt-aside-menu">
                    <ul class="kt-menu__nav ">
                        <template v-for="(n, k) in lang.app.menu" >
                            <sim-sidebar-item v-if="n.visibility" :to="{name:n.url}" :key="k">
                                <template #icon>
                                    <i class="kt-menu__link-icon material-icons">{{ n.icon}}</i>
                                </template>
                                <template #label>
                                    {{n.text}}
                                </template>
                                <template v-if="n.url === null" #menu>
                                    <sim-sidebar-item v-for="(s_n, k_m) in  n.menu" :key="`k_m_${k_m}`" :to="{name:s_n.url}">
                                        <template #icon>
                                            <i class="kt-menu__link-icon material-icons">{{ s_n.icon}}</i>
                                        </template>
                                        <template #label>
                                            {{s_n.text}}
                                        </template>
                                    </sim-sidebar-item>
                                </template>
                            </sim-sidebar-item>
                        </template>
                    </ul>
                </div>
            </div>
        </sim-sidebar>

        <div id="all_app" style="background: #F2F2F2; grid-area: main; padding: 28px 32px;">
            <router-view />
        </div>
        <!-- <footer class="app_footer" v-html="lang.app.footer.replace(/#year#/, new Date().getFullYear())" /> -->
    </sim-layout>
</template>

<script>
import { Role } from '@/constants'
import { mapGetters, mapState, mapActions } from 'vuex';
import playpenLogo from '@/components/playpenLogoW.vue';
export default {
    data: () => ({
        roles: Role,
        mylang: 'en'
    }),
    computed: {
        ...mapState({
            l: state => state.lang,
            user: state => state.session.user,
            // navbarTitle: state => state.navbarTitle
        }),
        ...mapGetters({
            currentLang: 'currentLang',
            isAuthenticated: 'session/isAuthenticated',
            lang: 'lang'
        }),
        breadcrumbs() {

            let breadcrumbs = this.$route.meta?.breadcrumbs
            const context = { route: this.$route, router: this.$router, store: this.$store, lang: this.lang }

            if (breadcrumbs) {
                if (typeof breadcrumbs === 'function') {
                    breadcrumbs = breadcrumbs(context)
                }
            } else {
                breadcrumbs = []
            }

            return breadcrumbs.map(route => {
                let segments = {}

                Object.entries(route)
                    .forEach(entry => {
                        segments[entry[0]] = entry[1](context)
                    })

                return segments
            }) ?? []
        }
    },
    created() {
      this.mylang = this.lang
    },
    methods: {
      ...mapActions({
        translate: 'translate',
        logout: 'session/logout'
      }),
      showConsol(){
        console.log('*** this.$route.meta?.breadcrumbs: ', this.breadcrumbs)
      }
    },
    components: {
        playpenLogo
    },
    watch: {
        mylang(val) {
            this.translate(val)
        }
    },
}
</script>

<style lang="scss">

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
//   background: rgb(243, 243, 243);
}

.user-dropdown-name {
    font-size: 14px;
    line-height: 0.8;
}

.user-dropdown-role {
    font-size: 12px;
    line-height: 0.8;
}

</style>
