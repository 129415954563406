import { Authenticated } from '../middlewares'

export default [
    {
        path: '/jobs',
        name: 'jobs.index',
        component: () => import('../../views/jobs/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'jobs.index' }), label: ({ lang }) => lang.app.list_of_jobs, loading: () => false }
            ]
        }
    },
    {
        path: '/jobs/create',
        name: 'jobs.create',
        component: () => import('../../views/jobs/manage.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'jobs.index' }), label: ({ lang }) => lang.app.list_of_jobs, loading: () => false },
                { route: () => ({ name: 'jobs.create' }), label: ({ lang }) => lang.app.Create, loading: () => false }
            ]
        }
    },
    {
        path: '/jobs/:id/edit',
        name: 'jobs.edit',
        component: () => import('../../views/jobs/manage.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'jobs.index' }), label: ({ lang }) => lang.app.list_of_jobs, loading: () => false },
                { route: () => ({ name: 'jobs.edit' }), label: ({ lang }) => lang.app.Edit, loading: () => false }
            ]
        }
    },
]