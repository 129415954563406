<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                Regresar
            </label>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full">
                <sim-card>
                    <div  v-if="!edit" slot="header" class="flex flex-wrap -mx-2">
                        <span style="width: 72px; height: 72px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden;">
                            <img :src="session.profile_picture || '@/assets/images/default_image.png'" alt="" style="width: 100%; height: 100%;">
                        </span>
                        <div class="mr-2" style="padding-left: 22px; width: calc(100% - 470px);">
                            <b class="user-dropdown-name v-align-super">{{ session.name }}</b> <br>
                            <span class="user-dropdown-role v-align-super">Administrador</span>
                        </div>
                        <sim-button flat @click="editInfo">
                            <fa-icon icon="edit" /> Editar perﬁl
                        </sim-button>
                        <sim-button flat @click="editPass">
                            <fa-icon icon="trash" /> Cambiar contraseña
                        </sim-button>
                    </div>

                    <div v-else slot="header" class="flex flex-wrap items-center justify-center lg:justify-start">
                        <sim-img-cropper :img_url="session.profile_picture" @editImg="addImg" />
                    </div>

                    <div class="flex flex-wrap">
                        <template v-if="!edit && !editDataPass">
                            <div class="w-full md:w-1/2 lg:w-1/3 px-2 title">
                                Email
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                {{session.email}}
                            </div>
                        </template>
                        <template v-if="edit">
                            <div class="w-full md:w-1/2 lg:w-1/3 px-2 title" style="margin-bottom: 20px;">
                                Name
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2" style="margin-bottom: 20px;">
                                <sim-input
                                id="name"
                                placeholder="Name"
                                :error="error('name', { attribute: 'nombres' })"
                                v-model="dataProfile.name"/>
                            </div>

                            <div class="w-full md:w-1/2 lg:w-1/3 px-2 title">
                                Email
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                <sim-input
                                id="email"
                                placeholder="Email"
                                :error="error('email', { attribute: 'email' })"
                                v-model="dataProfile.email"/>
                            </div>
                        </template>
                        <template v-if="editDataPass">
                            <div class="w-full md:w-1/2 lg:w-1/3 px-2 title"  style="margin-bottom: 20px;">
                                Contraseña actual
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2"  style="margin-bottom: 20px;">
                                <sim-input
                                id="password"
                                placeholder=""
                                type="password"
                                :error="error('password', { attribute: 'password' })"
                                v-model="dataPass.password"/>
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/3 px-2 title"  style="margin-bottom: 20px;">
                                Nueva Contraseña
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2"  style="margin-bottom: 20px;">
                                <sim-input
                                id="new_password"
                                placeholder=""
                                type="password"
                                :error="error('new_password', { attribute: 'new_password' })"
                                v-model="dataPass.new_password"/>
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/3 px-2 title">
                                Confirmar Contraseña
                            </div>
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                <sim-input
                                id="new_password_confirmation"
                                placeholder=""
                                type="password"
                                :error="error('new_password_confirmation', { attribute: 'new_password_confirmation' })"
                                v-model="dataPass.new_password_confirmation"/>
                            </div>
                        </template>
                    </div>
                    <template v-if="edit" slot="footer">
                        <sim-button class="mr-2" :loading="loading" @click="editSave()">
                            Guardar cambios
                        </sim-button>

                        <sim-button outlined @click="edit = false">
                            Cancelar
                        </sim-button>
                    </template>
                    <template v-if="editDataPass" slot="footer">
                        <sim-button class="mr-2" :loading="loading" @click="savePass()">
                            Guardar cambios
                        </sim-button>

                        <sim-button outlined @click="editPass()">
                            Cancelar
                        </sim-button>
                    </template>
                </sim-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
export default {
    data: () => ({
        picture: null,
        isUpdating: false,
        changeAvatar: false,
        edit: false,
        editDataPass: false,
        dataPass:{
            password: '',
            new_password: '',
            new_password_confirmation: ''
        },
        dataProfile: {
        }
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            session: state => state.session.user,
            user: state => state.users.user,
            loading: state => state.administrators.loading
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE',
            setAdministrator: 'administrators/SET_ADMINISTRATOR'
        }),
        ...mapActions({
            store: 'administrators/store',
            removeError: 'removeError',
            fetchUser: 'users/show',

            updateUserEmail : 'session/updateUserEmail',
            updateUserPassword : 'session/updateUserPassword',
            updateUserPicture : 'session/updateUserPicture',
        }),
        handlePhotoSelection(event) {
            this.changeAvatar=true;
            const reader = new FileReader()
            reader.onload = () => {
                this.dataProfile.profile_picture = event.target.files[0]
                this.picture = reader.result
            }
            reader.readAsDataURL(event.target.files[0])
        },
        dropPhoto() {
            this.changeAvatar=false;
            this.dataProfile.profile_picture = null
            this.picture = null
            document.querySelector('#file_input').value = null
        },
        handlePhotoUpdate() {

        },
        editSave() {

        },
        editInfo(){
            this.edit = true;
        },
        addImg( img ) {
            const data = new FormData();
            data.append('profile_picture', img);
            this.updateUserPicture({id: this.session.id, body: data});
        },
        editPass(){
            this.editDataPass = !this.editDataPass;
            this.dataPass= {
                password: '',
                new_password: '',
                new_password_confirmation: ''
            }
        },
        savePass(){
            this.updateUserPassword({id: this.session.id, body: this.dataPass}).then( () => { 
                this.editDataPass = !this.editDataPass;
                this.dataPass= {
                    password: '',
                    new_password: '',
                    new_password_confirmation: ''
                }
            });
        }
    },
    watch: {

    },
    created() {
        this.setNavbarTitle(['Perfil de usuario']);
        this.fetchUser( this.session.id ).then( () => {
            this.dataProfile = {...this.user}
        });
        
    }
}
</script>

<style lang="scss">
 .title{
        font-weight: bold;
        padding: 0 0 20px 0;
    }
    .kt-avatar.kt-avatar--outline .kt-avatar__holder {
        border-radius: 100%;
    }  
</style>
