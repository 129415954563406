import { Authenticated } from '../middlewares'

export default [
    {
        path: '/users',
        name: 'users.index',
        component: () => import(/* webpackChunkName: "users.index" */ '../../views/users/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'users.index' }), label: ({ lang }) => lang.app.users, loading: () => false }
            ]
        }
    },
    {
        path: '/users/create',
        name: 'users.create',
        component: () => import(/* webpackChunkName: "users.create" */ '../../views/users/manage.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'users.index' }), label: ({ lang }) => lang.app.users, loading: () => false },
                { route: () => ({ name: 'users.create' }), label: ({ lang }) => lang.app.Create, loading: () => false }
            ]
        },
    },
    {
        path: '/users/:id',
        name: 'users.show',
        component: () => import(/* webpackChunkName: "users.show" */ '../../views/users/edit.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'users.index' }), label: ({ lang }) => lang.app.users, loading: () => false },
                { route: ({ route }) => ({ name: 'users.show', params: route.params }), label: ({ store: { state: { users } } }) => users.current?.username ?? (users.current?.profile ? `${users.current?.profile.name} ${users.current?.profile.last_name}` : 'No encontrado'), loading: ({ store: { state: { users } } }) => users.loadings.show }
            ]
        },
    },
    {
        path: '/users/:id/edit',
        name: 'users.edit',
        component: () => import(/* webpackChunkName: "users.show" */ '../../views/users/edit.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'users.index' }), label: ({ lang }) => lang.app.users, loading: () => false },
                // { route: ({ route }) => ({ name: 'users.show', params: route.params }), label: ({ store: { state: { users } } }) => users.current?.username ?? (users.current?.profile ? `${users.current?.profile.name} ${users.current?.profile.last_name}` : 'No encontrado'), loading: ({ store: { state: { users } } }) => users.loadings.show },
                { route: ({ route }) => ({ name: 'users.show', params: route.params }), label: () => 'Edit', loading: () => false }
            ]
        },
    },
]