/**
 * This is the main axios handler, where is configured to handle
 * all the incoming and upconfimg requests
 */
import { getObject } from '@/helpers/storage'
import { URL } from '@/constants'

const untokenedURLs = ['/api/auth/login']
/**
 * implemented at ~/main.js
 */
export default ({ axios, store, router, notification, lang }) => {

    axios.defaults.baseURL = URL

    /**
     * the config must always be returned
     * @description this function is called after the request is sent
     * @return {JSON} config
     * @throws {Response} error
     */
    axios.interceptors.response.use(response => {
        if (response.headers.authorization) {
            store.dispatch('session/setNewToken', response.headers.authorization.split(' ')[1])
        }
        return response
    }, error => { // something wrong happend
        // const lang = store.getters['lang']
        /**
         * to start the axios request validation we must start validating
         * that the response is readable, if its readable we can proceed
         * to read the status codes.
         */
        if (error.hasOwnProperty('response')) {
           
            const message = error.response.data.message || error.response.data.description, status = error.response.status;
            const translate = lang.messages.errors[message];
            switch (status) {
                case 500: console.error(lang.messages.errors.consult_error); break;
                case 400:
                    if (translate) {
                        notification.warning({
                            title: translate.title,
                            message:  translate.message ?? 'mensaje de error',
                            position: 'top-right',
                            showClose: true
                        })
                    } else {
                        notification.warning({
                            message: `(development) ${message}`,
                            position: 'top-right',
                            showClose: true
                        })
                    }
                    break;
                case 401:
                    //if (message === 'The token has been blacklisted') {
                        notification.warning({
                            message: 'session expired',
                            position: 'top-right',
                            showClose: true
                        })
                        store.dispatch('session/vanish')
                        router.push({ name: 'login' })
                    // } else {
                    //     store.dispatch('setErrors', { email: ['invalid_credentials'] })
                    // }
                    break;
                case 404:
                    notification.warning({
                        message: `error ${status}: ${message}`,
                        position: 'top-right',
                        showClose: true
                    })

                    if (message === "the_token_has_been_blacklisted") store.dispatch('session/vanish')

                    break
                case 422:
                    if(error.response.config.method !== "delete"){
                        notification.error({
                            message: lang.messages.errors.veify_information,
                            position: 'top-right',
                            showClose: true
                        })
                        store.dispatch('setErrors', error.response.data.errors)
                    }else {
                        notification.error({
                            message: lang.messages.errors.object_not_deleted,
                            position: 'top-right',
                            showClose: true
                        })
                    }
                    break
                default: console.log('unhandled error, status code =>', status)
            }
        } else {
            notification.warning({
                message: lang.messages.errors.fail_conection_server,
                position: 'top-right',
                showClose: true
            })
            // vue.prototype.$swal({
            //     toast: true,
            //     position: 'top-end',
            //     type: 'error',
            //     title: lang.messages.errors.unable_to_reach_server
            // })
        }
        return Promise.reject(error);
    });

    /**
     * the config must always be returned
     * @description this function is called before send the request to the server
     * @return {JSON} config
     * @throws {Response} error
     */
    axios.interceptors.request.use(config => {
        const user = getObject('session')
        const token = getObject(process.env.VUE_APP_USER_TOKEN_NAME)
        if (user && !untokenedURLs.includes(config.url)) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        let encoded = encodeURI(config.url)
        config.url = encoded
            //store.dispatch('session/renewSession');
        return config

    }, error => Promise.reject(error))
}
