<template>
    <ul>
        <li v-for="(src, index) in source" :key="index">
            <slot :record="{ src, index }">
                <div>
                    <h3>{{ src.title }}</h3>
                    <p>{{ src.content }}</p>
                </div>
            </slot>
            <slot name="action" :it="src"/>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'list',
    props: {
        source: { required: false, type: Array, default: () => [] }
    }
}
</script>

<style lang="scss" scoped>
    ul {
        list-style: none;

        li {
            margin: 15px;
            padding-bottom: 15px;

            &:not(:last-child):not(:only-child) {
                border-bottom: 1px solid #E8E9EC;
            }
        }
    }
</style>