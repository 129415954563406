/**
 * This file autoimport every plugin/configuration
 * from this directory excluding this file.
 * Each configuration file requires to be an
 * exported function in order to be added to this
 * map and set the app configuration.
 */

const files = require.context('./', true, /\.js$/i)

export default (context) => {
    files.keys()
        .filter(key => key !== './index.js')
        .map(path => files(path).default)
        .filter(file => typeof file === 'function')
        .forEach(configuration => configuration(context))
}