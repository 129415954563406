var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('h3',{staticClass:"w-100 block"},[_vm._v(_vm._s(_vm.getTitle()))]),_c('div',{staticClass:"mt-4"},[_c('sim-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"source":this.data},scopedSlots:_vm._u([{key:"td:profile",fn:function(ref){
var record = ref.record;
return [(record.item.profile !== null)?_c('div',{staticStyle:{"display":"flex"}},[_c('sim-img-blog',{attrs:{"img_url":record.item.profile.avatar_url,"custom_class":'u-iten-img',"custom_class2":'PP_circular_gradient',"detailTex":''}}),_c('span',{staticClass:"u-iten-text-user row",staticStyle:{"width":"100%"}},[(record.item.profile !== undefined && record.item.profile !== null)?_c('span',{staticClass:"name col-12"},[_vm._v(" "+_vm._s(record.item.profile.name)+" "+_vm._s(record.item.profile.last_name)+" "),_c('small',{staticClass:"col-12",staticStyle:{"font-weight":"normal"}},[_vm._v(" "+_vm._s(record.item.profile.email ? record.item.profile.email :'')+" ")])]):_vm._e()])],1):_c('div',{staticClass:"flex space-x-2 flex-wrap"},[_vm._v(" -- Unregistered profile yet -- ")])]}},{key:"td:paying",fn:function(ref){
var record = ref.record;
return [(record.item.current_plan !== null)?_c('div',[_vm._v(" "+_vm._s('Paying')+" ")]):_c('div',[_vm._v(" "+_vm._s('Not Paying')+" ")])]}},{key:"td:allStars",fn:function(ref){
var record = ref.record;
return [(record.item.profile !== null)?_c('div',[_vm._v(" "+_vm._s(record.item.profile.is_featured ? 'All Stars' : '---')+" ")]):_c('div',[_vm._v(" "+_vm._s('---')+" ")])]}},{key:"td:price",fn:function(ref){
var record = ref.record;
return [(record.item.current_plan !== null)?_c('div',[_vm._v(" "+_vm._s(record.item.current_plan ? ("" + (record.item.current_plan.price_e2 / 100) + (record.item.current_plan.currency.symbol)) : '---')+" ")]):_c('div',[_vm._v(" "+_vm._s('---')+" ")])]}}])}),(_vm.data.length > 0 && _vm.title === 'registeredUsers')?_c('sim-pagination',{attrs:{"data":_vm.pagVals},on:{"change":_vm.handleChange}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }