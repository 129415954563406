<template>
<div>
    <div class="kt-avatar kt-avatar--outline" id="kt_apps_user_add_avatar">
        <div :class="['kt-avatar__holder', type]" :style="`background-image: url(${img_url})`"></div>
        <label :class="['kt-avatar__upload', type]" data-toggle="kt-tooltip" title="" data-original-title="Change avatar">
            <i v-if="type !== 'product'" class="material-icons">edit</i>
            <i v-else class="material-icons">cloud_upload</i>
            <input type="file" @change="onChangeImagen" name="kt_apps_user_add_user_avatar">
        </label>
        <span class="kt-avatar__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
            <i class="fa fa-times"></i>
        </span>
    </div>

    <sim-Modal :show="modal" @close="modal = false" >
        <p class="a-modal-title" slot="header">Recortar Imagen</p>
        <p>
        <cropper
            ref="cropper"
            :src="src"
            alt="Source Image"
            :aspectRatio="aspectRatio"
            :min-container-width="512"
            :min-container-height="512"
            :img-style="{ 'width': '512px', 'height': '512px' }"
            />
        </p>
        <button slot="footer" @click="cropImage" v-if="src != ''" class="btn btn-label-primary text-uppercase" >Guardar</button>
    </sim-Modal>

</div>
</template>

<script>
import { prefix } from '@/components/utils';
import cropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

const fixBinary = (bin) => {
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  
}

export default {
    name: `img-cropper`,
    props: {
        type: { required: false, type: String, default: '' },
        img_url: { required: false, type: String, default: '' },
        aspectRatio: { required: false, type: Number, default: 1/1 }
    },
    data(){
        return {
          modal: false,
          src: '',
          prefix
        }
    },

    mounted () {
    },

    created() {
    },

    components: {
        cropper
    },

  methods: {

    onChangeImagen(e) {
          e.preventDefault();
          let files;
          var extensiones_permitidas = new Array("image/png", "image/jpeg", "image/jpg"); 
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          
          if(files.length){
            if(files[0].size < 1048576){
              var permitida = false; 
              for (var i = 0; i < extensiones_permitidas.length; i++) { 
                if (extensiones_permitidas[i] == files[0].type) { 
                    permitida = true; 
                    break; 
                }
              } 
            
             if (permitida) {
                const reader = new FileReader();
                reader.onload = () => {
                  this.src = reader.result;
                  this.modal= true;
                };
                reader.readAsDataURL(files[0]);
             }else{
                this.$message.error({
                        message: `El archivo no es una imagen`,
                        position: `top-right`
                    });
             }
            } else {
                this.$message.error({
                        message: `La Imagen tiene un peso mayor a 1MB`,
                        position: `top-right`
                    });
            }
          }
          
    },
    cropImage() {
        let img_b64 = this.$refs.cropper.getCroppedCanvas({
          // maxHeight: 56, 
          // maxWidth: 56,
          fillColor: '#fff',
          imageSmoothingQuality: 'low',
          }).toDataURL('image/jpeg');
        this.modal= false;
        let jpeg = img_b64.split(',')[1];
        let binary = fixBinary(window.atob(jpeg));// <-- Usamos la fn "fixBinary"
        let the_file = new Blob([binary], {type: 'image/jpeg'});// <-- Sacamos el encode
        let imagen_firma = new File([the_file], 'imagen_firma.jpeg', { type: 'image/jpeg' });
        this.$emit('editImg', imagen_firma);
        this.$emit('editImgVie', img_b64);
    },
  }
}
</script>

<style lang="scss">
.kt-avatar {
  position: relative;
  display: inline-block;

  .kt-avatar__holder {
    width: 120px;
    height: 120px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    &.product{
        border: 2px dashed #ebedf2 !important;
        width: 180px;
        height: 250px;
    }
  }

  .kt-avatar__upload {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &.product{
      top: 0px  !important;
      left: 0px  !important;
      width: 100%  !important;
      height: 100%  !important;
      border-radius: 0  !important;
      background: transparent  !important;
      box-shadow: none;
       i {
          color: #ebedf2;
          font-size: 70px;
        }

      &:hover {
          i {
            color: #374afb;
          }
      }
    }

    input {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
      opacity: 0;
    }

    i {
      color: #374afb;
      font-size: 16px;
    }

    &:hover {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      background-color: #374afb;

      i {
        color: #ffffff;
      }
    }
  }

  .kt-avatar__cancel {
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: auto;
    right: -10px;
    bottom: -5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    display: none;

    i {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      color: #a7abc3;
      font-size: 0.9rem;
    }

    &:hover i {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      color: #374afb;
    }
  }

  &.kt-avatar--changed .kt-avatar__cancel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &.kt-avatar--brand .kt-avatar__upload {
    i {
      color: #374afb;
    }

    &:hover {
      background-color: #374afb;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar--light .kt-avatar__upload {
    i {
      color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;

      i {
        color: #282a3c;
      }
    }
  }

  &.kt-avatar--dark .kt-avatar__upload {
    i {
      color: #282a3c;
    }

    &:hover {
      background-color: #282a3c;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar--primary .kt-avatar__upload {
    i {
      color: #5867dd;
    }

    &:hover {
      background-color: #5867dd;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar--success .kt-avatar__upload {
    i {
      color: #1dc9b7;
    }

    &:hover {
      background-color: #1dc9b7;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar--info .kt-avatar__upload {
    i {
      color: #5578eb;
    }

    &:hover {
      background-color: #5578eb;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar--warning .kt-avatar__upload {
    i {
      color: #ffb822;
    }

    &:hover {
      background-color: #ffb822;

      i {
        color: #111111;
      }
    }
  }

  &.kt-avatar--danger .kt-avatar__upload {
    i {
      color: #fd397a;
    }

    &:hover {
      background-color: #fd397a;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar--circle {
    .kt-avatar__holder {
      border-radius: 50%;
    }

    .kt-avatar__upload {
      right: 0px;
      top: 5px;
    }

    .kt-avatar__cancel {
      right: 3px;
      bottom: 10px;
    }
  }

  &.kt-avatar--outline .kt-avatar__holder {
    border: 3px solid #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }
}

</style>