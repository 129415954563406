import { Authenticated } from '../middlewares'

export default [
	{
        path: '/administrators',
        name: 'administrators.index',
        component: () => import(/* webpackChunkName: "administrators.index" */ '../../views/administrators/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'administrators.index' }), label: ({ lang }) => lang.app.administrators, loading: () => false }
            ],
            title: ({ lang }) => lang.app.administrators
        }
    }
]
