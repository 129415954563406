<template>
	<div>
		<div
			:class="[{'has-error': error, 'is-loading': loading}]"
			v-outside-click="() => (isOpen = false)"
		>
			<v-select v-model="myValue" :options="mySource"></v-select>
		</div>
		<div class="feedback-zone">
			<span>
				<p :class="[`${prefix}-select-error-message`]" v-if="error">{{
					error
				}}</p>
			</span>
		</div>
	</div>
</template>

<script>
	import {prefix} from "@/components/utils";
	import "vue-select/src/scss/vue-select.scss";
	import vSelect from "vue-select";

	export default {
		name: `select`,
		props: {
			source: {required: false, type: Array, default: () => []},
			value: {requred: false, type: [String, Number, Boolean], default: null},
			error: {required: false, type: String, default: null},
			loading: {required: false, type: Boolean, default: false},
			multiple: {required: false, type: Boolean, default: false},
		},
		data: () => ({
			prefix,
			selection: null,
			multipleData: [],
			mySource: [],
			myValue: null,
		}),
		components: {
			vSelect,
		},
		methods: {},
		computed: {},
		watch: {
			myValue(val) {
				if (!val.init) {
					this.$emit("input", val.code);
				}
			},
			source(val) {
				val.forEach((element) => {
					if (typeof this.value === "boolean") {
						if (Number(this.value) === element.value) {
							this.myValue = {
								label: element.label,
								code: element.value,
								init: true,
							};
						}
					} else {
						if (this.value === element.value) {
							this.myValue = {
								label: element.label,
								code: element.value,
								init: true,
							};
						}
					}
					this.mySource.push({label: element.label, code: element.value});
				});
			},
		},
		created() {},
	};
</script>
<style lang="scss">
	.sim-select-error-message {
		color: #ff4600;
	}
	.has-error .vs__dropdown-toggle {
		border: 1px solid #ff4600;
	}
</style>
