<template>
    <div>
        <!-- aqui va la tabla -->
        <div class="flex justify-end mb-4">
			<sim-button
				align="right"
				@click="opentModal()"
				:disabled="loading"
				:loading="loading"
			>
				<i class="material-icons"> add</i>
				{{l.app.Create}}
			</sim-button>
		</div>
        <sim-table
            :loading="loading"
            :columns="columns"
            :toolbar-per-page="$route.query.limit"
            @toolbar:search="searchData({ search: $event, page: 1 })"
            @toolbar:per-page="searchData({ limit: $event })"
            @sort-change="searchData({ order_by: `${$event.key},${$event.direction}` })"
            :source="pagination.data">
            <template #td:requirements="{ record }">
                <span v-html="record.item.requirements"></span>
            </template>
            <template #td:finish_at="{ record }">
                {{moment(record.item.finish_at).format("MMMM DD, YYYY hh:mm a")}}
            </template>
            <template #td:actions="{ record }">
                <div class="flex space-x-2">
                    <label @click="opentModalEdit(record.item.id)" class="btn btn-label-warning text-uppercase" :title="l.app.Edit">
                        <i class="material-icons">edit</i>
                    </label>
                    <label @click="deleteUser({ id: record.item.id, back: false })" class="btn btn-label-danger text-uppercase" :title="l.app.Remove">
                        <i class="material-icons"> delete</i>
                    </label>
                </div>
            </template>
        </sim-table>

        <sim-pagination align="right" :data="pagination" @change="searchData({ page: $event })" />
        
        <sim-Modal :show="modal" @close="opentModal">
            <h3 slot="header">
                <b class="col-12 title-detail-transaction" style="font-size: 28px; margin-bottom: 20px;">
                    Tender
                </b>
            </h3>
            <div class="row">
                <div class="form-group col-12" v-for="(input, key_input) in  form" :key="key_input"> 
                    <label :for="input.atribute">{{input.label}}</label>
                    <sim-input
                        v-if="input.type !== 'select'"
                        :id="input.atribute"
                        :type="input.type"
                        :error="error(input.atribute, { attribute: input.atribute })"
                        :value="formData[input.atribute]"
                        v-model="formData[input.atribute]"/>
                    <template v-else>
                        <v-select  
                        v-model="selectData[input.atribute]"
                        :options="source[input.source]"></v-select>
                        <div class="feedback-zone">
                            <span>
                                <p style="color: #ff1100;" :class="[`sim-input-error-message`]" v-if="error(input.atribute, { attribute: input.atribute })">{{ error(input.atribute, { attribute: input.atribute }) }}</p>
                            </span>
                        </div>
                    </template>
                </div>
            </div>
            <template slot="footer">
                <sim-button class="mr-2" @click="save()" :loading="loading">
                    <i class="material-icons">save</i>
                   {{l.app.Save_Changes}}
                </sim-button>
                <sim-button outlined @click="opentModal()" style="color: rgb(224, 31, 139) !important;">
                    <i class="material-icons">clear</i>
                    {{l.app.Cancel}}
                </sim-button>
            </template>
        </sim-Modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from "moment";

export default {
    data: () => ({
        modal:false,
        limit: 10,
        search:'',
        query: {
            page: 1,
            search: ''
        },
        form:[
            {label: 'title', atribute: 'title', type: 'text', visibility: true},
            {label: 'overview', atribute: 'overview', type: 'text', visibility: true},
            {label: 'requirements', atribute: 'requirements', type: 'text', visibility: true},
            {label: 'finish_at', atribute: 'finish_at', type: 'date', visibility: true},
        ],
        formData: {
            title:'',
            overview:'',
            finish_at:'',
            requirements:'',
        }
    }),
    computed: {
        ...mapState({
            pagination: state => state.tenders.pagination,
            loading: state => state.tenders.loadings.index,
            session: state => state.session.user
        }),
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        columns() {
            return [
                { title: this.l.app.Title, key: "title", sortable: true },
                { title: this.l.app.Requirements, slot: "requirements", sortable: true },
                { title: this.l.app.Finish, slot: "finish_at", sortable: true },
                { title: this.l.app.Actions, slot: "actions" },
            ]
        }
    },
    methods: {
        ...mapActions({
            fetchTenders: 'tenders/fetchTenders',
            fetchTender: 'tenders/fetchTender',
            store: 'tenders/store',
            update: 'tenders/update',
            delete: 'tenders/delete',
            deleteUser: 'tenders/delete'
        }),
        searchData(_query = {}) {
            const query = this.$queryUtils.withoutInvalidValues({ ...this.query, ..._query })
            this.fetchTenders(query)
                .then(() => {
                    this.$router.replace({ query })
                        .catch(() => null)
                })
        },
        // orderBy(data){
        //     if( this.search !== ''){
        //         this.fetchTenders({ query: this.search, sort_by: `${data.field},${data.order}`, page:1});
        //     } else{
        //         this.fetchTenders({ order_by: `${data.field},${data.order}`, page:1});
        //     }
        // },
       async save(){
            let response = await this[this.formData.hasOwnProperty('id') ? `update`:`store`]( this.formData )
            if(response.status == 200){
                this.opentModal()
                this.fetchTenders(this.query);
            }
        },
        opentModal(){
            this.modal = !this.modal;
            this.formData = {
                currency_iso:'GBP',
                title:'',
                overview:'',
                finish_at:'',
                requirements:'',
            };
        },
        async opentModalEdit(id){
            this.modal = !this.modal;
            let response = await this.fetchTender(id)
            if(response.status == 200){
                this.formData = {
                    id:response.data.id,
                    currency_iso:response.data.currency_iso,
                    title:response.data.title,
                    overview:response.data.overview,
                    finish_at:moment(response.data.finish_at).format('yyyy-MM-DD'),
                    requirements:response.data.requirements							
                };
            }
        },
        moment,
    },
    mounted() {
        this.searchData();
    },
    created() {
        this.query = {...this.query, ...this.$route.query, query: ''}
    }
}
</script>
