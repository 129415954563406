<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div class="kt-portlet__body svg_list_trainig">
                    <div class="row kt-mb-25">
                        <div class="col-12 col-sm-10">
                            <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchData" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-2">
                            <sim-button align="right" :to="{ name: 'events.create' }">
                                <i class="material-icons"> add</i>
                                Create
                            </sim-button>
                        </div>
                    </div>
                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:tags="{ }">
                            -
                        </template>
                        <template #td:actions="{ record }">
                            <div style="width: 168px;">
                                <!--a class="btn btn-label-primary text-uppercase" :href="`https://pp-app.simgulary.io/api/v1/attendees/csv?attendable_type=events&attendable_id=${record.item.id}`">
                                    <i class="material-icons">download</i>
                                </a-->
                                <button @click="ecxelInvitations(record.item.id)" class="btn btn-label-primary text-uppercase" :title="l.app.Edit">
                                    <i class="material-icons">download</i>
                                </button> 
                                <router-link :to="{ name: 'events.edit', params: { id: record.item.id } }" class="btn btn-label-warning text-uppercase" :title="l.app.Edit">
                                    <i class="material-icons">edit</i>
                                </router-link> 
                                <label @click="deleteUser({ id: record.item.id, back: false })" class="btn btn-label-danger text-uppercase">
                                    <i class="material-icons"> delete</i>
                                </label>
                            </div>
                        </template>
                    </sim-table>
                    <sim-pagination align="right" :data="pagination" @change="fetchAdministrators({ page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
export default {
    data: () => ({
        columns: [],
        limit: 10,
        search:'',
        query: {
            limit: 10,
            page:1
        },
    }),
    computed: {
        ...mapState({
            pagination: state => state.events.pagination,
            loading: state => state.events.loadings,
            session: state => state.session.user
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchEvents: 'events/fetchEvents',
            deleteUser: 'events/delete',
            update: 'jobs/update',
            fetchEventinvitations: 'events/fetchEventinvitations',
        }),
        searchData(){
            this.fetchEvents({type:2, search: this.search, page:1})
        },
        orderBy(data){
            if( this.search !== ''){
                this.fetchEvents({type:2, search: this.search, sort_by: `${data.field},${data.order}`, page:1});
            } else{
                this.fetchEvents({type:2, sort_by: `${data.field},${data.order}`, page:1});
            }
        },
        active( id ){
            if (confirm('Are you sure to activate this Events?')) {
                this.update({id: id, status: 'active'});
            } 
        },
        deactivate( id ){
            if (confirm('Are you sure to disable this Events?')) {
                this.update({id: id, status: 'inactive'});
            }
        },
        ecxelInvitations( id ){
            this.fetchEventinvitations(id)
        },
    },
    mounted() {
        this.fetchEvents(this.query);
    },
    created() {
        this.setNavbarTitle(['List of Events'])
        this.columns= this.l.list.columns_event;
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchData()
            }
        }
    }
}
</script>
