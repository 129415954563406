<template>
    <div>
        <div class="flex justify-end mb-4">
            <sim-button align="right" :to="{ name: 'users.create' }" :disabled="loading" :loading="loading">
                <i class="material-icons"> add</i>
                New User
            </sim-button>
        </div>
        <!-- aqui va la tabla -->
        <sim-table
            :loading="loading"
            :columns="columns"
            :toolbar-per-page="$route.query.limit"
            @toolbar:search="searchData({ query: $event, page: 1 })"
            @toolbar:per-page="searchData({ limit: $event })"
            :source="pagination.data">
            <template #td:actions="{ record }">
                <router-link :to="{ name: 'edit_users', params: { user_id: record.item.id } }" style="margin-right: 10px;" class="btn btn-label-warning text-uppercase">
                    <i class="material-icons">edit</i>
                </router-link> 
                <label @click="deleteUser({ id: record.item.id, back: false })" class="btn btn-label-danger text-uppercase">
                    <i class="material-icons"> delete</i>
                </label>
            </template>
        </sim-table>

        <sim-pagination align="right" :data="pagination" @change="searchData({ page: $event })" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
    data: () => ({
        columns: [
            { title: 'Email', key: 'email', enabled: true, sorter:true },
            { title: 'Name', key: 'name', enabled: true, sorter: true },
            { title: 'Acciones', slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
        ],
        limit: 10,
        search:'',
        query: {
            role: 'admin'
        },
    }),
    computed: {
        ...mapState({
            pagination: ({ users }) => users.pagination,
            loading: ({ users }) => users.loadings.index,
            session: ({ session }) => session.user
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapActions({
            fetchUsers: 'users/index',
            deleteUser: 'users/delete'
        }),
        searchData(_query = {}){
            const query = this.$queryUtils.withoutInvalidValues({ ...this.query, ..._query, role: 'admin' })
            this.fetchUsers(query)
                .then(() => {
                    this.$router.replace({ query })
                        .catch(() => null)
                })
        },
    },
    mounted() {
        this.searchData()
    },
    created() {
        this.query = {...this.query, ...this.$route.query, query: ''}
    }
}
</script>
