import moment from 'moment'

export default ({ vue }) => {

    vue.filter('timePadding', (val, padding = '0') => {
        if (val < 10) {
            return `${padding}${val}`
        }

        return val
    })

    vue.filter('capitalize', (val = '') => {
        return val.split(' ').map($0 => $0.toString().charAt(0).toUpperCase() + $0.toLowerCase().slice(1)).join(' ')
    })

    vue.filter('gender', (val = '') => {
        return ['male', 'female'].includes(val) ? val === 'female' ? 'Mujer' : 'Hombre' : 'Otro'
    })

    vue.filter('ucfirst', val => {
        if (!val) return ''
        val = val.toString()
        return val.charAt(0).toUpperCase() + val.slice(1)
    })

    vue.filter('initials', (val, size = 2, wholeName) => {
        if (!val) return 'UK'

        if (wholeName) {
            return val.split(' ').filter((_, idx) => idx <= size - 1).map($0 => $0.substring(0, size - 1).toUpperCase()).join('')
        }

        return val.substring(0, size - 1).toUpperCase()
    })

    vue.filter('byteReadable', (bytes, decimals, kib, maxunit) => {
        kib = kib || false
        if (bytes === 0) return '0 Bytes'
        if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number'
        const k = kib ? 1024 : 1000
        const dm = decimals != null && !isNaN(decimals) && decimals >= 0 ? decimals : 2
        const sizes = kib ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB', 'BiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
        var i = Math.floor(Math.log(bytes) / Math.log(k))
        if (maxunit != undefined) {
            const index = sizes.indexOf(maxunit)
            if (index != -1) i = index
        }
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    })

    vue.filter('boolean', (val, positive = 'Yes', negative = 'No', other = null) => {
        if (val === true) {
            return positive
        }

        if (val === false) {
            return negative
        }

        return other ?? positive
    })

    vue.filter('truncate', (val, max) => {
        return val.length > max ? `${val.slice(0, max)}...` : val
    })

    vue.filter('date', (val, format = 'yyyy-MM-DD', fallback = 'Never') => {
        if (!val) return fallback
        
        return moment(val).format(format)
    })

    vue.filter('age', (val, single, multiple) => {
        if (!val) return moment().diff(new Date(), 'years', false)

        const ages = moment().diff(val, 'years', false)

        if (!single) {
            return ages
        }

        if (single && multiple) {
            return `${ages} ${ages <= 1 && ages >= 0 ? single : multiple}`
        }
    })

    vue.filter('json', (val, spacing = 2) => {
        return JSON.stringify(val, null, spacing)
    })

    vue.filter('roman', val => {
        const resources = [['I', 'V', 'X'],['X', 'L', 'C'],['C', 'D', 'M'], ['M']]
        let numStr = val.toString()
        let arr
        let arrReversed
        let singles = []
        let convertedReversed = []
        let converted
        
        arr = numStr.split('')
        
        arrReversed = arr.reverse()

        
        for (let num of arrReversed) {
            let converted
            switch(num) {
                case "0":
                    converted = ''
                    break
                case "1":
                    converted = "i"
                    break
                case "2":
                    converted = "ii"
                    break
                case "3":
                    converted = "iii"
                    break
                case "4":
                    converted = "iv"
                    break
                case "5":
                    converted = "v"
                    break
                case "6":
                    converted = "vi"
                    break
                case "7":
                    converted = "vii"
                    break
                case "8":
                    converted = "viii"
                    break
                case "9":
                    converted = "ix"
            }

            singles.push(converted)
        }
        
        for (let i = 0; i < singles.length; i++) {
            let converted
            converted = singles[i].replace(/i/g, resources[i][0]).replace(/v/g, resources[i][1]).replace(/x/g, resources[i][2])
            convertedReversed.push(converted)
        }
        converted = convertedReversed.reverse()
        return converted.join('')
    })
}