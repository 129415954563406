// import router from '@/router';
// import Vue from 'vue';

// import Vue from "vue";


export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        current: {},
        all: {},
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_BANNERS: (state, pagination) => state.pagination = pagination,
        SET_CURRENT_BANNER: (state, banner) => state.current = banner,
        SET_ALL_BANNERS: (state, list) => state.all = list,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
    },
    actions: {
        index ({ commit }, query = {}) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.banners
                .index(query)
                .then(({ data }) => {
                    if (query.limit === 0) {
                        commit('SET_ALL_BANNERS', data)
                    } else {
                        commit('SET_PAGINATED_BANNERS', data)
                    }
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        show({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.banners
                .show(id)
                .then(({ data }) => {
                    commit('SET_CURRENT_BANNER', { data })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, body){
            commit('TOGGLE_LOADING', 'store')
            return this.$repository.banners
                .store(body)
                .then(({ data }) => {
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        },
        update({ commit }, body) {
            commit('TOGGLE_LOADING', 'update')
            return this.$repository.banners
                .update(body.id, body)
                .then(({ data }) => {
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        delete({ commit }, id) {
            commit('TOGGLE_LOADING', 'delete')
            return this.$repository.banners
                .delete(id)
                .finally(() => commit('TOGGLE_LOADING', 'delete'))
        },
    }
}