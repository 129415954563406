import { Chrome } from 'vue-color'
import { library } from '@fortawesome/fontawesome-svg-core'
import Select from 'vue-select'
import {
    faHome,
    faChevronDown,
    faChevronUp,
    faChevronCircleLeft,
    faChevronCircleRight,
    faGraduationCap,
    faUser,
    faMoneyBillWave,
    faClipboard,
    faUserGraduate,
    faClone,
    faCalendarAlt,
    faCalendar,
    faPalette,
    faStar,
    faListAlt,
    faSchool,
    faUniversity,
    faWrench,
    faCreditCard,
    faFileAlt,
    faBell,
    faSearch,
    faTrash,
    faEdit,
    faTrashAlt,
    faTimes,
    faUserCircle,
    faCog,
    faSignOutAlt,
    faAsterisk,
    faCheck,
    faLock,
    faUpload,
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

[
    faHome,
    faChevronDown,
    faChevronUp,
    faChevronCircleLeft,
    faChevronCircleRight,
    faGraduationCap,
    faUser,
    faMoneyBillWave,
    faClipboard,
    faUserGraduate,
    faClone,
    faCalendarAlt,
    faCalendar,
    faPalette,
    faStar,
    faListAlt,
    faSchool,
    faUniversity,
    faWrench,
    faCreditCard,
    faFileAlt,
    faBell,
    faSearch,
    faTrash,
    faEdit,
    faTrashAlt,
    faTimes,
    faUserCircle,
    faCog,
    faSignOutAlt,
    faAsterisk,
    faCheck,
    faLock,
    faUpload,
    faEye,
    faEyeSlash
].forEach(el => library.add(el))

export default ({ vue }) => {
    vue.component('fa-icon', FontAwesomeIcon)
    vue.component('v-select', Select)
    vue.component('chrome-picker', Chrome)
}
