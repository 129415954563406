<template>
    <div :class="[`${prefix}-card`]">
        <div :class="[`${prefix}-card-header`]" v-if="$slots.header">
            <slot name="header" />
        </div>
        <div :class="[`${prefix}-card-body`]">
            <slot />
        </div>
         <div :class="[`${prefix}-card-footer`]" v-if="$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `card`,
    props: {
        rounded: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix
    })
}
</script>

<style lang="scss" scoped>

</style>