<template>
	<div>
		<div class="flex justify-end mb-4">
			<sim-button
				align="right"
				:to="{name: 'sectors.create'}"
				:disabled="loading"
				:loading="loading"
			>
				<i class="material-icons"> add</i>
				Create
			</sim-button>
		</div>

		<!-- aqui va la tabla -->
		<sim-table
			:loading="loading"
			:columns="columns"
			:toolbar-per-page="$route.query.limit"
			@toolbar:search="searchData({search: $event, page: 1})"
			@toolbar:per-page="searchData({limit: $event})"
			@sort-change="searchData({order_by: `${$event.key},${$event.direction}`})"
			:source="pagination.data"
		>
			<template #td:actions="{ record: { item } }">
				<div class="flex space-x-2">
					<router-link
						:to="{name: 'sectors.edit', params: {id: item.id}}"
						:title="l.app.Edit"
						class="btn btn-label-primary text-uppercase"
					>
						<i class="material-icons">edit</i>
					</router-link>
					<label
						@click="actDeleteSectors({id: item.id, back: false})"
						:title="l.app.Remove"
						class="btn btn-label-danger text-uppercase"
					>
						<i class="material-icons"> delete</i>
					</label>
				</div>
			</template>
            <template #td:actions="{ record: { item } }">
                <div class="flex space-x-2">
                    <router-link :to="{ name: 'sectors.edit', params: { id: item.id } }" :title="l.app.Edit" class="btn btn-label-primary text-uppercase">
                        <i class="material-icons">edit</i>
                    </router-link>
                    <label @click="deleteSectors({ id: item.id, back: false })" :title="l.app.Remove" class="btn btn-label-danger text-uppercase">
                        <i class="material-icons"> delete</i>
                    </label>
                </div>
            </template>
        </sim-table>

		<sim-pagination
			align="right"
			:data="pagination"
			@change="searchData({page: $event})"
		/>
	</div>
</template>

<script>
	import {mapActions, mapState, mapGetters} from "vuex";

	export default {
		data: () => ({
			modal: false,
			query: {
				limit: 10,
				page: 1,
				query: "",
			},
			selection: [],
			source: {
				fields: [],
			},
			selectData: {
				fields: [],
			},
			form: [
				{label: "Name", atribute: "name", type: "text", visibility: true},
				{
					label: "Description",
					atribute: "description",
					type: "text",
					visibility: true,
				},
				{
					label: "Field",
					atribute: "fields",
					type: "select",
					source: "fields",
					visibility: true,
				},
			],
			formData: {
				name: "",
				description: "",
				fields: [],
			},
		}),
		components: {},
		computed: {
			...mapState({
				fields: (state) => state.fields.pagination,
				pagination: (state) => state.sectors.pagination,
				loading: (state) => state.sectors.loadings.index,
				session: (state) => state.session.user,
			}),
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
			columns() {
				return [
					{title: this.l.app.Name, key: "name", sortable: true},
					{title: this.l.app.Description, key: "description", sortable: true},
					{title: this.l.app.Field, key: "field"},
					{title: this.l.app.Actions, slot: "actions", alignment: "right"},
				];
			},
		},
		methods: {
			...mapActions({
				fetchFields: "fields/fetchFields",
				fetchSectors: "sectors/index",
				store: "sectors/store",
				update: "sectors/update",
				deleteSectors: "sectors/delete",
			}),
			async actDeleteSectors(data) {
				let respuesta = await this.deleteSectors(data);
				if (respuesta) {
					this.searchData();
				}
			},
			searchData(_query = {}) {
				const query = this.$queryUtils.withoutInvalidValues({
					...this.query,
					..._query,
				});
				this.fetchSectors(query).then(() => {
					this.$router.replace({query}).catch(() => null);
				});
			},
		},
		mounted() {
			this.searchData();
		},
		created() {
			this.query = {...this.query, ...this.$route.query, query: ""};
		},
	};
</script>
