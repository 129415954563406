<template>
    <component :is="to ? 'router-link' : 'button'" :class="[
        `${prefix}-button`,
        `is-${scheme}`,
        { 'icon': icon },
        { 'flat': flat },
        { 'block': block },
        { 'is-link': link },
        { 'outlined': outlined },
        { 'disabled': disabled }
    ]" :to="to" @click="$emit('click', $event)" :type="submit ? 'submit' : 'button'" :title="label" :disabled="disabled">
        <template v-if="icon">
            <fa-icon :icon="icon" :color="iconColor" />
        </template>
        <template v-else>
            <div v-if="loading" :class="getSpinnerClass" />
            <slot>{{ label }}</slot>
            <!-- <sim-spinner dot-width="4" v-if="loading"/>  -->
        </template>
    </component>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `button`,
    props: {
        flat: { required: false, type: Boolean, default: false },
        to: { required: false, type: [Object, String], default: null },
        icon: { required: false, type: String, default: null },
        label: { required: false, type: String, default: '' },
        scheme: { required: false, type: String, default: 'primary' },
        iconColor: { required: false, type: String, default: '#3C3E4A' },
        block: { required: false, type: Boolean, default: false },
        submit: { required: false, type: Boolean, default: false },
        link: { required: false, type: Boolean, default: false },
        outlined: { required: false, type: Boolean, default: false },
        loading: { required: false, type: Boolean, default: false },
        disabled: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix
    }),
    computed: {
        getSpinnerClass () {
            return {
                'spinner': this.loading,
                'check': !this.emptyStatus && this.isSuccess && !this.loading,
                'cross': !this.emptyStatus && !this.isSuccess && !this.loading
            }
        },
    }
}
</script>