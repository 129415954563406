import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		pagination: { data: [], current_page: 1, total: 0 },
		current: {},
		loadings: { index: false, show: false, store: false, update: false, delete: false }
	},
	mutations: {
		SET_PAGINATED_MEDIA: (state, pagination) => state.pagination = pagination,
		SET_SECTORS: (state, current) => state.current = current,
		TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
		DROP_MEDIA: (state, index) => state.pagination.data.splice(index, 1),
	},
	actions: {
		index({ commit }, page = {}) {
			commit('TOGGLE_LOADING', 'index')
			return this.$repository.media
				.index(page)
				.then((res) => {
					commit('SET_PAGINATED_MEDIA', res.data)
					return res.data
				})
				.finally(() => commit('TOGGLE_LOADING', 'index'))
		},
		show({ commit }, id) {
			commit('TOGGLE_LOADING', 'show')
			return this.$repository.media
				.show(id)
				.then(({ data }) => {
					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'show'))
		},
		store({ commit }, body) {
			commit('TOGGLE_LOADING', 'store')
			return this.$repository.media
				.store(body)
				.then(({ data }) => {
					// console.log('************************ data: ',data)
					Vue.prototype.$message.success({
						message: `Successful registration`,
						text: `Successful registration`,
						description: `Successful registration`,
						position: `top-right`
					})
					return data
				})
				/* .catch((e)=>{
					// console.log('************************ error: ',e)
				}) */
				.finally(() => commit('TOGGLE_LOADING', 'store'))
		},
		update({ commit }, payload) {
			commit('TOGGLE_LOADING', 'store')
			return this.$repository.media
				.update(payload.itemId, payload)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful update`,
						text: `Successful update`,
						description: `Successful update`,
						position: `top-right`
					})
					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'store'))
		},
		uploadFeaturedImage({ commit }, payload) {
			console.log("data",payload.itemId, payload)

			commit('TOGGLE_LOADING', 'uploadFeaturedImage')
			return this.$repository.media
				.uploadFeaturedImage(payload.itemId, payload.image)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful update`,
						text: `Successful update`,
						description: `Successful update`,
						position: `top-right`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'uploadFeaturedImage'))
		},
		delete({ commit,state },  id) {
			if (confirm('¿Do you want to delete this?')) {
				commit('TOGGLE_LOADING', 'delete')
				return this.$repository.media
					.delete(id)
					.then(({ data }) => {
						const index = state.pagination.data.findIndex(event => event.id === id)
						if (index !== -1) {
							commit('DROP_MEDIA', index)
						}
						return data
					})
					.finally(() => commit('TOGGLE_LOADING', 'delete'))
			}
		},
		publish({ commit }, id) {
			if (confirm('¿Do you want to publish this?')) {
				commit('TOGGLE_LOADING', 'publish')
				return this.$repository.media
					.publish(id)
					.then(({ data }) => {
						Vue.prototype.$message.success({
							message: `Successful publish`,
							text: `Successful publish`,
							description: `Successful publish`,
							position: `top-right`
						})
						return data
					})
					.finally(() => commit('TOGGLE_LOADING', 'publish'))
			}
		}
	},
	getters: {
	}
}