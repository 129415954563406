<template>
	<div>
		<div :class="[`${prefix}-input`, { 'borderless': borderless, 'has-error': error }]">
			<div :class="[`${prefix}-input-main`, { 'search': search }]">
				<div v-if="search && !value" class="search-icon">
					<fa-icon :icon="searchIcon" class="search-icon" />
				</div>
				<span :class="[`${prefix}-input-main-prefix-icon`]" v-if="$slots['prefix-icon']">
					<slot name="prefix-icon" />
				</span>
				<input v-if="type !== 'textarea'" ref="input" :id="id" :type="type" :min="min ? min : undefined"
					:max="max ? max : undefined" :class="[{ 'search': search }]" :placeholder="placeholder" :disabled="disabled"
					:readonly="readonly" :required="required" :style="{ textAlign: alignment }" v-model="valor"
					@input="handleInput">
				<textarea v-else :name="id" rows="10" cols="50" :disabled="disabled" :required="required" :readonly="readonly"
					:style="{ textAlign: alignment }" v-model="valor" @input="handleInput"></textarea>
				<span :class="[`${prefix}-input-main-suffix-icon`]" v-if="$slots['suffix-icon']">
					<slot name="suffix-icon" />
				</span>
			</div>
		</div>
		<div class="feedback-zone">
			<span>
				<p :class="[`${prefix}-input-error-message`]" v-if="error">{{ error }}</p>
			</span>
			<span v-if="counter">{{ valor.length }}</span>
		</div>
	</div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
	name: `input`,
	props: {
		required: { required: false, type: Boolean, default: false },
		search: { required: false, type: Boolean, default: false },
		searchIcon: { required: false, type: String, default: 'search' },
		placeholder: { required: false, type: String, default: '' },
		value: { required: false, type: [String, Number, Object], default: '' },
		borderless: { required: false, type: Boolean, default: false },
		type: { required: false, type: String, default: 'text' },
		id: { required: false, type: String, default: null },
		disabled: { required: false, type: Boolean, default: false },
		readonly: { required: false, type: Boolean, default: false },
		alignment: { required: false, type: String, default: 'left' },
		error: { required: false, type: String, default: null },
		counter: { required: false, type: Boolean, default: false },
		min: { required: false, default: false },
		max: { required: false, default: false },
	},
	data: () => ({
		prefix,
		hasFocus: false,
		valor: '',
	}),
	mounted() {
		this.valor = this.value
	},
	methods: {
		handleInput(event) {
			this.valor = event.target?.value

			this.$emit('input', event.target?.value)
			//this.$emit('input', {id: this.id, value: event.target?.value})

			return event.target?.value
		}
	}
}
</script>
