<template>
    <div class="login-screen">
        <div class="left-login-area"></div>
        <div class="right-login-area py-3">
            <form @submit.prevent="confirmAccount(guest)" style="max-width: 315px" @keydown="removeError($event.target.id)">
                <playpen-logo class="d-block mb-4" width="100%" height="48px"/>

                <p class="text-center mb-3">
                    Coloca tu correo y la nueva clave
                </p>

                <div>
                    <div class="form-group">
                        <label for="" style="display: block;">Email</label>
                        <sim-input id="email" bordered v-model="guest.email">
                            <template #suffix-icon>
                                <fa-icon icon="user-circle" color="#000"/>
                            </template>
                        </sim-input>
                        <p v-if="hasError('email')">
                            {{ error('email', { attribute: 'correo electrónico' }) }}
                        </p>
                    </div>
                    <div class="form-group">
                        <label for="" style="display: block;">New Password</label>
                        <sim-input id="password" bordered v-model="guest.password" type="password">
                            <template #suffix-icon>
                                <fa-icon icon="asterisk" color="#000"/>
                            </template>
                        </sim-input>
                        <p v-if="hasError('password')">
                            {{ error('password', { attribute: 'contraseña' }) }}
                        </p>
                    </div>
                </div>

                <div class="d-flex justify-content-between mb-3">
                    <router-link to="/" class="sim-link">
                        Iniciar sesión
                    </router-link>
                </div>

                <sim-button :loading="loading" submit block>
                    Restaurar Clave
                </sim-button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import playpenLogo from '@/components/playpenLogo.vue';
export default {
    data: () => ({
        guest: {
            token: '',
            email: '',
            password: ''
        },
        lang: 'es'
    }),
    watch: {
        lang(val) {
            this.translate(val)
        }
    },
    computed: {
        ...mapGetters({
            error: 'error',
            hasError: 'hasError'
        }),
        ...mapState({
            loading: state => state.session.loading
        })
    },
    methods: mapActions({
        confirmAccount: 'session/confirmAccount',
        translate: 'translate',
        removeError: 'removeError'
    }),
    components: {
        playpenLogo
    },
    created() {
        this.guest.token = this.$route.params.token;
    }
}
</script>

<style lang="scss" scoped>
    .login-screen {
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .left-login-area, .right-login-area {
            display: flex;
            flex-grow: 1;
            height: 100%;
        }

        .left-login-area {
            background: #4D4F5C;
        }

        .right-login-area {
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 800px) {
        .login-screen {
            grid-template-columns: 1fr;
        }

        .left-login-area {
            display: none !important;
        }
    }
</style>