<template>
	<div class="login-screen">
		<div class="left-login-area"></div>
		<div class="right-login-area py-3">
			<form @submit.prevent="submitForm" style="min-width: 315px" @keydown="removeError($event.target.id)">
				<playpen-logo class="d-block mb-4" width="100%" height="48px" />

				<p class="text-center mb-3">
					{{ l.app.reset_password_txt }}
				</p>

        <div>
					<div class="form-group">
						<label for="" style="display: block;"> {{ l.app.password }}</label>
						<sim-input
                :disabled="sending"
                id="password"
                :type="showPassword ? 'text' : 'password'"
                bordered
                v-model="guest.password">
							<template #suffix-icon>
								<fa-icon :icon="showPassword ? 'eye-slash' : 'eye'" @click="showPassword = !showPassword" color="#000" />
							</template>
						</sim-input>
						<p v-if="hasError('password')">
							{{ error('password', { attribute: 'correo electrónico' }) }}
						</p>
					</div>
					<div class="form-group">
						<label for="" style="display: block;"> {{ l.app.confirm_password }}</label>
						<sim-input
                :disabled="sending"
                id="confirm_password"
                :type="showPassword ? 'text' : 'password'"
                bordered
                v-model="guest.confirm_password">
							<template #suffix-icon>
								<fa-icon :icon="showPassword ? 'eye-slash' : 'eye'" @click="showPassword = !showPassword" color="#000" />
							</template>
						</sim-input>
						<p v-if="hasError('password')">
							{{ error('password', { attribute: 'correo electrónico' }) }}
						</p>
					</div>
				</div>

				<div class="d-flex justify-content-between mb-3">
					<router-link to="/" class="sim-link">
						{{ l.app.login }}
					</router-link>
				</div>

				<sim-button :loading="loading" submit block>
					{{ l.app.reset_password }}
				</sim-button>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import playpenLogo from '@/components/playpenLogo.vue';
export default {
	data: () => ({
		guest: {
			token: '',
			email: '',
			password: '',
			confirm_password: '',
		},
		lang: 'es',
		sending: false,
    showPassword: false,
	}),
	mounted() {
		this.guest.token = this.$route.params?.token ? this.$route.params?.token : ''
		this.guest.email = this.$route.query?.email ? this.$route.query?.email : ''
	},
	watch: {
		lang(val) {
			this.translate(val)
		}
	},
	computed: {
		...mapGetters({
			hasError: "hasError",
			error: "error",
			l: "lang",
		}),
		...mapState({
			loading: state => state.session.loading
		})
	},
	methods: {
		...mapActions({
			passwordResetConfirm: 'session/passwordResetConfirm',
			translate: 'translate',
			removeError: 'removeError'
		}),
		async submitForm() {
			if (this.guest.password == '' || this.guest.confirm_password == '') {
				this.$message.error({
					message: this.l.app.missing_password_values,
					position: `top-right`
				})
				return false
			} else if (this.guest.password != this.guest.confirm_password) {
				this.$message.error({
					message: this.l.app.passwords_not_match,
					position: `top-right`
				})
				return false
			} else {
        this.sending = true
        await this.passwordResetConfirm(this.guest)
          .then((response) => {
            console.log(response)
            if (response) {
              this.$message.success({
                message: this.l.app.success_password_change,
                position: `top-right`
              })
              this.$router.push({ name: 'login' })
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.sending = false
          })
        // this.$router.push('/')
      }
		},
	},
	components: {
		playpenLogo
	},
}
</script>

<style lang="scss" scoped>
.login-screen {
	min-height: 100vh;
	display: grid;
	grid-template-columns: 1fr 1fr;

	.left-login-area,
	.right-login-area {
		display: flex;
		flex-grow: 1;
		height: 100%;
	}

	.left-login-area {
		background: #4D4F5C;
	}

	.right-login-area {
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 800px) {
	.login-screen {
		grid-template-columns: 1fr;
	}

	.left-login-area {
		display: none !important;
	}
}
</style>