<template>
    <div class="c-pagination">
        <ul v-if="data && data.links" class="sim-pagination" style="padding: 20px 0;">
            <li :class="['page-item']">
                <div class="page-link" @click="onPaginationChangePage(0)"> 
                    <i class="material-icons">chevron_left</i>
                </div>
            </li>
            <li v-for="(pag, index) in data.links" :key="`pag-${index}`"  :class="['page-item', pag.active && 'active']">
                <div class="page-link" v-if="index !== 0 && index !==  (data.links.length - 1)" @click="onPaginationChangePage(pag.label)" > 
                    {{pag.label}}
                </div>
            </li>
            <li :class="['page-item']">
                <div class="page-link" @click="onPaginationChangePage(data.links.length - 2)"> 
                    <i class="material-icons">chevron_right</i>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: 'pagination',
    props: {
        data: { type: Object, default: () => ({}) },
        limit: { type: Number, default: 5 }
    },
    data: () => ({
        prefix,
        dataPagination: [],
    }),
    methods: {
        onPaginationChangePage (page) {
					this.$emit('change', page);
        },
        init(){
            let aux = [];
            if( this.data.total_pages < 6){
                for (let index = 0; index < this.data.total_pages; index++) {
                    aux.push(index + 1);
                }
            } else {
                console.log(this.data.page);
                if(this.data.page < 3){
                    for (let index = 0; index < 6 ; index++) {
                        aux.push(index + 1);
                    }
                } else {
                    let i = this.data.page - 1;
                    let e = i + 8;
                    for (let index = i; index < e ; index++) {
                        aux.push(index + 1);
                    }
                }
            }
            this.dataPagination = aux;
        }
    },
    mounted() {
        // this.init();
    },
    watch: {
        data(){
            // this.init();
        }
    }
}
</script>

<style lang="scss" scoped>

$prefix: sim;
.c-pagination{
    display: table;
    margin: 0 auto;
}
.#{$prefix}-pagination {
    width: 100%;
    display: flex;
    list-style: none;
    overflow: hidden;
    .page-item {
        padding: 5px;

        .page-link{
            border-radius: 4px;
            padding: 5px;
            min-width: 35px;
            min-height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            cursor: pointer;
        }
        &.active {
            .page-link{
                background: #006CFF;
                color: white !important;
            }
        }
    }
}

</style>