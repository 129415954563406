export default ({ axios, mapQueryString }) => ({
    index: (query = {}) => axios.get(`/events${mapQueryString(query)}`),
    show: (id) => axios.get(`/events/${id}`),
    store: (data) => axios.post(`/events`, data),
    update: (id, data) => axios.patch(`/events/${id}`, data),
    get_invitations: (id) => axios.get(`/attendees/csv?attendable_type=events&attendable_id=${id}`,
    {headers:{ responseType: 'blob'}}
    ).then((response) => {
        console.log("response",response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `attendees-${id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }),

    //get_invitations: (id) => axios.get(`/invitations/csv?invitable_type=events&invitable_id=${id}`),
    delete: (id) => axios.delete(`/events/${id}`),
})

