<template>
    <aside :class="['kt-aside', 'kt-aside--fixed', 'kt-grid__item kt-grid', 'kt-grid--desktop', 'kt-grid--hor-desktop', isOpen && 'kt-aside--on']">
        <div class="menu-btn-movile" @click="toggleOpen()">
            <i class="material-icons">menu_open</i>
        </div>
        <slot />
    </aside>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `sidebar`,
    props: {
        accordion: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix,
        isOpen: false
    }),
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>