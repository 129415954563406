<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <p v-if="loading" class="text-2xl text-center pt-16 p-8 animate-bounce">{{l.app.loading}}</p>
        <div v-else class="row kt-pt25">
            <div class="col-12 col-sm-12">
                <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                    <div class="kt-portlet__body svg_list_trainig">
                        <form @submit.prevent="isUpdating ? updateData() : storeData()" @keydown="removeError($event.target.id)">
                            <h3>{{ isUpdating ? l.app.To_update : l.app.Create }}</h3>
                            <div class="flex flex-wrap -mx-2">
                                <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                    <div class="form-group" v-for="(input, key_input) in  form" :key="key_input">
                                        <label :for="input.atribute">{{input.label}}</label>
                                        <sim-input
                                            v-if="input.type !== 'select' && input.type !== 'textArea' && input.type !== 'checkbox'"
                                            :id="input.atribute"
                                            :type="input.type"
                                            :error="error(input.atribute, { attribute: input.atribute })"
                                            :disabled="input.disabled"
                                            v-model="formData[input.atribute]"/>
                                        <vue-editor v-else-if="input.type === 'textArea' " :id="input.atribute" v-model="formData[input.atribute]"></vue-editor>
                                        <label v-else-if="input.type === 'checkbox'">
                                            <input :id="input.atribute" type="checkbox"  v-model="formData[input.atribute]" />
                                            <i class="input-helper"></i>
                                            {{input.description}}
                                      </label>
                                        <template v-else>
                                            <v-select  
                                            v-model="formData[input.atribute]"
                                            :options="input.source"></v-select>
                                            <div class="feedback-zone">
                                                <span>
                                                    <p style="color: #ff1100;" :class="[`sim-input-error-message`]" v-if="error(input.atribute, { attribute: input.atribute })">{{ error(input.atribute, { attribute: input.atribute }) }}</p>
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                           
                            <sim-button class="mr-2" type="submit" :loading="loading">
                                <i class="material-icons">save</i>
                                {{ isUpdating ? l.app.Update_changes : l.app.Save_Changes }}
                            </sim-button>

                            <sim-button outlined :to="{ name: 'jobs.index' }">
                                <i class="material-icons">clear</i>
                                <span style="color: #e01f8b !important;">{{l.app.Cancel}}</span>
                            </sim-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import 'vue-select/src/scss/vue-select.scss';
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select';

export default {
    data: () => ({
        picture: null,
        isUpdating: false,
        pictureV: '',
        selectData: {
            type: {},
            price_range_id: {},
            size: {},
            category_id: {},
            brand_id: {},
            fit_type: {},
            condition: {},
        },
        source: {
            country:[],
        },
        form:[
            {label: 'name', atribute: 'name', type: 'text', visibility: true},
        ],
        pictures:[],
        formData: {
            title: '',
            description:'',
            position_type:'',
            primary_role:'',
            years_work_experience:'',
            required_skills: [],
            min_yearly_salary:'',
            min_yearly_salary_amount:'',
            max_yearly_salary_amount:'',
            min_yearly_salary_currency: 'GBP',
            max_yearly_salary_currency: 'GBP',
            min_bonus:'',
            max_bonus:'',
            how_to_apply: '',
            contact_person: '',
            contact_info: '',
            company_name: '',
            contact_email: '',
            num_employees: '',
            anonymous: '',
            currency_iso: 'GBP',     
        },
        receipt: [
            {label: 'Primera Imagen', pictureV: '', file: null}
        ],
        certificate: [
            {label: 'Primera Imagen', pictureV: '', file: null}
        ],
        calculatePoints:{
            type:null,
            category_id:null,
            price_range_id: null,
            brand_id:null,
        },
        loading: null
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        ...mapState({
            lang: state => state.lang,
            errorsS: state => state.errors,
            jobs: state => state.jobs.pagination.data,
            garment: state => state.jobs.garment,
            categories: state => state.categories.pagination,
            brands: state => state.brands.pagination,
            priceRanges: state => state.PriceRanges.pagination,
            companiesList: state => state.companies.current,
            user: state => state.session.user
        }),
        companies() {
          console.log(this.companiesList)
           if (this.companiesList) {
            return this.companiesList.map((company) => {
              return { label: company.name, code: company.id }
            })
          } else {
             return []
          }
        }
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            store: 'jobs/store',
            removeError: 'removeError',
            fetchJob: 'jobs/fetchJob',
            update: 'jobs/update',
            fetchCompanies: 'companies/list'
        }),

        addImgADD(img, picture) {
            picture.file = img;
        },
        addImgPV(img, picture) {
            picture.pictureV = img;
        },
        addArryPicture(){
            this.pictures.push({label: 'Primera Imagen', pictureV: '', file: null});
        },
        activeInput(){
            if(this.formData.brand_id === -1){
                this.form[10].visibility=true;
            }else{
                this.form[10].visibility=false;
                this.formData.other_brand= '';
            }
        },
        updateData(){
            this.formData.id = this.$route.params.id;
            this.formData.years_work_experience = this.formData.years_work_experience.code;
            this.formData.position_type = this.formData.position_type.code
            console.log("formData",this.formData)
            this.update(this.formData).then(()=>{
                return this.$router.push('/jobs?limit=10&page=1')
            });
        },
        storeData(){
            this.formData.years_work_experience = this.formData.years_work_experience.code;
            this.formData.position_type = this.formData.position_type.code
            this.store(this.formData).then(()=>{
                return this.$router.push('/jobs?limit=10&page=1')
            });
        },
        getCompaniesList() {
          console.log(this.companiesList)
        }
    },
    watch: {
      // 'formData.company_name' (e) {
      //   this.fetchCompanies({search: e.label})
      // }
      'formData.contact_email' (e) {
        console.log(e)
      },
      loading (e) {
        console.log(e)
      }
    },
    components: {
        vSelect,
        VueEditor
    },
    created() {
        this.loading = true
        if(this.$route.name=== 'jobs.create'){
            this.setNavbarTitle(['List of Jobs' , this.l.app.Create])
            this.formData.contact_person = this.user.profile.name + ' ' + this.user.profile.last_name
            this.formData.contact_email = this.user.email
            this.formData.company_name = this.user.company ? this.user.company : 'pensionplaypen'
            this.loading = false
        }else{
            const { id } = this.$route.params;
            this.isUpdating = true;
            this.setNavbarTitle(['List of Jobs', this.l.app.To_update])
            this.fetchJob(id)
            .then(job => {
                this.formData = {
                    title: job.title,
                    description: job.description,
                    position_type:{ "label": job.position_type.replace("_", " "), "code": job.position_type },
                    primary_role:job.primary_role,
                    years_work_experience:{ "label": `+${job.years_work_experience}`, "code": `+${job.years_work_experience}` },
                    required_skills: [],
                    min_yearly_salary:job.min_yearly_salary,
                    min_yearly_salary_amount:job.min_yearly_salary_amount,
                    min_yearly_salary_currency: 'GBP',
                    max_yearly_salary_currency: 'GBP',
                    min_bonus:job.min_bonus,
                    max_bonus:job.max_bonus,
                    how_to_apply: job.how_to_apply,
                    contact_person: job.contact_person,
                    contact_info: job.contact_info,
                    company_name: job.company_name,
                    contact_email: job.contact_email,
                    num_employees: job.num_employees,
                    anonymous: job.anonymous,
                    currency_iso: 'GBP',
                };
                console.log(this.formData)
            }).finally(() => this.loading = false)
        }
        // this.fetchCompanies({ limit: 0 }).then(() => {
        this.form = [
          {label: 'Title', atribute: 'title', type: 'text', visibility: true},
          {label: 'Description', atribute: 'description', type: 'textArea', visibility: true},
          {
            label: 'Position type', atribute: 'position_type', type: 'select', visibility: true ,
            source:[
              {label:'Full time employee',code:'Full time employee'},
              {label:'Part time employee',code:'Part time employee'},
              {label:'Fixed term contract',code:'Fixed term contract'},
            ],
          },
          {label: 'Primary role', atribute: 'primary_role', type: 'text', visibility: true},
          {label: 'Years work experience', atribute: 'years_work_experience', type: 'select', visibility: true,
            source:[
              {label:'+0',code:'+0'},
              {label:'+1',code:'+1'},
              {label:'+2',code:'+2'},
              {label:'+3',code:'+3'},
              {label:'+4',code:'+4'},
              {label:'+5',code:'+5'},
              {label:'+6',code:'+6'},
              {label:'+7',code:'+7'},
              {label:'+8',code:'+8'},
              {label:'+9',code:'+9'},
              {label:'+10',code:'+10'},
            ],},
          //{label: 'Required Skills', atribute: 'required_skills', type: 'text', visibility: true}, deuda tecnica

          {label: 'Min yearly salary', atribute: 'min_yearly_salary_amount', type: 'number', visibility: true},
          {label: 'Max yearly salary', atribute: 'max_yearly_salary_amount', type: 'number', visibility: true},

          {label: 'Min bonus', atribute: 'min_bonus', type: 'number', visibility: true},
          {label: 'Max bonus', atribute: 'max_bonus', type: 'number', visibility: true},


          {label: 'Contact person', atribute: 'contact_person', type: 'text', visibility: true},
          //{label: 'Contact info', atribute: 'contact_info', type: 'text', visibility: true},
          {label: 'Contact mail', atribute: 'contact_email', type: 'email', visibility: true},


          {label: 'Company name', atribute: 'company_name', type: 'text', visibility: true},
          {label: 'Num employees', atribute: 'num_employees', type: 'number', visibility: true},
          {label: 'Anonymous', atribute: 'anonymous', type: 'checkbox', visibility: true,description:'Posts will not show user or company name'},
        ]
        // console.log(this.user.company)
        // this.loading = false;
    }
}
</script>

<style lang="scss">
.conte_img{
    display: table;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}
.add_img{
    border: 2px dashed rgb(235, 237, 242) !important;
    width: 180px;
    height: 250px;
    display: flex;
    -webkit-box-align: center;
    cursor: pointer;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    i {
        color: #ebedf2;
        font-size: 70px;
    }

    &:hover {
        i {
        color: #374afb;
        }
    }
}
.deleteIcon {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 1;
}
</style>