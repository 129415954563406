export default ({ axios, mapQueryString }) => ({
    index: (query = {}) => axios.get(`/forums${mapQueryString(query)}`),
    show: (id = {}) => axios.get(`/forums/${id}`),
    store: (data) => axios.post(`/forums`, data),
    update: (id, data) => axios.patch(`/forums/${id}`, data),
    delete: (id) => axios.delete(`/forums/${id}`),
    uploadFeaturedImage: (id, data) => axios.post(`/upload-featured-image/${id}`,data),
    fileResources: (id, data) => axios.post(`/file-resources/${id}`,data),
    //-----//
    categoriesIndex: (query = {}) => axios.get(`/categories${mapQueryString(query)}`),
    categoriesShow: (id) => axios.get(`/categories/${id}`),
    categoriesStore: (data) => axios.post(`/categories`, data),
    categoriesUpdate: (id, data) => axios.patch(`/categories/${id}`, data),
    categoriesDelete: (id) => axios.delete(`/categories/${id}`)
})