import { Authenticated } from '../middlewares'
export default [
    {
        path: '/sectors',
        name: 'sectors.index',
        component: () => import('../../views/sectors/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'sectors.index' }), label: () => 'List of sectors', loading: () => false }
            ]
        }
    },
    {
        path: '/sectors/create',
        name: 'sectors.create',
        component: () => import('../../views/sectors/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'sectors.create' }), label: () => 'List of sectors', loading: () => false }
            ]
        }
    },
    {
        path: '/sectors/:id',
        name: 'sectors.show',
        component: () => import('../../views/sectors/show.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'sectors.show' }), label: () => 'List of sectors', loading: () => false },
                { route: ({ route }) => ({ name: 'sectors.show', params: route.params }), label: ({ store: { state: { sectors } } }) => `Sector #${sectors.current?.id ?? 'NO'}`, loading: ({ store: { state: { sectors } } }) => sectors.loadings.show }
            ]
        }
    },
    {
        path: '/sectors/:id/edit',
        name: 'sectors.edit',
        component: () => import('../../views/sectors/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'sectors.edit' }), label: () => 'List of sectors', loading: () => false },
                { route: ({ route }) => ({ name: 'sectors.show', params: route.params }), label: ({ store: { state: { sectors } } }) => `Sector #${sectors.current?.id ?? 'NO'}`, loading: ({ store: { state: { sectors } } }) => sectors.loadings.show },
                { route: ({ route }) => ({ name: 'sectors.edit', params: route.params }), label: () => `Editar` },
            ]
        }
    },
]