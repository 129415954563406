<template>
	<div class="login-screen">
		<div class="left-login-area"></div>
		<div class="right-login-area py-3">
			<form
				@submit.prevent="sendLogin()"
				style="min-width: 315px"
				@keydown="removeError($event.target.id)"
			>
				<playpen-logo class="d-block mb-4" width="100%" height="48px" />

				<p class="text-center mb-3">
					{{ l.app.welcome }}
				</p>

				<div>
					<div class="form-group">
						<label for="" style="display: block;"> {{ l.app.Email }}</label>
						<sim-input id="email" bordered v-model="guest.email">
							<template #suffix-icon>
								<fa-icon icon="user-circle" color="#000" />
							</template>
						</sim-input>
						<p v-if="hasError('login')">
							{{ error("login", {attribute: "correo electrónico"}) }}
						</p>
					</div>
					<div class="form-group">
						<label for="" style="display: block;"> {{ l.app.password }}</label>
						<sim-input
							id="password"
							bordered
							v-model="guest.password"
							:type="typePassword"
						>
							<template #suffix-icon>
								<fa-icon
									:icon="typePassword=='password'?'eye':'eye-slash'"
									color="#000"
									class="cursor-pointer"
									@click="togglePassword()"
								/>
							</template>
						</sim-input>
						<p v-if="hasError('password')">
							{{ error("password", {attribute: "contraseña"}) }}
						</p>
					</div>
				</div>

				<div class="d-flex justify-content-between mb-3">
					<router-link :to="{name: 'reset-password'}" class="sim-link">
						{{ l.app.forgot_password }}
					</router-link>
				</div>

				<sim-button :loading="loading" submit block>
					{{ l.app.login}}
				</sim-button>
			</form>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters, mapState} from "vuex";
	import playpenLogo from "@/components/playpenLogo.vue";
	export default {
		data: () => ({
			typePassword: "password",
			guest: {
				email: "",
				password: "",
				remember: false,
			},
			lang: "es",
		}),
		watch: {
			lang(val) {
				this.translate(val);
			},
		},
		computed: {
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
        isAdmin: "session/isAdmin"
			}),
			...mapState({
				loading: (state) => state.session.loading,
			}),
		},
		methods: {
			...mapActions({
				login: "session/login",
				translate: "translate",
				removeError: "removeError",
			}),
			togglePassword() {
				if (this.typePassword === "password") {
					this.typePassword = "text";
				} else {
					this.typePassword = "password";
				}
			},
			sendLogin(){
				this.login({
					email: this.guest.email,
					password: this.guest.password
				}).then(() => {
          console.log(this.isAdmin)
        })
			}
		},
		components: {
			playpenLogo,
		},
	};
</script>

<style lang="scss" scoped>
  .cursor-pointer{
    cursor: pointer;
  }

	.login-screen {
		min-height: 100vh;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.left-login-area,
		.right-login-area {
			display: flex;
			flex-grow: 1;
			height: 100%;
		}

		.left-login-area {
			background: #4d4f5c;
		}

		.right-login-area {
			justify-content: center;
			align-items: center;
		}
	}

	@media (max-width: 800px) {
		.login-screen {
			grid-template-columns: 1fr;
		}

		.left-login-area {
			display: none !important;
		}
	}
</style>
