<template>
    <nav :class="[`${prefix}-navbar`]">
        <!-- <div class="content"> -->
            <slot />
        <!-- </div> -->
    </nav>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `navbar`,
    props: {},
    data: () => ({
        prefix
    })
}
</script>