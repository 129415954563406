import { OnlyPublic } from '../middlewares'

export default [
	{
        path: '/',
        name: 'login',
        alias: '/login',
        component: () => import(/* webpackChunkName: "empty" */ '../../views/auth/login.vue'),
        meta: {
            middleware: OnlyPublic
        }
    },
    {
        path: '/confirm-account',
        name: 'confirm-account',
        component: () => import(/* webpackChunkName: "confirm-account" */ '../../views/auth/confirm-account.vue'),
        meta: {
            middleware: OnlyPublic
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "confirm-account" */ '../../views/auth/reset-password/index.vue'),
        meta: {
            middleware: OnlyPublic
        }
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password-token',
        component: () => import(/* webpackChunkName: "confirm-account" */ '../../views/auth/reset-password/_token.vue'),
        meta: {
            middleware: OnlyPublic
        }
    },
    {
        path: '/recover-password/:token',
        name: 'recover-password',
        component: () => import(/* webpackChunkName: "confirm-account" */ '../../views/auth/recover-password.vue'),
        meta: {
            middleware: OnlyPublic
        }
    }
]