<template>
  <div class="row">
    <div class="flex justify-end mb-4 col-12">
      <sim-button align="right" :loading="loading" :to="{ name: 'companies.create' }">
        <i class="material-icons">add</i> {{this.l.app.Create}}
      </sim-button>
    </div>
    <!-- aqui va la tabla -->
    <sim-table
      :loading="loading"
      :columns="columns"
      :toolbar-per-page="$route.query.limit"
      @toolbar:search="searchData({ search: $event, page: 1 })"
      @toolbar:per-page="searchData({ limit: $event })"
      @sort-change="searchData({ order_by: `${$event.key},${$event.direction}` })"
      :source="pagination.data"
    >
	
      <template #filter:sector>
        <v-select
          label="name"
          :loading="loadings.sectors"
          :reduce="(sector) => sector.id"
          :options="sectors.data"
          @input="searchData({ sector_id: $event })"
          @search="searchSectors({ query: $event, page: 1, limit: 10 })"
          :filterable="false"
        >
          <template #list-footer v-if="sectors.total_pages > 1">
            <li class="pagination">
              <p class="text-center my-2">
                Página {{ sectors.page }} de {{ sectors.total_pages }}
              </p>
              <div class="flex space-x-2 px-2 pt-2">
                <button
                  class="bg-gray-200 flex-grow rounded-md py-2"
                  @click="searchSectors({ page: sectors.page - 1, limit: 10 })"
                  v-if="sectors.page !== 1"
                >
                  Anterior
                </button>
                <button
                  class="bg-gray-200 flex-grow rounded-md py-2"
                  @click="searchSectors({ $event, page: sectors.page + 1, limit: 10 })"
                  v-if="sectors.page < sectors.total_pages"
                >
                  Siguiente
                </button>
              </div>
            </li>
          </template>
        </v-select>
      </template>

      <template #filter:status>
        <select
          :value="$route.query.status"
          class="py-3 px-4 appearance-none w-full font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          @input="searchData({ status: $event.target.value })"
        >
          <option value="">-- TODOS --</option>
          <option value="pending">Pendientes</option>
          <option value="active">Activos</option>
          <option value="inactive">Inactivos</option>
        </select>
      </template>

      <template #td:state="{ record }">
        {{ record.item.status }}
      </template>

      <template #td:actions="{ record }">
        <div class="flex space-x-2">
          <template v-if="record.item.status && record.item.status != 'pending'">
            <label
              v-if="record.item.status != 'active'"
              :title="'Activate'"
              @click="active(record.item.id)"
              class="btn btn-label-primary text-uppercase"
            >
              <i class="material-icons">done_all</i>
            </label>
            <label
              v-else
              :title="l.app.desactivate"
              @click="deactivate(record.item.id)"
              class="btn btn-label-primary text-uppercase"
            >
              <i class="material-icons">unpublished</i>
            </label>
          </template>
          <template v-if="record && record.item && record.item.status">
          </template>
          <router-link
            :to="{ name: 'companies.edit', params: { id: record.item.id } }"
            :title="l.app.Edit"
            class="btn btn-label-warning text-uppercase"
          >
            <i class="material-icons">edit</i>
          </router-link>
          <label
            @click="deleteCompany(record.item.id).then(() => searchData())"
            :title="l.app.Remove"
            class="btn btn-label-danger text-uppercase"
          >
            <i class="material-icons"> delete</i>
          </label>
        </div>
      </template>

    </sim-table>

    <sim-pagination
      align="right"
      :data="pagination"
      @change="searchData({ page: $event })"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data: () => ({
    query: {
      page: 1,
    },
    sectorsQuery: {
      page: 1,
    },
    sectors: { results: [] },
    loadings: {
      sectors: false,
    },
  }),
  computed: {
    ...mapState({
      pagination: (state) => state.companies.pagination,
      loading: (state) => state.companies.loadings.index,
      session: (state) => state.session.user,
    }),
    ...mapGetters({
      l: "lang",
    }),
    columns() {
      return [
        { title: this.l.app.Company_name, key: "name", sortable: true },
        {
          title: this.l.app.Sector,
          key: "sector.name",
          filter: true,
          keyFilter: "sector",
        },
        {
          title: this.l.app.Active_Inactive,
          slot: "state",
          alignment: "right",
          filter: true,
          key: "status",
        },
        {
          title: this.l.app.Actions,
          slot: "actions",
          alignment: "right",
          key: "id",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchCompanies: "companies/index",
      deleteCompany: "companies/delete",
      update: "companies/update",
    }),
    searchSectors(_query = {}) {
      this.sectorsQuery = { ...this.sectorsQuery, ..._query };
      this.loadings.sectors = !this.loadings.sectors;
      this.$repository.sectors
        .index(this.sectorsQuery)
        .then(({ data }) => (this.sectors = data))
        .finally(() => (this.loadings.sectors = !this.loadings.sectors));
    },
    searchData(_query = {}) {
      const query = this.$queryUtils.withoutInvalidValues({
        ...this.query,
        ..._query,
      });
      this.fetchCompanies(query).then(() => {
        this.$router.replace({ query }).catch(() => null);
      });
    },
    async active(id) {
      if (confirm("Are you sure to activate this Company?")) {
        let response = await this.update({ id: id, status: "active" });
        if (response) {
          let index = this.pagination.data.findIndex((item) => item.id == id);
          if (index >= 0) {
            this.pagination.data[index].status = response.status;
          }
        }
      }
    },
    async deactivate(id) {
      if (confirm("Are you sure to disable this Company?")) {
        let response = await this.update({ id: id, status: "inactive" });
        if (response) {
          let index = this.pagination.data.findIndex((item) => item.id == id);
          if (index >= 0) {
            this.pagination.data[index].status = response.status;
          }
        }
      }
    },
  },
  mounted() {
    this.searchData();
    this.searchSectors();
  },
  created() {
    this.query = { ...this.query, ...this.$route.query, query: "" };
  },
};
</script>
