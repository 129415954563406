<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full">
                <sim-card>
                    <div class="w-full">
                        <div class="form-group">
                            <label for="name">Title</label>
                            <sim-input
                                id="title"
                                :error="error('title', { attribute: 'title' })"
                                v-model="post.title"/>
                        </div>
                        
                        <sim-img-blog :img_url="pictureV" @editImg="addImg($event, picture)" @editImgVie="addImgPV($event, picture)" />

						<!--sim-img-cropper-blog
							:img_url="pictureV"
							@editImg="addImg($event, picture)"
							@editImgVie="addImgPV($event, picture)"
						/-->

						<label for="name">Biography of the person</label>
						<vue-editor
							v-model="post.content"
							:editorToolbar="customToolbar"
						></vue-editor>

						<div class="w-full" style="padding-right: 10px;">
							<div
								class="checkbox"
								style="margin-top: 9px; position: relative;"
							>
								<label>
									<input type="checkbox" v-model="post.allow_comments" />
									<i class="input-helper"></i>
									Allow comments
								</label>
							</div>
						</div>

						<div class="w-full" style="padding-right: 10px;text-align: center;">
							<div
								class="checkbox"
								style="margin-top: 9px; position: relative;"
							>
								<label>
									<input type="checkbox" v-model="post.receive_alerts" />
									<i class="input-helper"></i>
									Receive an alert when someone comments
								</label>
							</div>
						</div>

						<sim-button class="mr-2" :loading="loadings.store" :disabled="loadings.store" @click="save()">
							<i class="material-icons">save</i>
							{{ l.app.Save_Changes }}
						</sim-button>
						<sim-button
							@click="$router.push('/blog')"
							outlined
							style="color: #e01f8b !important;"
						>
							<i class="material-icons">clear</i>
							{{ l.app.Cancel }}
						</sim-button>
					</div>
				</sim-card>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions,mapState, mapGetters} from "vuex";
	import {VueEditor} from "vue2-editor";

	export default {
		data: () => ({
			picture: null,
			isUpdating: false,
			pictureV: "",
			biography: "",
			customToolbar: [
				["bold", "italic", "underline"],
				[{list: "ordered"}, {list: "bullet"}],
			],
			post: {},
		}),
		computed: {
			...mapState({
				loadings: (state) => state.post.loadings,
			}),
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
		},
		components: {
			VueEditor,
		},
		methods: {
			...mapActions({
				removeError: "removeError",
				show: "post/show",
				update: "post/update",
				store: "post/store",
				updateProfilePicture: "post/updateProfilePicture",
			}),
			addImg(img, picture) {
				console.log(img, picture);
			},
			addImgPV(img, picture) {
				console.log(img, picture);
			},
			async save() {
				if (this.post.hasOwnProperty("id")) {
					this.update(this.post).then(() => {
						this.$router.push("/blog");
					});
				} else {
					this.store(this.post).then(() => {
						this.$router.push("/blog");
					});
				}
			},
		},
		created() {
			const {id} = this.$route.params;
			if(this.$route.name=== 'blog.edit'){
				this.show(id).then((res) => {
					this.post = res;
				});
			}
		},
	};
</script>
