<template>
    <div :class="['container', { 'fluid': fluid }]">
        <slot />
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `container`,
    props: {
        fluid: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix
    })
}
</script>