// import router from '@/router';
// import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        all: [],
        current: {},
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_REQUESTS: (state, pagination) => state.pagination = pagination,
        SET_REQUEST: (state, company) => state.current = company,
        SET_REQUEST_LIST: (state, company) => state.current = company,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        index({ commit }, query = {} ) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.companies.requests()
                .index(query)
                .then(({ data }) => {
                    // console.log(data.data)
                    data.data.map($0 => {

                        if ($0.value.sector_id && $0.value.sector_name) {
                            delete $0.value.sector_id
                        }
                        if ($0.value.socials) {
                            $0.value['socials'] = JSON.parse($0.value.socials)
                        }
                        return $0
                    })
                    commit('SET_PAGINATED_REQUESTS', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        // list({ commit }, page = {} ) {
        //     commit('TOGGLE_LOADING', 'list')
        //     return this.$repository.companies
        //         .listCompanies(page)
        //         .then(({ data }) => {
        //             commit('SET_COMPANY_LIST', data)
        //             return data
        //         })
        //         .finally(() => commit('TOGGLE_LOADING', 'list'))
        // },
        // show({ commit }, id) {
        //     commit('TOGGLE_LOADING', 'show')
        //     return this.$repository.companies
        //         .show(id)
        //         .then(({ data }) => {
        //             commit('SET_COMPANY', data)
        //             return data
        //         })
        //         .finally(() => commit('TOGGLE_LOADING', 'show'))
        // },
        // store({ commit }, body) {
        //     commit('TOGGLE_LOADING', 'store')
        //     return this.$repository.companies
        //         .store(body)
        //         .then(({ data }) => {
        //             Vue.prototype.$message.success({
        //                 message: `Se ha creado el administrador con éxito`,
        //                 text: `Se ha creado el administrador con éxito`,
        //                 description: `Se ha creado el administrador con éxito`,
        //                 position: `top-right`
        //             })
        //             router.push({ name: 'companies.index' })
        //             return data
        //         })
        //         .finally(() => commit('TOGGLE_LOADING', 'store'))
        // },
        // async update({ commit }, body) {
        //     commit('TOGGLE_LOADING', 'update')
        //     return await this.$repository.companies
        //         .update(body.id, body)
        //         .then(({ data }) => {
        //             Vue.prototype.$message.success({
        //                 message: `The user has been updated successfully`,
        //                 text: `The user has been updated successfully`,
        //                 description: `The user has been updated successfully`,
        //                 position: `top-right`
        //             })
        //             router.push({ name: 'companies.index' })
        //             return data
        //         })
        //         .finally(() => commit('TOGGLE_LOADING', 'update'))
        // },
        // delete({ commit }, id) {
        //     return new Promise((resolve, reject) => {
        //         if (confirm('¿Desea eliminar este administrador?')) {
        //             commit('TOGGLE_LOADING', 'delete')
        //             return this.$repository.companies
        //                 .delete(id)
        //                 .then(({ data }) => resolve(data))
        //                 .catch(err => reject(err))
        //                 .finally(() => commit('TOGGLE_LOADING', 'delete'))
        //         } else {
        //             reject()
        //         }
        //     })
        // }
    },
    getters: {
        companyCanDo: (state, getters, rootState, rootGetters) => action => {
            return rootGetters["session/isAuthenticated"]
                && rootState.session.company.permissions.hasOwnProperty(action)
                && rootState.session.company.permissions[action]
        }
    }
}
