<template>
    <div class="login-screen">
        <div class="left-login-area flex flex-col justify-center items-center">
            <playpen-logo class="d-block mb-4" width="100%" height="48px" color="white"/>
            <h3 class="text-center mb-3 text-white">
                Bienvenido, por favor ingrese su nueva contraseña
            </h3>
        </div>
        <div class="right-login-area flex-col py-3">
            <h3>Confirmar cuenta</h3>
            <form @submit.prevent="confirmAccount(guest)" style="max-width: 365px" @keydown="removeError($event.target.id)">
                <div class="form-group">
                    <label for="password">Contraseña</label>
                    <sim-input
                        id="password" 
                        type="password"
                        :error="error('password', { attribute: 'contraseña' })"
                        v-model="guest.password">
                        <template #suffix-icon>
                            <fa-icon icon="asterisk" color="#4D4F5C"/>
                        </template>
                    </sim-input>
                </div>

                <sim-button :loading="loading" submit block>
                    Confirmar
                </sim-button>
                <sim-button class="mt-2 text-center" :to="{ name: 'login' }" submit block>
                    Iniciar sesión
                </sim-button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import playpenLogo from '@/components/playpenLogo.vue';
export default {
    data: () => ({
        guest: {
            password: '',
            token: null
        },
        lang: 'es'
    }),
    watch: {
        lang(val) {
            this.translate(val)
        }
    },
    computed: {
        ...mapGetters({
            error: 'error',
            hasError: 'hasError'
        }),
        ...mapState({
            loading: state => state.session.loading
        })
    },
    methods: mapActions({
        translate: 'translate',
        removeError: 'removeError',
        confirmAccount: 'session/confirmAccount'
    }),
    components: {
        playpenLogo
    },
    mounted() {
        this.guest.token = this.$route.query?.token
    }
}
</script>

<style lang="scss" scoped>
    .login-screen {
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .left-login-area, .right-login-area {
            display: flex;
            flex-grow: 1;
            height: 100%;
        }

        .left-login-area {
            background: #4D4F5C;
        }

        .right-login-area {
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 800px) {
        .login-screen {
            grid-template-columns: 1fr;
        }

        .left-login-area {
            display: none !important;
        }
    }
</style>