<template>
  <div>
    <div class="flex flex-wrap mb-2">
      <label @click="$router.back()" class="btn text-uppercase">
        <i class="material-icons">keyboard_backspace</i>
        {{ l.app.To_return }}
      </label>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full">
        <sim-card>
          <div class="w-full">
            <div class="form-group">
              <label for="name">{{l.app.Title}}</label>
              <sim-input
                id="title"
                :error="error('title', { attribute: 'title' })"
                v-model="media.title"
                :required="true"
              />
            </div>

            <div class="form-group">
              <label for="description">{{l.app.Description}}</label>
              <vue-editor id="description" v-model="media.description" :error="error('description', { attribute: 'description' })"></vue-editor>
            </div>

            <div class="form-group">
              <select
                class="input-style"
                id="type"
                v-model="media.type"
                :error="error('type', { attribute: 'type' })"
                @change="clearFile(true)"
              >
                <option value="video" selected>Video</option>
                <option value="podcast">Podcast</option>
                <option value="press">Press</option>
              </select>
            </div>

            <div v-if="true" class="w-full" style="padding-right: 10px">
              <div class="checkbox" style="margin-top: 9px; position: relative">
                <label>
                  <input type="checkbox" v-model="media.isLink" />
                  <i class="input-helper"></i>
                  Is link
                </label>
              </div>
            </div>

            <template v-if="media.isLink">
              <div class="form-group">
                <label for="name">Url media</label>
                <sim-input
                  id="url"
                  type="url"
                  :error="error('url', { attribute: 'url' })"
                  v-model="media.url"
                />
                <button
                  v-if="media.url && media.url != ''"
                  @click="clearFile(false)"
                  class="btn-clear-file"
                >
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </template>
            <template v-else>
              <div class="form-group">
                <label for="name">Attach file</label>
                <input
                  type="file"
                  @change="onChangeFile(1)"
                  ref="inputFile"
                  class="form-control add-files"
                  id=""
                />
                <button
                  v-if="$refs.inputFile && $refs.inputFile.value"
                  @click="clearFile(true)"
                  class="btn-clear-file"
                >
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </template>

            <div class="row input-group flex-column margin-l-image">
              

              <!--div v-if="imgTem" class="file-upload"-->
                  <!--img :src="imgTem" alt="" /-->
                <div style="margin-left: -20px;position: relative;width: 338px;">
                  <label for="" class="">Cover image</label>
                  <sim-img-blog
                    :img_url="media.featured_image_url || picture"
                    @editImg="addImg($event, picture)"
                    @editImgVie="addImgPV($event, picture)"
                    :custom_class="'kt-avatar-blog__holder2'"
                    :detailTex="''"/>
                    <i v-if="media.featured_image_url !==null || picture !==null" class="material-icons delete_bt" @click="clearCover()">delete</i>
                </div>
               
                <!--div class="file btn btn-lg btn-primary">
                  Change picture
                  <input
                    type="file"
                    @change="previewFiles"
                    name="file"
                    ref="inputImage1"
                  />
                </div>
              </div>
              <div  class="file-upload">
                <div class="profile-img upload-arrow">
                  <div class="file btn btn-lg btn-primary">
                    Upload picture
                    <input
                      type="file"
                      @change="previewFiles"
                      name="file"
                      ref="inputImage2"
                    />
                  </div>
                </div>
              </div-->
            </div>

            <div class="w-full my-2" style="padding-right: 10px">
              <div class="checkbox" style="margin-top: 9px; position: relative">
                <label>
                  <input type="checkbox" v-model="media.allow_comments" />
                  <i class="input-helper"></i>
                  {{l.app.allow_comments}}
                </label>
              </div>
            </div>

            <sim-button class="mr-2" @click="confirmSave()" :disabled="loadings.store" :loading="loadings.store">
              <i class="material-icons">save</i>
              {{ l.app.Save_Changes }}
            </sim-button>
            <sim-button
              @click="$router.push('/media')"
              outlined
              style="color: #e01f8b !important"
            >
              <i class="material-icons">clear</i>
              {{ l.app.Cancel }}
            </sim-button>
          </div>
        </sim-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import Vue from 'vue';

export default {
  data: () => ({
    imgData: null,
    imgTem: null,
    resource: null,
    resource2: null,
    picture: null,
    isUpdating: false,
    pictureV: "",
    biography: "",
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    media: {
      type: "video",
      isLink: false,
      field: "",
      url: "",
      media_file: null,
      featured_image: null,
    },
  }),
  computed: {
    ...mapState({
      loadings: (state) => state.media.loadings,
    }),
    ...mapGetters({
      hasError: "hasError",
      error: "error",
      l: "lang",
    }),
  },
  components: {
    VueEditor,
  },
  methods: {
    ...mapActions({
      removeError: "removeError",
      show: "media/show",
      update: "media/update",
      store: "media/store",
      updateProfilePicture: "media/updateProfilePicture",
      uploadFeaturedImage: "media/uploadFeaturedImage",
    }),
     addImg(img /*, picture*/) {
      console.log("addImg", img);
      if(this.media.id){
        const data = new FormData();
        data.append("image", img);
        this.uploadFeaturedImage({
          image:data,
          itemId: this.media.id,
        })
      }else{
        this.imgData = img
        // this.resource = img
        //, this.imgData.name
      }
    },
    addImgPV(img /*, picture*/) {
      this.picture = img;
    },
    previewFiles() {
      this.imgData = null;

      if (
        this.$refs.inputImage1 &&
        this.$refs.inputImage1.files &&
        this.$refs.inputImage1.files.length
      ) {
        this.imgData = this.$refs.inputImage1.files[0];
        // this.imgTem = URL.createObjectURL(this.$refs.inputImage1.files[0]);
      } else if (
        this.$refs.inputImage2 &&
        this.$refs.inputImage2.files &&
        this.$refs.inputImage2.files.length
      ) {
        this.imgData = this.$refs.inputImage2.files[0];
        // this.imgTem = URL.createObjectURL(this.$refs.inputImage2.files[0]);
      } else {
        this.imgData = null;
        // this.imgTem = null;
      }

      if (this.imgData) {
        this.imgTem = URL.createObjectURL(this.imgData);
        this.uploadFeaturedImage({
          image:this.imgTem,
          itemId: this.media.id,
        })
      }
    },
    
    async confirmSave() {
      if (this.media.isLink) {
        var validLink = /^(ftp|http|https):\/\/[^ "]+$/.test(this.media.url);
        if (!validLink) {
          Vue.prototype.$message.error({
            message: `You must enter a valid url!`,
            position: `top-right`
          })
          return false;
        }
      }

      if (this.media.title && this.media.title != "") {
        let response = await confirm(
          `Are you sure you want to ${this.media.id ? "modify" : "create"} this registry?`
        );
        if (response) {
          this.save();
        }
      } else {
        Vue.prototype.$message.error({
            message: `You must fill in the requested fields!`,
            position: `top-right`
          })
      }
    },
    async save() {
      let data = null;
      data = new FormData();
      if (this.media.id) {
        data.append("id", this.media.id);
      }
      
      data.append("description", (this.media.description && this.media.description != "") ? this.media.description : '');

      if(this.imgData == null && this.media.id){
        data.append("featured_image", null);
      }
      console.log("this.imgData",this.imgData)
      if (this.imgData !== null) {
        console.log("this.imgData",this.imgData)
        data.append("featured_image", this.imgData, this.imgData.name);
      }

      if (this.media.isLink) {
        data.append("url", this.media.url);
      } else {
        data.append("media_file", this.resource, this.resource.name);
      }
      
      data.append("type", this.media.type);
      data.append("title", this.media.title);
      data.append("allow_comments", this.media.allow_comments ? "1" : "0");
      if (this.media.hasOwnProperty("id")) {
        this.update({
          //...data,
          ...this.media,
          itemId: this.media.id,
        }).then(() => {
          this.$router.push("/media");
        });
      } else {
        this.store(data).then(() => {
          this.$router.push("/media");
        });
      }
    },
    clearFile(flag) {
      if (flag) {
        this.$refs.inputFile.files = null;
        this.$refs.inputFile.value = null;
      } else {
        this.media.url = null;
      }
      this.$forceUpdate();
    },
    onChangeFile(flag) {
      let mediaTipes = "mp4";
      if (flag === 1) {
        if (this.media.type == "press") {
          mediaTipes = "pdf";
        } else if (this.media.type == "video") {
          mediaTipes = "mp4, 3gpp, quicktime, x-flv";
        } else {
          mediaTipes = "ogg, mpeg, mpga, mp3, wav";
        }

        if (
          this.$refs.inputFile.value &&
          ((this.media.type == "press" &&
            this.$refs.inputFile.files[0].type == "application/pdf") ||
            (this.media.type == "video" &&
              [
                "video/mp4",
                'video/x-flv',
                'video/MP2T',
                'video/3gpp',
                'video/quicktime',
                'video/x-msvideo',
                'video/x-ms-wmv',
              ].includes(this.$refs.inputFile.files[0].type)) ||
            (this.media.type == "podcast" &&
              [
                "audio/ogg",
                "audio/mp3",
                "audio/mpeg",
                "audio/wav",
                "audio/mpga",
              ].includes(this.$refs.inputFile.files[0].type)))
        ) {
          console.log(
            "this.resource = this.$refs.inputFile.files[0]: ",
            (this.resource = this.$refs.inputFile.files[0])
          );
          this.resource = this.$refs.inputFile.files[0];
        } else {
          this.$message.error(`Please verify the media file must be a file of type: ${mediaTipes}`);
          this.$refs.inputFile.files = null;
          this.$refs.inputFile.value = null;
          this.resource = null;
        }
      } else {
        mediaTipes = "jpeg, svg, png";
        if (
          ["image/jpeg", "image/svg+xml", "image/png"].includes(
            this.$refs.inputImage.files[0].type
          )
        ) {
          this.resource2 = this.$refs.inputImage.files[0];
        } else {
          this.$message.error(`Please verify the media file must be a file of type: ${mediaTipes}`);
          this.$refs.inputImage.files = null;
          this.$refs.inputImage.value = null;
        }
      }
      this.$forceUpdate();
    },
    async clearCover(){
      let response = await confirm(
        `Are you sure you want to remove this Cover image?`
      );
      if (response) {
        this.imgTem = null;
        this.imgData = null;
        this.picture = null;
        this.media.featured_image_url = null;
        this.uploadFeaturedImage({
          image:null,
          itemId: this.media.id,
        })
      } else {
        Vue.prototype.$message.error({
          message: `You must fill in the requested fields!`,
          position: `top-right`
        })
      }
    },
    Vue
  },
  created() {
    const { id } = this.$route.params;
    if (id && id != "") {
      this.show(id).then((res) => {
        this.media = {
          ...res,
          isLink: res.url && res.url != "",
        };
        this.imgTem =
          this.media.featured_image_url && this.media.featured_image_url != ""
            ? this.media.featured_image_url
            : null;
      });
    }
  },
  watch: {
    "media.isLink"(val) {
      if (val) {
        this.media.media_file = null;
      } else {
        this.media.url = "";
      }
    },
  },
};
</script>

<style>
  
.margin-l-image {
  margin-left: 0.1rem;
}
.delete_bt{
  z-index: 5;
  display: inline-block;
  position: absolute;
  color:#FF6565;
  right: 26px;
  top: 26px;
}
</style>
