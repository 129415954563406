<template>
  <div>
    <div class="flex flex-wrap mb-2">
      <label @click="$router.back()" class="btn text-uppercase">
        <i class="material-icons">keyboard_backspace</i>
        {{ l.app.To_return }}
      </label>
    </div>

    <div v-if="loading">
      <div class="bg-white p-2 dark:bg-gray-900">
        <p class="text-2xl text-center pt-16 p-8 animate-bounce">{{l.app.loading}}</p>
        <!-- <a-skeleton class="my-1 h-10 w-full rounded-xl" v-for="n in skeletonCount" :key="n" :style="{ height: skeletonHeight }" /> -->
      </div>
    </div>

    <div v-else class="flex flex-wrap">
      <div class="w-full">
        <form
          @submit.prevent="update(user)"
          @keydown="removeError($event.target.id)"
          v-if="user"
        >
          <sim-card>
            <h3>{{ this.l.app.users }}</h3>
            <div style="margin-left: -20px">
              <sim-img-blog
                v-if="user.profile"
                :img_url="picture"
                @editImg="addImg($event, picture)"
                @editImgVie="addImgPV($event, picture)"
                :custom_class="'kt-avatar-blog__holder2'"
                :detailTex="''"
              />
            </div>
            <div class="flex flex-wrap -mx-2">
              <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                <div class="form-group">
                  <label for="name">Work Email</label>
                  <sim-input
                    id="email"
                    placeholder="Email"
                    :error="error('email', { attribute: 'email' })"
                    v-model="user.email"
                  />
                </div>
              </div>
            </div>
            <template v-if="user.profile !== null">
              <h3>Profile</h3>
              <div
                class="w-full md:w-1/2 lg:w-1/2 px-2"
                v-for="(d, key) in Object.keys(user.profile)"
                :key="key"
              >
                <template
                  v-if="
                    // d !== 'id' &&
                    // d !== 'created_at' &&
                    // d !== 'address' &&
                    // d !== 'connection_status' &&
                    // d !== 'charity' &&
                    // d !== 'avatar_url' &&
                    // d !== 'country_name' &&
                    // d !== 'country_code' &&
                    // d !== 'user_id' &&
                    // /*d !== 'email' &&*/
                    // d !== 'charity_id' &&
                    // d !== 'deleted_at' &&
                    // d !== 'is_featured' &&
                    // d !== 'employer' &&
                    // d !== 'updated_at' &&
                    // d !== 'biography' &&
                    // d !== 'email_verified' &&
                    // d !== 'preferences' &&
                    // d !== 'email_verified_at'
                    d === 'email' ||
                    d === 'name' ||
                    d === 'last_name' ||
                    d === 'job_title'
                  "
                >
                  <div class="form-group">
                    <label for="name" :style="`text-transform: capitalize`">{{
                      d === "email" ? "Personal Email" : d.replace("_", " ")
                    }}</label>
                    <sim-input
                      :id="d"
                      :error="error(d, { attribute: d })"
                      v-model="user.profile[d]"
                    />
                  </div>
                </template>
              </div>
              <div class="form-group" v-if="user.profile.id">
                <label for="created_at" :style="`text-transform: capitalize`"
                  >Created at</label
                >
                <sim-input
                  :id="'created_at'"
                  disabled
                  v-model="user.profile.created_at"
                />
              </div>
              <div class="checkbox" style="margin: 20px 0px; position: relative">
                <label>
                  <input type="checkbox" v-model="selfEmployer" />
                  <i class="input-helper"></i>
                  Self employed
                </label>
              </div>
              <div v-if="!selfEmployer" class="flex flex-col">
                <label for="target_ids">Current employer</label>
                <v-select
                  id="target_type"
                  @input="setEmployer($event)"
                  @search="getCompaniesList($event)"
                  :options="messageTypes"
                  :reduce="(option) => option.value"
                  v-model="user.profile.employer"
                />
              </div>

              <div class="checkbox" style="margin: 20px 0px; position: relative">
                <label>
                  <input type="checkbox" v-model="user.profile.is_featured" />
                  <i class="input-helper"></i>
                  All stars
                </label>
              </div>

<!--              <sim-button class="mr-2" @click="set_biography()">-->
              <sim-button class="mr-2" @click="modal = true">
                <i class="material-icons">save</i>
                {{ l.app.Save_Changes }}
              </sim-button>
              <sim-button
                outlined
                :to="{ name: 'users.index' }"
                style="color: #e01f8b !important"
              >
                <i class="material-icons">clear</i>
                {{ l.app.Cancel }}
              </sim-button>
            </template>
          </sim-card>
        </form>
      </div>
    </div>
    <sim-Modal :show="modal" @close="modal = false" @cancel="modal = false" confirmModal>
      <h4 slot="header">{{ l.app.Save_Changes }}</h4>
      <template slot="footer">
        <div class="text-right">
          <sim-button outlined @click="modal = false" style="color: rgb(224, 31, 139) !important; margin-right: 15px;">
            <i class="material-icons"></i>
            {{ l.app.Cancel }}
          </sim-button>
          <sim-button class="mr-2" @click="set_biography">
            <i class="material-icons"></i>
            {{ l.app.Confirm }}
          </sim-button>
        </div>
      </template>
    </sim-Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import ConfirmModal from '../../components/modal/confimModal'
//import { VueEditor } from "vue2-editor";

export default {
  data: () => ({
    picture: null,
    isUpdating: false,
    biography: "",
    form: {
      message: "",
      target_type: "",
      target_ids: [],
    },
    selfEmployer: false,
    messageTypes: [],
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    modal: false
  }),
  computed: {
    ...mapGetters({
      hasError: "hasError",
      error: "error",
      l: "lang",
    }),
    ...mapState({
      loading: (state) => state.users.loadings.show,
      user: (state) => state.users.user,
    }),
  },
  components: {
    //VueEditor,
    // ConfirmModal
  },
  methods: {
    ...mapActions({
      removeError: "removeError",
      fetchUser: "users/show",
      createProfile: "users/createProfile",
      update: "users/update",
      updateProfilePicture: "users/updateProfilePicture",
      companiesList: "companies/list",
    }),
    handlePhotoSelection(event) {
      const reader = new FileReader();
      reader.onload = () => {
        this.user.profile_picture = event.target.files[0];
        this.picture = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    dropPhoto() {
      this.user.profile_picture = null;
      this.picture = null;
      document.querySelector("#file_input").value = null;
    },
    handlePhotoUpdate() {
      if (this.picture) {
        this.updateProfilePicture(this.user.profile_picture);
      }
    },
    set_biography() {
      if (!this.selfEmployer && (!this.user.profile?.employer || this.user.profile?.employer == "")) {
        this.$message.error(this.l.messages.errors.select_employer)
        return false;
      }

      if (!this.user.profile.id) {
        this.createProfile({
          user_id: this.$route.params.id,
          name: this.user.profile.name,
          employer: this.selfEmployer ? "Self-employed" : this.user.profile.employer,
          last_name: this.user.profile.last_name,
          job_title: this.user.profile.job_title,
          is_featured: this.user.profile.is_featured,
          biography: this.user.profile.biography,
          email: this.user.profile.email,
        }).then(() => {
          return this.$router.push("/users");
        });
      } else {
        this.update({
          id: this.user.profile.id,
          name: this.user.profile.name,
          employer: this.selfEmployer ? "Self-employed" : this.user.profile.employer,
          last_name: this.user.profile.last_name,
          job_title: this.user.profile.job_title,
          is_featured: this.user.profile.is_featured,
          biography: this.user.profile.biography,
          email: this.user.profile.email,
        }).then(() => {
          this.$repository.users.updateDataUser(this.$route.params.id, { email: this.user.email }).then(() => {
            return this.$router.push("/users");
          })
        });
      }
    },
    addImg(img /*, picture*/) {
      console.log("addImg", img);
      const data = new FormData();
      data.append("image", img);
      this.updateProfilePicture({
        id: this.user.profile.id,
        img: data,
      });
    },
    addImgPV(img /*, picture*/) {
      this.picture = img;
    },
    remove_featured() {
      //this.update({ id: this.user.profile.id,is_featured: false})
    },
    setEmployer(e) {
      let value = this.messageTypes.find((employer) => employer.value === e).label;
      this.user.profile.employer = value;
    },
    async getCompaniesList(_search) {
      const query = this.$queryUtils.withoutInvalidValues({ search: _search, limit: 0 });
      this.query = query;
      let res = await this.companiesList(query);

      let compalist = [];
      if (res) {
        res.forEach((element) => {
          compalist.push({
            value: element.id,
            label: element.name,
          });
        });
      }
      this.messageTypes = compalist;
    },
  },
  created() {
    let initForm = {
      email: "",
      work_email: "",
      name: "",
      last_name: "",
      address: "",
      job_title: "",
      employer: "",
    };
    // if (!this.user.profile.id) {
    const { id } = this.$route.params;

    this.fetchUser(id).then(() => {
      // this.user = { ...user };
      // if (this.user.profile === null) {
      // }
      this.picture = this.user.profile ? this.user.profile.avatar_url : ''
    }).finally(() => {
      if (!this.user.profile) {
        this.user.profile = initForm
      }
    })
    // } else {
    //   this.user.profile = initForm;
    // }
  },
};
</script>
<style scoped>
.a-modal-wrapper.a-modal-body {
  min-height: 100px !important;
}
.a-modal {
  min-height: 100px !important;
}
</style>