<template>
    <div :class="[`${prefix}-layout`]">
        <slot />
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `layout`,
    props: {},
    data: () => ({
        prefix
    })
}
</script>