<template>
	<div class="switch-button-control">
		<div class="switch-button" :class="{ enabled: value }" @click="toggle" :style="{'--color': color}">
			<div class="button"></div>
		</div>
		<div class="switch-button-label">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'switch',
	props: {
		value: { required: false, type: Boolean, default: false },
		color: { type: String, required: false, default: "#006CFF" }
	},
	methods: {
		toggle() {
			this.$emit("input", !this.value);
		}
	}
}
</script>

<style scoped lang="scss">
$switch-button-height: 1.6em;
$switch-button-color: var(--color);
$switch-button-border-thickness: 2px;
$switch-transition: all 0.3s ease-in-out;
$switch-is-rounded: true;
$off-color: #BCBCCB;
.switch-button-control {
	display: flex;
	flex-direction: row;
	align-items: center;

	.switch-button {

		height: $switch-button-height;
		width: calc(#{$switch-button-height} * 2);
		border: $switch-button-border-thickness solid $off-color;
		box-shadow: inset 0px 0px $switch-button-border-thickness 0px
		rgba(0, 0, 0, 0.33);
		border-radius: if($switch-is-rounded, $switch-button-height, 0);

		transition: $switch-transition;

		$button-side-length: calc(
			#{$switch-button-height} - (2 * #{$switch-button-border-thickness})
		);

		cursor: pointer;

		.button {
			height: $button-side-length;
			width: $button-side-length;
			border: $switch-button-border-thickness solid white;
			border-radius: if($switch-is-rounded, $button-side-length, 0);
			background: $off-color;
			margin-left: 1px;
			transition: $switch-transition;
		}

		&.enabled {
			background-color: $switch-button-color;
			box-shadow: none;
			border: $switch-button-border-thickness solid $switch-button-color;

			.button {
				background: white;
				border: $switch-button-border-thickness solid $switch-button-color;
				transform: translateX(
					calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
				);
			}
		}
	}

	.switch-button-label {
		margin-left: 10px;
	}
}
</style>