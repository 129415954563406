<template>
	<div>
		<div class="flex justify-end mb-4">
			<sim-button
				align="right"
				@click="opentMolda()"
				:disabled="loading"
				:loading="loading"
			>
				<i class="material-icons"> add</i>
				{{l.app.Create}}
			</sim-button>
		</div>
		<!-- aqui va la tabla -->
		<sim-table
			:loading="loading"
			:columns="columns"
			:toolbar-per-page="$route.query.limit"
			@toolbar:search="searchData({search: $event, page: 1})"
			@toolbar:per-page="searchData({limit: $event})"
			@sort-change="searchData({order_by: `${$event.key},${$event.direction}`})"
			:source="pagination.data"
		>
			<template #td:answer="{ record }">
				<div v-html="record.item.answer"></div>
			</template>
			<template #td:actions="{ record }">
				<div class="flex space-x-2">
					<label
						:title="l.app.Edit"
						@click="editData(record.item)"
						class="btn btn-label-warning text-uppercase"
					>
						<i class="material-icons">edit</i>
					</label>

					<label
						:title="l.app.Remove"
						@click="deleteData({id: record.item.id, back: false})"
						class="btn btn-label-danger text-uppercase"
					>
						<i class="material-icons"> delete</i>
					</label>
				</div>
			</template>
		</sim-table>

		<sim-pagination
			align="right"
			:data="pagination"
			@change="searchData({page: $event})"
		/>

		<sim-Modal :show="modal" @close="modal = false">
			<h3 slot="header">
				<b
					class="col-12 title-detail-transaction"
					style="font-size: 28px; ">
					{{formData.question === "" ? l.app.Create : l.app.Edit }} FAQ
				</b>
			</h3>

			<div class="row">
				<div
					class="form-group col-12"
					v-for="(input, key_input) in form"
					:key="key_input"
				>
					<label :for="input.atribute">{{ input.label }}</label>
					<sim-input
						v-if="input.type !== 'select' && input.type !== 'textarea'"
						:id="input.atribute"
						:type="input.type"
						:error="error(input.atribute, {attribute: input.atribute})"
						v-model="formData[input.atribute]"
					/>
					<template v-else-if="input.type === 'textarea'">
						<vue-editor @input="clearError('message')" :id="input.atribute" v-model="formData[input.atribute]"></vue-editor>
					</template>
					
					<template v-else>
						<v-select
							v-model="selectData[input.atribute]"
							:options="source[input.source]"
						></v-select>
						<div class="feedback-zone">
							<span>
								<p
									style="color: #ff1100;"
									:class="[`sim-input-error-message`]"
									v-if="error(input.atribute, {attribute: input.atribute})"
									>{{ error(input.atribute, {attribute: input.atribute}) }}</p
								>
							</span>
						</div>
					</template>
				</div>
			</div>
			<template slot="footer">
				<sim-button class="mr-2" @click="save()" :loading="loading">
					<i class="material-icons">save</i>
					{{ l.app.Save_Changes }}
				</sim-button>
				<sim-button
					outlined
					@click="
						modal = false;
						updatePoint = 5;
						garments = null;
					"
				>
					<i class="material-icons">clear</i>
					<p class="pink-cancel-button">{{ l.app.Cancel }}</p>
				</sim-button>
			</template>
		</sim-Modal>
	</div>
</template>

<script>
	import {mapActions, mapState, mapGetters} from "vuex";
	import { VueEditor } from "vue2-editor";

	export default {
		data: () => ({
			modal: false,
			limit: 10,
			search: "",
			query: {
				limit: 10,
				page: 1,
				search: "",
				order_by: "position,asc",
			},
			form: [],
			formData: {
				position: "",
				answer: "",
				question: "",
			},
		}),
		computed: {
			...mapState({
				pagination: (state) => state.faq.pagination,
				loading: (state) => state.faq.loadings.index,
				session: (state) => state.session.user,
			}),
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
			columns() {
				return [
					{title: this.l.app.position, key: "position", sortable: true},
					{title: this.l.app.question, key: "question"},
					{
						title: this.l.app.Answer,
						key: "answer",
						slot: "answer",
						titledContent: true,
					},
					{title: this.l.app.Actions, slot: "actions", alignment: "right"},
				];
			},
		},
		methods: {
			...mapActions({
				fetchFaqs: "faq/fetchFaqs",
				store: "faq/store",
				update: "faq/update",
				delete: "faq/delete",
				deleteData: "faq/delete",
			}),
			searchData(_query = {}) {
				const query = this.$queryUtils.withoutInvalidValues({
					...this.query,
					..._query,
				});
				this.fetchFaqs(query).then(() => {
					this.$router.replace({query}).catch(() => null);
				});
			},
			orderBy(data) {
				if (this.search !== "") {
					this.fetchFaqs({
						query: this.search,
						sort_by: `${data.field},${data.order}`,
						page: 1,
					});
				} else {
					this.fetchFaqs({order_by: `${data.field},${data.order}`, page: 1});
				}
			},
			save() {
				if ( this.formData.position != "" && this.formData.answer != "" && this.formData.question != "" ) {
					if (!this.formData.hasOwnProperty("id")) {
						this.store(this.formData).then(() => {
							this.fetchFaqs(this.query);
							this.modal = false
						});
					} else {
						this.update(this.formData).then(() => {
							this.fetchFaqs(this.query);
							this.modal = false
						});
					}
					
				}else{
					this.$message.error({
						title: this.l.app.title,
						message: this.l.messages.errors.fields_required ?? "mensaje de error",
						position: "top-right",
						showClose: true,
					})
				}
			},
			editData(data) {
				this.modal = true;
				this.formData = data;
			},
			opentMolda() {
				this.modal = true;
				this.formData = {
					position: "",
					answer: "",
					question: "",
				};
			},
		},
		components: {
			VueEditor
		},
		mounted() {
			this.searchData();
		},
		created() {
			this.query = {...this.query, ...this.$route.query, query: ""};
			this.form = [
				{
					label: this.l.app.position,
					atribute: "position",
					type: "text",
					visibility: true,
				},
				{
					label: this.l.app.question,
					atribute: "question",
					type: "text",
					visibility: true,
				},
				{
					label: this.l.app.Answer,
					atribute: "answer",
					type: "textarea",
					visibility: true,
				},
			]
		},
	};
</script>
