import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import configuration from './config'
import axios from 'axios'
import Embed from 'v-video-embed'
import { Message } from '@/components/dialogs';
import { Repository } from './api'
import Debounce from './directives/debounce'
import '@/assets/scss/index.scss'
import '@/assets/tailwind.css'

/**
 * This Vue.use is beingh initialized here in order to have
 * the messages plugin enabled with all the configuration
 * files
 */
Vue.use(Message)
Vue.use(Debounce)
Vue.use(Repository, { store })
Vue.use(Embed)
/**
 * complete app configuration.
 * This will setup the router view render and access,
 * axios requests handlers, plugins instalations
 * and will give access to the store, languages and every
 * needed instance to work
 */

configuration({
    vue: Vue,
    axios,
    router,
    store,
    lang: store.getters['lang'],
    notification: Vue.prototype.$message
})

new Vue({
 router,
 store,
 render: h => h(App)
}).$mount('#app')

/**
 * while true, the devtools inspector plugin
 * will be enabled, set false in environment variables
 * to disable it
 */
let isConsoleEnabled = process.env.VUE_APP_ENABLE_DEV_CONSOLE === 'true';

Vue.config.productionTip = false
Vue.config.devtools = isConsoleEnabled;
Vue.config.debug = isConsoleEnabled;
Vue.config.silent = !isConsoleEnabled;