var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"flex justify-end mb-4 col-12"}),_c('sim-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"toolbar-per-page":_vm.$route.query.limit,"source":_vm.pagination.data},on:{"toolbar:per-page":function($event){return _vm.searchData({ limit: $event })},"sort-change":function($event){return _vm.searchData({ order_by: (($event.key) + "," + ($event.direction)) })}},scopedSlots:_vm._u([{key:"filter:sector",fn:function(){return [_c('v-select',{attrs:{"label":"name","loading":_vm.loadings.sectors,"reduce":function (sector) { return sector.id; },"options":_vm.sectors.data,"filterable":false},on:{"input":function($event){return _vm.searchData({ sector_id: $event })},"search":function($event){return _vm.searchSectors({ query: $event, page: 1, limit: 10 })}},scopedSlots:_vm._u([(_vm.sectors.total_pages > 1)?{key:"list-footer",fn:function(){return [_c('li',{staticClass:"pagination"},[_c('p',{staticClass:"text-center my-2"},[_vm._v(" Página "+_vm._s(_vm.sectors.page)+" de "+_vm._s(_vm.sectors.total_pages)+" ")]),_c('div',{staticClass:"flex space-x-2 px-2 pt-2"},[(_vm.sectors.page !== 1)?_c('button',{staticClass:"bg-gray-200 flex-grow rounded-md py-2",on:{"click":function($event){return _vm.searchSectors({ page: _vm.sectors.page - 1, limit: 10 })}}},[_vm._v(" Anterior ")]):_vm._e(),(_vm.sectors.page < _vm.sectors.total_pages)?_c('button',{staticClass:"bg-gray-200 flex-grow rounded-md py-2",on:{"click":function($event){return _vm.searchSectors({ $event: $event, page: _vm.sectors.page + 1, limit: 10 })}}},[_vm._v(" Siguiente ")]):_vm._e()])])]},proxy:true}:null],null,true)})]},proxy:true},{key:"td:username",fn:function(ref){
var record = ref.record;
return [_vm._v(" "+_vm._s(record.item.author.profile.name + ' ' + record.item.author.profile.last_name)+" ")]}},{key:"td:changes",fn:function(ref){
var record = ref.record;
return [(record.item.value)?_c('div',_vm._l((record.item.value),function(value,key){return _c('div',{key:key,staticClass:"w-100 pb-2"},[_c('span',{staticClass:"w-100 text-bold"},[_vm._v(_vm._s(key.replaceAll('id','').replaceAll('_',' ') + ': '))]),(key === 'socials')?_c('div',_vm._l((value),function(val,k){return _c('div',{key:k},[_c('span',{staticClass:"mx-2 inline-block"},[_vm._v(_vm._s(k.replaceAll('id','').replaceAll('_',' ') + ': '))]),_c('p',{staticClass:"inline-block",domProps:{"innerHTML":_vm._s(val)}})])}),0):_c('p',{domProps:{"innerHTML":_vm._s(value)}})])}),0):_vm._e()]}},{key:"td:state",fn:function(ref){
var record = ref.record;
return [_vm._v(" "+_vm._s(record.item.status)+" ")]}},{key:"td:actions",fn:function(ref){
var record = ref.record;
return [_c('div',{staticClass:"flex space-x-2"},[(record && record.item && record.item.status)?void 0:_vm._e(),_c('label',{staticClass:"btn btn-label-primary text-uppercase",attrs:{"title":'Approve'},on:{"click":function($event){return _vm.accept(record.item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("check")])]),_c('label',{staticClass:"btn btn-label-danger text-uppercase",attrs:{"title":'Refuse'},on:{"click":function($event){return _vm.refuse(record.item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])],2)]}}])}),_c('sim-pagination',{attrs:{"align":"right","data":_vm.pagination},on:{"change":function($event){return _vm.searchData({ page: $event })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }