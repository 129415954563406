<template>
	<div :class="['w-full']">
		<div
			:class="[
				'align-middle inline-block min-w-full shadow sm:rounded-xl border-b border-gray-200 bg-white',
				{'overflow-hidden rounded-2xl': rounded},
			]"
		>
			<!-- START TOOLBAR -->
			<div
				class="p-2 flex w-full flex-wrap justify-between items-center"
				v-if="shouldShowToolbar"
			>
				<span>
					<slot
						name="toolbar:search"
						v-if="$slots['toolbar:search'] || $listeners['toolbar:search']"
					>
						<input
							type="text"
							:placeholder="searchPlaceholder"
							class="p-2 font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
							@input="$emit('toolbar:search-change', $event.target.value)"
							v-debounce:400="handleDebounceSearch"
						/>
					</slot>
				</span>
				<span class="flex space-x-2">
					<slot
						name="toolbar:per-page"
						v-if="$slots['toolbar:per-page'] || $listeners['toolbar:per-page']"
					>
						<select
							class="p-2 px-4 appearance-none font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
							:disabled="loading"
							:value="toolbarPerPage"
							@input="$emit('toolbar:per-page', $event.target.value)"
						>
							<option
								:selected="getSelectedToolbarPerPage(n)"
								:value="parsePerPageKeyValue(n).value"
								v-for="(n, k) in toolbarPerPageOptions"
								:key="k"
							>
								{{ parsePerPageKeyValue(n).label }}
							</option>
						</select>
					</slot>

					<slot name="toolbar:column-filter" v-if="toolbarColumnFilter">
						<sim-dropdown>
							<button
								class="p-2 px-4 font-medium border outline-none transition-colors rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
								@click="$emit('toolbar:column-filter')"
							>
								<fa-icon icon="eye" class="text-gray-700 dark:text-gray-200" />
							</button>

							<template #menu>
								<template v-for="(column, key) in sourceColumns">
									<sim-dropdown-item
										:key="key"
										@click="toggleColumnsVisibility(column.key)"
										v-if="
											column.columnFilterable === undefined ||
												column.columnFilterable
										"
									>
										<div
											class="flex space-x-2 justify-between text-left w-full"
										>
											{{ column.title }}
											<fa-icon
												:icon="column.visible ? 'eye' : 'eye-slash'"
												:class="{
													'text-gray-300': !column.visible,
												}"
											/>
										</div>
									</sim-dropdown-item>
								</template>
							</template>
						</sim-dropdown>
					</slot>

					<slot
						name="toolbar:refresh"
						v-if="$slots['toolbar:refresh'] || $listeners['toolbar:refresh']"
					>
						<button
							:disabled="loading"
							class="p-2 px-4 font-medium border outline-none transition-colors rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
							@click="$emit('toolbar:refresh')"
						>
							<fa-icon icon="redo-alt" />
						</button>
					</slot>

					<slot name="toolbar" :actions="toolbarAndFilterSlotActions" />
				</span>
			</div>
			<!-- END TOOLBAR -->

			<!-- START TABLE -->
			<div>
				<table class="w-full">
					<!-- TABLE HEADERS -->
					<thead>
						<tr>
							<th
								v-if="expandable"
								class="px-6 py-3 border-b border-gray-200 bg-gray-200 bg-opacity-60 text-left text-sm leading-4 font-medium uppercase tracking-wider"
							></th>
							<th
								v-for="(column, key) in columnsToShow"
								:key="key"
								class="px-6 py-3 border-b border-gray-200 bg-gray-200 bg-opacity-60 text-left text-sm leading-4 font-medium uppercase tracking-wider"
							>
								<span class="flex justify-between items-center font-medium">
									<slot :name="`th:${column.slot || ''}`" :it="column">
										{{ column.title }}
									</slot>

									<!-- hover:bg-gray-700 hover:bg-opacity-25 transition ease-in-out duration-150 -->
									<button
										:title="'order for ' + column.title"
										:disabled="loading"
										@click="handleSortChange({column})"
										v-if="column.sortable"
										class="focus:outline-none rounded-full p-1 px-3 flex justify-center flex-col items-center"
									>
										<fa-icon
											icon="chevron-up"
											class="text-xs text-gray-600"
											v-if="shouldShowSortArrow(column, 'asc')"
										/>
										<fa-icon
											icon="chevron-down"
											class="text-xs text-gray-600"
											v-if="shouldShowSortArrow(column, 'desc')"
										/>
									</button>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<!-- TABLE FILTERS -->
						<tr v-if="hasAtLeastOneFilter">
							<td
								v-for="(column, n) in columnsToShow"
								:key="n"
								class="text-left text-sm leading-4 font-medium tracking-wider p-2 border-b"
							>
								<slot
									v-if="column.filter"
									:name="`filter:${column.keyFilter || column.key}`"
									:actions="toolbarAndFilterSlotActions"
								>
									<input
										:placeholder="`Search ${column.title.toLowerCase()}`"
										type="text"
										:value="
											localFilters[getFilterKeyValueIndex(column.key)].value
										"
										v-debounce:400="
											(e) => $emit(`delayed-filter:${column.key}`, e)
										"
										@input="
											updateFilterKeyValue(
												column.key,
												$event.target.value,
												false,
												column.preventLocalFilter || false
											)
										"
										class="border p-3 border-gray-200 font-medium rounded-md w-full outline-none focus:outline-none focus:ring-2 focus:ring-primary"
									/>
								</slot>
							</td>
						</tr>

						<!-- TABLE LOADING STATE -->
						<template v-if="loading">
							<tr>
								<td :colspan="columnsToShow.length + expandable" class="bg-white p-2 dark:bg-gray-900">
									<p class="text-2xl text-center pt-16 p-8 animate-bounce">{{l.app.loading}}</p>
									<!-- <a-skeleton class="my-1 h-10 w-full rounded-xl" v-for="n in skeletonCount" :key="n" :style="{ height: skeletonHeight }" /> -->
								</td>
							</tr>
						</template>

						<!-- TABLE RENDER CELLS -->
						<template v-else>
							<template v-if="dataSource.length">
								<template v-for="(src, i) in dataSource">
									<tr :key="i">
										<td
											v-if="expandable"
											:class="[
												'px-6 bg-white py-4 border-b border-gray-200 mx-auto text-sm',
												{
													'border-none': expanded.includes(i),
													'border-t': expanded.includes(i - 1),
												},
											]"
										>
											<slot
												name="tr:expand-trigger"
												v-if="canExpand({item: src, index: i})"
												:record="{
													item: src,
													index: i,
													toggleExpand: () => toggleExpand(i),
													expanded: expanded.includes(i),
												}"
											>
												<button
													class="focus:outline-none"
													@click="toggleExpand(i)"
												>
													<fa-icon
														icon="chevron-down"
														:class="[
															'transform transition-transform',
															{
																'rotate-180': expanded.includes(i),
															},
														]"
													/>
												</button>
											</slot>
										</td>
										<td
											v-for="(column, j) in columnsToShow"
											:key="j"
											:width="column.width"
											:class="[
												'px-6 bg-white py-4 border-b border-gray-200 mx-auto text-sm',
												{
													'border-none': expanded.includes(i),
													'border-t': expanded.includes(i - 1),
												},
											]"
										>
											<slot
												:name="`td:${column.slot || ''}`"
												:record="{
													item: src,
													index: i,
													key: render(src, column),
													column,
													columnIndex: j,
												}"
											>
												{{ render(src, column) }}
											</slot>
										</td>
									</tr>
									<tr v-if="expanded.includes(i)" :key="`i_${i}`">
										<td :colspan="columns.length + 1" class="p-2">
											<slot
												:name="`tr:expanded`"
												:record="{item: src, index: i}"
											/>
										</td>
									</tr>
								</template>
							</template>

							<!-- TABLE EMPTY SLOT -->
							<template v-else>
								<tr>
									<!-- <td v-if="expandable" :class="$style.tableRenderedNoResults"></td> -->
									<td
										:colspan="columnsToShow.length + expandable"
										style="text-align: center"
										class="px-6 py-4 bg-white border-b border-gray-200 text-center mx-auto text-sm"
									>
										<slot name="empty">
											<div class="flex justify-center items-center flex-col">
												<!-- <div class="w-full md:w-2/6 lg:w-1/6">
                                                    <a-no-data />
                                                </div> -->
												<h3 class="text-2xl dark:text-gray-300 p-8">
													{{this.l.app.no_results_found}}
												</h3>
											</div>
										</slot>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
					<tfoot>
						<tr>
							<td :colspan="columnsToShow.length" v-if="$slots['full-footer']">
								<slot name="full-footer" />
							</td>
							<template v-if="$slots['last-cell-footer']">
								<td
									v-for="n in columnsToShow.length"
									:key="n"
									:class="[
										n === columnsToShow.length &&
											'px-6 py-4 bg-white border-b border-gray-200 mx-auto text-sm',
									]"
								>
									<slot
										name="last-cell-footer"
										v-if="n === columnsToShow.length"
									/>
								</td>
							</template>
						</tr>
					</tfoot>
				</table>
			</div>
			<slot name="hint">
				<small class="block text-center p-4 text-gray-500 w-full" v-if="hint">{{
					hint
				}}</small>
			</slot>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: "table",
		props: {
			columns: {required: false, type: Array, default: () => []},
			source: {required: false, type: Array, default: () => []},
			bordered: {required: false, type: Boolean, default: false},
			overflow: {required: false, type: Boolean, default: false},
			loading: {required: false, type: Boolean, default: false},
			skeletonCount: {required: false, type: Number, default: 10},
			skeletonHeight: {required: false, type: String, default: `30px`},
			rounded: {required: false, type: Boolean, default: false},

			// toolbar options
			toolbarColumnFilter: {required: false, type: Boolean, default: false},
			searchPlaceholder: {required: false, type: String, default: "Buscar..."},
			toolbarPerPage: {required: false, type: [String, Number], default: 10},
			toolbarPerPageOptions: {
				required: false,
				type: Array,
				default: () => Array.from({length: 4}, (_, idx) => (idx + 1) * 5),
			},
			toolbarSearch: {required: false, type: [String, Number], default: ""},

			hint: {required: false, type: String, default: null},
			expandable: {required: false, type: Boolean, default: false},
			canExpand: {required: false, type: Function, default: () => true},
		},
		data: () => ({
			sort: {
				direction: "asc",
				key: "",
			},
			query: {
				search: "",
			},
			localFilters: [],
			hiddenColumns: [],
			expanded: [],
		}),
		computed: {
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
			columnsToShow() {
				return this.sourceColumns.filter(($0) => $0.visible);
			},
			shouldShowToolbar() {
				const slots = [
					this.$slots["toolbar:search"] ||
						this.$slots["toolbar:per-page"] ||
						this.$slots["toolbar:reload"],
				];

				const listeners = [
					this.$listeners["toolbar:search"] ||
						this.$listeners["toolbar:search-change"] ||
						this.$listeners["toolbar:per-page"] ||
						this.$listeners["toolbar:refresh"],
				];

				return [...slots, ...listeners, this.toolbarColumnFilter].some(
					($0) => $0
				);
			},
			hasAtLeastOneFilter() {
				return this.columnsToShow.some(($0) => $0.filter ?? false);
			},
			dataSource() {
				if (!Object.keys(this.localFilters).length) {
					return this.source;
				}

				return this.source.filter(($0) => {
					return this.localFilters.every(($1) => {
						if ($1.prevent) {
							return true;
						}

						if (!$1.value) {
							return true;
						}

						if ($1.exact) {
							return (
								($0[$1.key] ?? "").toString().toLowerCase() ===
								$1.value.toString().toLowerCase()
							);
						}

						return ($0[$1.key] ?? "")
							.toString()
							.toLowerCase()
							.includes($1.value?.toString().toLowerCase());
					});
				});
			},
			sourceColumns() {
				return this.columns.map(($0) => ({
					...$0,
					visible: !this.hiddenColumns.includes($0.key),
				}));
			},
			toolbarAndFilterSlotActions() {
				return {
					updateFilter: this.updateFilterKeyValue,
					clearFilters: this.clearLocalFilters,
				};
			},
		},
		methods: {
      getSelectedToolbarPerPage (n) {
        return parseInt(this.toolbarPerPage) === this.parsePerPageKeyValue(n).value
      },
			toggleColumnsVisibility(key) {
				const index = this.hiddenColumns.findIndex(($0) => $0 === key);

				if (index >= 0) {
					this.hiddenColumns.splice(index, 1);
				} else {
					this.hiddenColumns.push(key);
				}
			},
			parsePerPageKeyValue(item) {
				return {
					label: item?.label ?? item,
					value: item?.value ?? item,
				};
			},
			toggleExpand(index) {
				const foundIndex = this.expanded.findIndex(($0) => $0 === index);
				if (foundIndex >= 0) {
					this.expanded.splice(foundIndex, 1);
				} else {
					this.expanded.push(index);
				}
			},
			clearLocalFilters() {
				this.localFilters = this.localFilters.map(($0) => ({...$0, value: ""}));
			},
			getFilterKeyValueIndex(key) {
				return this.localFilters.findIndex(($0) => $0.key === key);
			},
			updateFilterKeyValue(key, value, exact = false, prevent = false) {
				const index = this.getFilterKeyValueIndex(key);

				if (index >= 0) {
					this.localFilters[index].value = value;
					this.localFilters[index].exact = exact;
					this.localFilters[index].prevent = prevent;

					this.$emit(`filter:${key}`, value);
				}
			},
			handleDebounceSearch(search) {
				this.$emit("toolbar:search", search);
			},
			render(src, column) {
				if (typeof src === "object") {
					let temp = {...src};
					column.key
						?.split(".")
						.forEach((section) => (temp = temp[section] ?? ""));

					const context = {key: temp, item: src, column};

					if (column.mutate) {
						return column.mutate(context, {
							truncate: this.$options.filters.truncate,
							date: this.$options.filters.date,
							bytes: this.$options.filters.byteReadable,
							capitalize: this.$options.filters.capitalize,
							timePadding: this.$options.filters.timePadding,
						});
					}

					Object.entries(column).forEach(([attribute, value]) => {
						if (typeof value === "function") {
							column[attribute](context);
						}
					});

					return temp;
				}

				return "";
			},
			handleSortChange({column}) {
				if (!this.loading) {
					const key = column.sortKey ?? column.key;

					this.sort = {
						direction:
							key === this.sort.key
								? this.sort.direction === "asc"
									? "desc"
									: "asc"
								: this.sort.direction,
						key,
					};

					this.$emit("sort-change", this.sort);
				}
			},
			shouldShowSortArrow(column, direction) {
				const key = column.sortKey ?? column.key;
				if (this.sort.key !== key) {
					return true;
				}

				return this.sort.direction !== direction;
			},
		},
		created() {
			this.hiddenColumns = this.columns
				.filter(($0) => !($0?.visible ?? true))
				.map(($0) => $0.key);
			this.localFilters = this.columns
				.filter(($0) => $0.filter ?? false)
				.map(($0) => ({key: $0.key, value: "", exact: false}));
		},
	};
</script>
