<template>
<div class="card-spacer mt-n25 w-full" style="margin-top: 30px;">
  <div class="flex text-center">
    <div class="flex-auto border rounded-lg p-4 m-2 cursor-pointer bg-white" @click="userType = 'registeredUsers'">
      <p class="font-bold border-b pb-2 text-lg">{{ l.app.dashboard.registered_users }}</p>
      <p class="ml-2 text-center pt-2 text-2xl">{{ getUserLifetime }}</p>
    </div>
    <div class="flex-auto border rounded-md p-4 m-2 bg-white">
      <p class="font-bold border-b pb-2 text-lg">{{ l.app.dashboard.registered_users_in_month }}</p>
      <p class="ml-2 text-center pt-2 text-2xl">{{ getUserLogin }}</p>
    </div>
    <div class="flex-auto border rounded-md p-4 m-2 cursor-pointer bg-white" @click="userType = 'paying'">
      <p class="font-bold border-b pb-2 text-lg">{{ l.app.dashboard.paying_users }}</p>
      <p class="ml-2 text-center pt-2 text-2xl">{{ getPayingUsers }}</p>
    </div>
    <div class="flex-auto border rounded-md p-4 m-2 cursor-pointer bg-white" @click="userType = 'nonpaying'">
      <p class="font-bold border-b pb-2 text-lg">{{ l.app.dashboard.non_paying_users }}</p>
      <p class="ml-2 text-center pt-2 text-2xl">{{ getNonPayingUsers }}</p>
    </div>
  </div>
  <div>
    <users-table :title="userType" :date-begin="getDateBeg"/>
  </div>
    <!--begin::Row-->
<!--    <div v-if="dashboardsData !== null" class="row m-0">-->

<!--        <div class="box row col bg-white px-6 py-8 rounded-xl mr-7 mb-7">-->
<!--            <div class="col">-->
<!--                <pie :chart-data="dataGarments" :options="options"/>-->
<!--            </div>-->
<!--            <div class="col row">-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        <svg  x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" >-->
<!--                            <path d="M22.7,16.7c-1-1.7-1.8-2.8-0.1-6.1c0.1-0.1,0.1-0.1,0.4-5.7l3.1-1c1.4,1.3,7,6.1,13.3,7c1.5,3.1,0.7,4.2-0.3,5.8-->
<!--                                c-0.2,0.4-0.5,0.8-0.8,1.3c-0.5,0.9-0.8,1.8-1,2.7c-5,1.5-11.2,0.3-12.9,0c-0.2-0.9-0.5-1.8-1-2.6C23.2,17.5,23,17.1,22.7,16.7-->
<!--                                L22.7,16.7z"/>-->
<!--                            <path d="M47.1,58.1c-1,0-1.9,0.4-2.6,1c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2-->
<!--                                c-0.1,0.1-0.2,0.2-0.3,0.2c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.2-->
<!--                                c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.6-1.6-1-2.6-1c-0.1,0-0.1,0-0.1-0.1c0-0.2,1.5-19.6,7.5-31.5-->
<!--                                c0.3-0.6,0.7-1.7,1.2-2.7c0.3-0.8,0.7-1.6,0.9-2.1c0.4,0.1,1.1,0.2,1.9,0.4c-0.9,2.5-1.6,5.1-2.1,7.7l1.4,0.2-->
<!--                                c0.5-2.6,1.2-5.2,2.1-7.7c0.6,0.1,1.3,0.1,2,0.2c-0.4,2.9-2.1,14.7-2.2,21.2h1.4c0.1-6.5,1.8-18.5,2.2-21.2h0.7c0.8,0,1.6,0,2.5-0.1-->
<!--                                c0.5,1.3,2.3,6.2,2.3,9.5h1.4c0-3.4-1.7-8.1-2.3-9.7c0.7-0.1,1.5-0.3,2.2-0.5c0.3,0.7,0.8,2,1.3,3c0.4,1,0.8,1.9,1,2.4-->
<!--                                c6.2,13.7,7.2,30.3,7.2,30.9C47.2,58.1,47.1,58.2,47.1,58.1C47.1,58.2,47.1,58.2,47.1,58.1L47.1,58.1z"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.garments.total}}-->
<!--                    </div>-->
<!--                    <router-link :to="{ name: 'customers' }" style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.Clothing_Catalog}}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        <svg  x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" >-->
<!--                            <path d="M22.7,16.7c-1-1.7-1.8-2.8-0.1-6.1c0.1-0.1,0.1-0.1,0.4-5.7l3.1-1c1.4,1.3,7,6.1,13.3,7c1.5,3.1,0.7,4.2-0.3,5.8-->
<!--                                c-0.2,0.4-0.5,0.8-0.8,1.3c-0.5,0.9-0.8,1.8-1,2.7c-5,1.5-11.2,0.3-12.9,0c-0.2-0.9-0.5-1.8-1-2.6C23.2,17.5,23,17.1,22.7,16.7-->
<!--                                L22.7,16.7z"/>-->
<!--                            <path d="M47.1,58.1c-1,0-1.9,0.4-2.6,1c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2-->
<!--                                c-0.1,0.1-0.2,0.2-0.3,0.2c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.2-->
<!--                                c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.6-1.6-1-2.6-1c-0.1,0-0.1,0-0.1-0.1c0-0.2,1.5-19.6,7.5-31.5-->
<!--                                c0.3-0.6,0.7-1.7,1.2-2.7c0.3-0.8,0.7-1.6,0.9-2.1c0.4,0.1,1.1,0.2,1.9,0.4c-0.9,2.5-1.6,5.1-2.1,7.7l1.4,0.2-->
<!--                                c0.5-2.6,1.2-5.2,2.1-7.7c0.6,0.1,1.3,0.1,2,0.2c-0.4,2.9-2.1,14.7-2.2,21.2h1.4c0.1-6.5,1.8-18.5,2.2-21.2h0.7c0.8,0,1.6,0,2.5-0.1-->
<!--                                c0.5,1.3,2.3,6.2,2.3,9.5h1.4c0-3.4-1.7-8.1-2.3-9.7c0.7-0.1,1.5-0.3,2.2-0.5c0.3,0.7,0.8,2,1.3,3c0.4,1,0.8,1.9,1,2.4-->
<!--                                c6.2,13.7,7.2,30.3,7.2,30.9C47.2,58.1,47.1,58.2,47.1,58.1C47.1,58.2,47.1,58.2,47.1,58.1L47.1,58.1z"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.garments.types.clothing}}-->
<!--                    </div>-->
<!--                    <div style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.CLOTHING}}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        <svg  x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" >-->
<!--                            <path d="M22.7,16.7c-1-1.7-1.8-2.8-0.1-6.1c0.1-0.1,0.1-0.1,0.4-5.7l3.1-1c1.4,1.3,7,6.1,13.3,7c1.5,3.1,0.7,4.2-0.3,5.8-->
<!--                                c-0.2,0.4-0.5,0.8-0.8,1.3c-0.5,0.9-0.8,1.8-1,2.7c-5,1.5-11.2,0.3-12.9,0c-0.2-0.9-0.5-1.8-1-2.6C23.2,17.5,23,17.1,22.7,16.7-->
<!--                                L22.7,16.7z"/>-->
<!--                            <path d="M47.1,58.1c-1,0-1.9,0.4-2.6,1c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2-->
<!--                                c-0.1,0.1-0.2,0.2-0.3,0.2c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.2-->
<!--                                c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.6-1.6-1-2.6-1c-0.1,0-0.1,0-0.1-0.1c0-0.2,1.5-19.6,7.5-31.5-->
<!--                                c0.3-0.6,0.7-1.7,1.2-2.7c0.3-0.8,0.7-1.6,0.9-2.1c0.4,0.1,1.1,0.2,1.9,0.4c-0.9,2.5-1.6,5.1-2.1,7.7l1.4,0.2-->
<!--                                c0.5-2.6,1.2-5.2,2.1-7.7c0.6,0.1,1.3,0.1,2,0.2c-0.4,2.9-2.1,14.7-2.2,21.2h1.4c0.1-6.5,1.8-18.5,2.2-21.2h0.7c0.8,0,1.6,0,2.5-0.1-->
<!--                                c0.5,1.3,2.3,6.2,2.3,9.5h1.4c0-3.4-1.7-8.1-2.3-9.7c0.7-0.1,1.5-0.3,2.2-0.5c0.3,0.7,0.8,2,1.3,3c0.4,1,0.8,1.9,1,2.4-->
<!--                                c6.2,13.7,7.2,30.3,7.2,30.9C47.2,58.1,47.1,58.2,47.1,58.1C47.1,58.2,47.1,58.2,47.1,58.1L47.1,58.1z"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.garments.types.gown}}-->
<!--                    </div>-->
<!--                    <div style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.GOWN}}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        <svg  x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" >-->
<!--                            <path d="M22.7,16.7c-1-1.7-1.8-2.8-0.1-6.1c0.1-0.1,0.1-0.1,0.4-5.7l3.1-1c1.4,1.3,7,6.1,13.3,7c1.5,3.1,0.7,4.2-0.3,5.8-->
<!--                                c-0.2,0.4-0.5,0.8-0.8,1.3c-0.5,0.9-0.8,1.8-1,2.7c-5,1.5-11.2,0.3-12.9,0c-0.2-0.9-0.5-1.8-1-2.6C23.2,17.5,23,17.1,22.7,16.7-->
<!--                                L22.7,16.7z"/>-->
<!--                            <path d="M47.1,58.1c-1,0-1.9,0.4-2.6,1c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2-->
<!--                                c-0.1,0.1-0.2,0.2-0.3,0.2c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.2-->
<!--                                c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.6-1.6-1-2.6-1c-0.1,0-0.1,0-0.1-0.1c0-0.2,1.5-19.6,7.5-31.5-->
<!--                                c0.3-0.6,0.7-1.7,1.2-2.7c0.3-0.8,0.7-1.6,0.9-2.1c0.4,0.1,1.1,0.2,1.9,0.4c-0.9,2.5-1.6,5.1-2.1,7.7l1.4,0.2-->
<!--                                c0.5-2.6,1.2-5.2,2.1-7.7c0.6,0.1,1.3,0.1,2,0.2c-0.4,2.9-2.1,14.7-2.2,21.2h1.4c0.1-6.5,1.8-18.5,2.2-21.2h0.7c0.8,0,1.6,0,2.5-0.1-->
<!--                                c0.5,1.3,2.3,6.2,2.3,9.5h1.4c0-3.4-1.7-8.1-2.3-9.7c0.7-0.1,1.5-0.3,2.2-0.5c0.3,0.7,0.8,2,1.3,3c0.4,1,0.8,1.9,1,2.4-->
<!--                                c6.2,13.7,7.2,30.3,7.2,30.9C47.2,58.1,47.1,58.2,47.1,58.1C47.1,58.2,47.1,58.2,47.1,58.1L47.1,58.1z"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.garments.types.haute_couture}}-->
<!--                    </div>-->
<!--                    <div style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.HAUTE_COUTURE}}-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        <svg  x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" >-->
<!--                            <path d="M22.7,16.7c-1-1.7-1.8-2.8-0.1-6.1c0.1-0.1,0.1-0.1,0.4-5.7l3.1-1c1.4,1.3,7,6.1,13.3,7c1.5,3.1,0.7,4.2-0.3,5.8-->
<!--                                c-0.2,0.4-0.5,0.8-0.8,1.3c-0.5,0.9-0.8,1.8-1,2.7c-5,1.5-11.2,0.3-12.9,0c-0.2-0.9-0.5-1.8-1-2.6C23.2,17.5,23,17.1,22.7,16.7-->
<!--                                L22.7,16.7z"/>-->
<!--                            <path d="M47.1,58.1c-1,0-1.9,0.4-2.6,1c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2-->
<!--                                c-0.1,0.1-0.2,0.2-0.3,0.2c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-1.2-3.5-1.3-4.9-0.2c-0.1,0.1-0.2,0.2-0.3,0.2-->
<!--                                c-1.4,1.3-3.7,1.4-5.3,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.6-1.6-1-2.6-1c-0.1,0-0.1,0-0.1-0.1c0-0.2,1.5-19.6,7.5-31.5-->
<!--                                c0.3-0.6,0.7-1.7,1.2-2.7c0.3-0.8,0.7-1.6,0.9-2.1c0.4,0.1,1.1,0.2,1.9,0.4c-0.9,2.5-1.6,5.1-2.1,7.7l1.4,0.2-->
<!--                                c0.5-2.6,1.2-5.2,2.1-7.7c0.6,0.1,1.3,0.1,2,0.2c-0.4,2.9-2.1,14.7-2.2,21.2h1.4c0.1-6.5,1.8-18.5,2.2-21.2h0.7c0.8,0,1.6,0,2.5-0.1-->
<!--                                c0.5,1.3,2.3,6.2,2.3,9.5h1.4c0-3.4-1.7-8.1-2.3-9.7c0.7-0.1,1.5-0.3,2.2-0.5c0.3,0.7,0.8,2,1.3,3c0.4,1,0.8,1.9,1,2.4-->
<!--                                c6.2,13.7,7.2,30.3,7.2,30.9C47.2,58.1,47.1,58.2,47.1,58.1C47.1,58.2,47.1,58.2,47.1,58.1L47.1,58.1z"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.garments.types.designer_bag}} -->
<!--                    </div>-->
<!--                    <div style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                       {{l.app.DESIGNER_BAG}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="box row col bg-white px-6 py-8 rounded-xl">-->
<!--            <div class="col">-->
<!--                <pie :chart-data="dataOrders" :options="options"/>-->
<!--            </div>-->
<!--            <div class="col row">-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Urgent-mail.svg&ndash;&gt;-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                                <rect x="0" y="0" width="24" height="24"/>-->
<!--                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
<!--                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.orders.total}}-->
<!--                    </div>-->
<!--                    <router-link :to="{ name: 'customers' }"  style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.Orders_Placed}}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Urgent-mail.svg&ndash;&gt;-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                                <rect x="0" y="0" width="24" height="24"/>-->
<!--                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
<!--                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.orders.pending}}-->
<!--                    </div>-->
<!--                    <router-link :to="{ name: 'customers' }"  style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.Pending_orders}}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Urgent-mail.svg&ndash;&gt;-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                                <rect x="0" y="0" width="24" height="24"/>-->
<!--                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
<!--                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.orders.being_prepared}}-->
<!--                    </div>-->
<!--                    <router-link :to="{ name: 'customers' }"  style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{this.l.app.Orders_Started_preparation}}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Urgent-mail.svg&ndash;&gt;-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                                <rect x="0" y="0" width="24" height="24"/>-->
<!--                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
<!--                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.orders.completed}}-->
<!--                    </div>-->
<!--                    <router-link :to="{ name: 'customers' }"  style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{this.l.app.Orders_Completed}}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                        &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Urgent-mail.svg&ndash;&gt;-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                                <rect x="0" y="0" width="24" height="24"/>-->
<!--                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>-->
<!--                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>-->
<!--                                <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>-->
<!--                            </g>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--                    </span>-->
<!--                    <div class="num_info">-->
<!--                        {{dashboardsData.orders.canceled}}-->
<!--                    </div>-->
<!--                    <router-link :to="{ name: 'customers' }"  style="font-weight: bold;font-size: 14px;float: left;line-height: 18px;padding-left: 3px; padding-top: 25px;">-->
<!--                        {{l.app.Orders_Canceled}}-->
<!--                    </router-link>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="box col bg-white px-6 py-8 rounded-xl mb-7">-->
<!--            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Add-user.svg&ndash;&gt;-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                        <polygon points="0 0 24 0 24 24 0 24"></polygon>-->
<!--                        <path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>-->
<!--                        <path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>-->
<!--                    </g>-->
<!--                </svg>-->
<!--                &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--            </span>-->
<!--            <div class="num_info">-->
<!--                {{dashboardsData.customers.total}}-->
<!--            </div>-->
<!--            <router-link :to="{ name: 'customers' }" class="text-primary font-weight-bold font-size-h6 mt-2">-->
<!--                {{l.app.customers}}-->
<!--            </router-link>-->
<!--        </div>-->
<!--        <div class="box col bg-white px-6 py-8 rounded-xl mr-7">-->
<!--            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Design/Layers.svg&ndash;&gt;-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                        <polygon points="0 0 24 0 24 24 0 24"/>-->
<!--                        <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>-->
<!--                        <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>-->
<!--                        <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>-->
<!--                    </g>-->
<!--                </svg>-->
<!--                &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--            </span>-->
<!--            <div class="num_info">-->
<!--                {{dashboardsData.transactions.total}}-->
<!--            </div>-->
<!--            <router-link :to="{ name: 'customers' }" class="text-primary font-weight-bold font-size-h6 mt-2">-->
<!--                {{this.l.app.pending_transactions}}-->
<!--            </router-link>-->
<!--        </div>-->
<!--        <div class="box col bg-white px-6 py-8 rounded-xl">-->
<!--            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">-->
<!--                &lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Urgent-mail.svg&ndash;&gt;-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
<!--                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                        <polygon points="0 0 24 0 24 24 0 24"/>-->
<!--                        <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z M10.875,15.75 C11.1145833,15.75 11.3541667,15.6541667 11.5458333,15.4625 L15.3791667,11.6291667 C15.7625,11.2458333 15.7625,10.6708333 15.3791667,10.2875 C14.9958333,9.90416667 14.4208333,9.90416667 14.0375,10.2875 L10.875,13.45 L9.62916667,12.2041667 C9.29375,11.8208333 8.67083333,11.8208333 8.2875,12.2041667 C7.90416667,12.5875 7.90416667,13.1625 8.2875,13.5458333 L10.2041667,15.4625 C10.3958333,15.6541667 10.6354167,15.75 10.875,15.75 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>-->
<!--                        <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>-->
<!--                    </g>-->
<!--                </svg>-->
<!--                &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--            </span>-->
<!--            <div class="num_info">-->
<!--                {{dashboardsData.transactions.completed}}-->
<!--            </div>-->
<!--            <router-link :to="{ name: 'customers' }" class="text-primary font-weight-bold font-size-h6 mt-2">-->
<!--                {{l.app.transactions_carried_out}}-->
<!--            </router-link>-->
<!--        </div>-->
<!--    </div>-->
    <!--end::Row-->
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import usersTable from './usersTable'

// import Pie from './pie.js'

export default {
    data: () => ({
        dashboardsData: null,
        dataGarments: {},
        dataOrders: {},
        options: {
            responsive: true
        },
        userType: null
    }),
    computed: {
        ...mapGetters({
            l: 'lang'
        }),
      getUserLifetime() {
          return this.dashboardsData ? this.dashboardsData.lifetime_users_registered : ''
      },
      getUserLogin() {
          return this.dashboardsData ? this.dashboardsData.distinct_login_count : ''
      },
      getPayingUsers() {
        return this.dashboardsData ? this.dashboardsData.charged_users : ''
      },
      getNonPayingUsers() {
        return this.dashboardsData ? this.dashboardsData.lifetime_users_registered - this.dashboardsData.charged_users : ''
      },
      getDateBeg() {
        return this.dashboardsData?.date_beg
      }
    },
    components: {
      // Pie
      usersTable
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
        }),
        getData(){
            this.$repository.dashboards
                .index({date_mode: 'month'}).then(res => {
                this.dashboardsData = res.data;
                // this.dataGarments = {
                //     datasets: [
                //         {
                //             data: [
                //                 this.dashboardsData.garments.no_status,
                //                 this.dashboardsData.garments.accepted,
                //                 this.dashboardsData.garments.rejected,
                //                 this.dashboardsData.garments.on_sale,
                //                 this.dashboardsData.garments.sold,
                //             ],
                //             backgroundColor: [
                //                 'rgb(255, 99, 132)',
                //                 'rgb(255, 159, 64)',
                //                 'rgb(255, 205, 86)',
                //                 'rgb(75, 192, 192)',
                //                 'rgb(54, 162, 235)'
                //             ],
                //             label: 'Dataset 1'
                //         }
                //     ],
                //     labels: [
                //         this.l.app.Stateless_Garments,
                //         this.l.app.Garments_accepted,
                //         this.l.app.Garments_rejected,
                //         this.l.app.Garments_for_sale,
                //         this.l.app.Garments_sold
                //     ]
                // };
                // this.dataOrders = {
                //     datasets: [
                //         {
                //             data: [
                //                 this.dashboardsData.orders.types.clothing,
                //                 this.dashboardsData.orders.types.gown,
                //                 this.dashboardsData.orders.types.haute_couture,
                //                 this.dashboardsData.orders.types.designer_bag,
                //             ],
                //             backgroundColor: [
                //                 'rgb(255, 99, 132)',
                //                 'rgb(255, 159, 64)',
                //                 'rgb(255, 205, 86)',
                //                 'rgb(75, 192, 192)',
                //             ],
                //             label: 'Dataset 1'
                //         }
                //     ],
                //     labels: [
                //         this.l.app.Prendas_clothing,
                //         this.l.app.Gown_garments,
                //         this.l.app.Haute_couturegarments,
                //         this.l.app.Garments_designer_bag
                //     ]
                // };
            }).catch(() => '');
        }
    },
    mounted() {
      this.getData();
      this.userType = 'registeredUsers'
    },
    created() {
        // this.setNavbarTitle(['Dashboards']);
    }
}
</script>

<style lang="postcss">
.box{
    border-radius: 1.25rem !important;
	padding-bottom: 2rem !important;
	padding-top: 2rem !important;
	padding-left: 1.5rem !important;
	padding-right: 1.5rem !important;
	margin-bottom: 1.75rem !important;
	margin-right: 1.75rem !important;
}
.my-2 {
    margin-bottom: 0.5rem !important;
}
.svg-icon.svg-icon-3x svg {
    height: 3rem !important;
    width: 3rem !important;
}
.svg-icon.svg-icon-info svg g [fill],
.svg-icon.svg-icon-info svg path {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ff1d30 !important;
}
.font-size-h6 {
    font-size: 1.175rem !important;
}
.svg-icon{
    float: left;
}
.num_info{
    font-weight: bold;
    font-size: 34px;
    float: left;
    line-height: 57px;
}
.text-primary{
    width: 100%;
    float: left;
    text-align: center;
    font-weight: bold;
}

</style>