<template>
  <div>
    <div class="flex flex-wrap mb-2">
      <label @click="$router.back()" class="btn text-uppercase">
        <i class="material-icons">keyboard_backspace</i>
        {{ l.app.To_return }}
      </label>
    </div>
    <div class="row kt-pt25">
      <div class="col-12 col-sm-12">
        <div class="kt-portlet kt-portlet--height-fluid" style="height: auto">
          <div class="kt-portlet__body svg_list_trainig">
            <form @submit.prevent="isUpdating ? updateData() : storeData()" @keydown="removeError($event.target.id)">
              <h3>{{ isUpdating? l.app.To_update : l.app.Create }}</h3>
              <div class="flex flex-wrap -mx-2">
                <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                  <template v-for="(input, key_input) in form">
                    <div class="form-group" v-if="input.visibility()" :key="key_input">
                      <label :for="input.atribute">{{ input.label }}</label>

                      <sim-input v-if="
                      input.type !== 'select' &&
                      input.type !== 'editor' &&
                      input.type !== 'date' &&
                      input.type !== 'find_address'" :id="input.atribute" :type="input.type"
                        :error="error(input.atribute, { attribute: input.atribute })" v-model="formData[input.atribute]"
                        @input="setForm" />

                      <div class="alingDates" v-else-if="input.type === 'date'">
                        <sim-input :id="input.atribute" :name="input.atribute" :type="input.type" :min="
                    input.atribute === 'start_at'
                      ? input.min
                      : formData.start_at
                        ? formData.start_at
                        : input.min" :max="
      input.atribute === 'finish_at' ? null : formData.finish_at"
                          :error="error(input.atribute, { attribute: input.atribute })" @input="setDate"
                          v-model="formData[input.atribute]" />

                        <v-select v-if="input.atribute === 'start_at'" v-model="start_at"
                          :options="filterHours"></v-select>
                        <v-select v-else v-model="finish_at" :options="source.hour_list"></v-select>

                        <div class="w-full" style="padding-right: 10px">
                          <div class="checkbox" style="margin-top: 9px; position: relative">
                            <label v-if="input.atribute === 'start_at'">
                              <input type="checkbox" :checked="multipleDays" v-model="multipleDays" />
                              <i class="input-helper"></i>
                              Multiple days
                            </label>
                          </div>
                        </div>
                      </div>

                      <vue-editor v-else-if="input.type === 'editor'" :id="input.atribute"
                        v-model="formData[input.atribute]"></vue-editor>

                      <div v-else-if="input.type === 'find_address'" class="row">
                        <div class="col-7">
                          <div id="lookup_field" class="col-10" :disabled="!allow_edit"></div>
                        </div>
                        <div class="col-3">
                          <button v-if="btnAdrres" :disabled="!allow_edit" type="button" @click="getPostcode()" style="
                            display: block;
                            width: 140px;
                            height: 40px;
                            border-radius: 4px;
                            background: #1b70b7;
                            font-weight: bold;
                            font-size: 16px;
                            text-align: center;
                            color: #fff;
                            ">
                            Find my Address
                          </button>
                        </div>
                        <div class="col-2">
                          <button @click="clearPostcode()" style="
                              display: block;
                              width: calc(100% - 11px);
                              margin-left: 10px;
                              height: 40px;
                              border-radius: 4px;
                              border: 1px solid #1b70b7;
                              color: #1b70b7;
                              font-weight: bold;
                              font-size: 16px;
                              text-align: center;
                            ">
                            Clear
                          </button>
                        </div>
                        <div :class="['col-12', 'row', !getAdrres && 'hidden']">
                          <div class="col-12 row">
                            <label class="title-label">Address Line One</label>
                            <sim-input id="first_line" v-model="first_line" :disabled="!allow_edit"></sim-input>
                          </div>
                          <div class="col-12 row">
                            <label class="title-label">Address Line Two</label>
                            <sim-input id="second_line" v-model="second_line" :disabled="!allow_edit"></sim-input>
                          </div>
                          <div class="col-12 row">
                            <label class="title-label">Address Line Three</label>
                            <sim-input id="third_line" v-model="third_line" :disabled="!allow_edit"></sim-input>
                          </div>
                          <div class="col-12 row">
                            <label class="title-label">Post Town</label>
                            <sim-input id="post_town" v-model="post_town" :disabled="!allow_edit"></sim-input>
                          </div>
                          <div class="col-12 row">
                            <label class="title-label">Postcode</label>
                            <sim-input id="postcode" v-model="postcode" :disabled="!allow_edit"></sim-input>
                          </div>
                        </div>
                      </div>

                      <template v-else>
                        <v-select v-model="selectData[input.atribute]" :options="source[input.source]"></v-select>

                        <div class="feedback-zone">
                          <span>
                            <p style="color: #ff1100" :class="[`sim-input-error-message`]"
                              v-if="error(input.atribute, { attribute: input.atribute })">
                              {{
  error(input.atribute, {
  attribute: input.atribute,
                              })
                              }}
                            </p>
                          </span>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>

              <sim-button class="mr-2" type="submit" :loading="loading">
                <i class="material-icons">save</i>
                {{ isUpdating? l.app.Update_changes : l.app.Save_Changes }}
              </sim-button>

              <sim-button outlined :to="{ name: 'events.index' }" style="color: #e01f8b !important">
                <i class="material-icons">clear</i>
                {{ l.app.Cancel }}
              </sim-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import "vue-select/src/scss/vue-select.scss";
import { VueEditor } from "vue2-editor";
import vSelect from "vue-select";
// import moment from "moment";
import * as moment from 'moment'
import { PostcodeLookup } from "@ideal-postcodes/postcode-lookup";


export default {
  data: () => ({
    // moment: moment,
    allow_edit: true,
    btnAdrres: true,
    getAdrres: false,
    multipleDays: false,
    picture: null,
    isUpdating: false,
    pictureV: "",
    first_line: "",
    second_line: "",
    third_line: "",
    post_town: "",
    postcode: "",
    start_at: "",
    finish_at: "",
    selectData: {
      event_type: "",
      privacy: "public",
      start_at: "",
      finish_at: "",
    },
    source: {
      event_type: [
        {
          value: "Online",
          label: "Online",
        },
        {
          value: "In_person",
          label: "In person",
        },
      ],
      /*privacy: [
          {
              value: "private",
              label: "Only people who are invited",
          },
          {
              value: "public",
              label: "Anyone on or off Pension Playpen",
          },
      ],*/
      platform: [
        {
          value: "GoogleMeet",
          label: "Google Meet",
        },
        {
          value: "zoom",
          label: "Zoom",
        },
        {
          value: "MicrosoftTeams",
          label: "Microsoft Teams",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      hour_list: [
        {
          value: "00:00",
          label: "00:00",
        },
        {
          value: "00:30",
          label: "00:30",
        },
        {
          value: "01:00",
          label: "01:00",
        },
        {
          value: "01:30",
          label: "01:30",
        },
        {
          value: "02:00",
          label: "02:00",
        },
        {
          value: "02:30",
          label: "02:30",
        },
        {
          value: "03:00",
          label: "03:00",
        },
        {
          value: "03:30",
          label: "03:30",
        },
        {
          value: "04:00",
          label: "04:00",
        },
        {
          value: "04:30",
          label: "04:30",
        },
        {
          value: "05:00",
          label: "05:00",
        },
        {
          value: "05:30",
          label: "05:30",
        },
        {
          value: "06:00",
          label: "06:00",
        },
        {
          value: "06:30",
          label: "06:30",
        },
        {
          value: "07:00",
          label: "07:00",
        },
        {
          value: "07:30",
          label: "07:30",
        },
        {
          value: "08:00",
          label: "08:00",
        },
        {
          value: "08:30",
          label: "08:30",
        },
        {
          value: "09:00",
          label: "09:00",
        },
        {
          value: "09:30",
          label: "09:30",
        },
        {
          value: "10:00",
          label: "10:00",
        },
        {
          value: "10:30",
          label: "10:30",
        },
        {
          value: "11:00",
          label: "11:00",
        },
        {
          value: "11:30",
          label: "11:30",
        },
        {
          value: "12:00",
          label: "12:00",
        },
        {
          value: "12:30",
          label: "12:30",
        },
        {
          value: "13:00",
          label: "13:00",
        },
        {
          value: "13:30",
          label: "13:30",
        },
        {
          value: "14:00",
          label: "14:00",
        },
        {
          value: "14:30",
          label: "14:30",
        },
        {
          value: "15:00",
          label: "15:00",
        },
        {
          value: "15:30",
          label: "15:30",
        },
        {
          value: "16:00",
          label: "16:00",
        },
        {
          value: "16:30",
          label: "16:30",
        },
        {
          value: "17:00",
          label: "17:00",
        },
        {
          value: "17:30",
          label: "17:30",
        },
        {
          value: "18:00",
          label: "18:00",
        },
        {
          value: "18:30",
          label: "18:30",
        },
        {
          value: "19:00",
          label: "19:00",
        },
        {
          value: "19:30",
          label: "19:30",
        },
        {
          value: "20:00",
          label: "20:00",
        },
        {
          value: "20:30",
          label: "20:30",
        },
        {
          value: "21:00",
          label: "21:00",
        },
        {
          value: "21:30",
          label: "21:30",
        },
        {
          value: "22:00",
          label: "22:00",
        },
        {
          value: "22:30",
          label: "22:30",
        },
        {
          value: "23:00",
          label: "23:00",
        },
        {
          value: "23:30",
          label: "23:30",
        },
      ],
    },
    form: [],
    pictures: [],
    formData: {
      platform: "",
      title: "",
      event_type: "",
      privacy: "public",
      start_at: "",
      tags: [],
      finish_at: "",
      description: "",
      url: "",
      video_url: "",
      cpd_minutes: "",
    },
    receipt: [{ label: "Primera Imagen", pictureV: "", file: null }],
    certificate: [{ label: "Primera Imagen", pictureV: "", file: null }],
    loading: true,
  }),
  computed: {
    ...mapGetters({
      hasError: "hasError",
      error: "error",
      l: "lang",
    }),
    ...mapState({
      lang: (state) => state.lang,
      errorsS: (state) => state.errors,
      companies: (state) => state.companies.pagination.data,
      garment: (state) => state.companies.garment,
      categories: (state) => state.categories.pagination,
      brands: (state) => state.brands.pagination,
      priceRanges: (state) => state.PriceRanges.pagination,
    }),
    filterHours() {
      let horas = []
      let now = moment().format('YYYY-MM-DD');

      horas = this.source.hour_list.filter((hora) => {
        if(moment().format('HH:mm').toString() < moment(`${now} ${hora.value}`).format('HH:mm').toString()) {
          return true
        }
        return false
      })

      return now == moment(this.formData.start_at).format('YYYY-MM-DD') ? horas : this.source.hour_list
    }
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: "SET_NAVBAR_TITLE",
      setAdministrator: "companies/SET_ADMINISTRATOR",
    }),
    ...mapActions({
      store: "events/store",
      removeError: "removeError",
      fetchEvent: "events/fetchEvent",
      update: "events/update",
    }),
    clearPostcode() {
      console.log('clearPostcode')
      this.first_line = "";
      this.second_line = "";
      this.third_line = "";
      this.post_town = "";
      this.postcode = "";
      this.events.address = "";

      if (document.getElementsByClassName("idpc-input")[0]) {
        document.getElementsByClassName("idpc-input")[0].value = "";
      }
      if (document.getElementsByClassName("idpc-select")[0]) {
        document.getElementsByClassName("idpc-select")[0].remove();
      }
      this.getPostcode();
    },
    getPostcode() {
      console.log('getPostcode')
      setTimeout(() => {
        try {
          this.loadingPostCode = true;
          // eslint-disable-next-line
          PostcodeLookup.setup({
            apiKey: "ak_knkvgud5pA544LxBCfJRyTYdPp11D",
            context: "#lookup_field",
            outputFields: {
              line_1: "#first_line",
              line_2: "#second_line",
              line_3: "#third_line",
              post_town: "#post_town",
              postcode: "#postcode",
            },
          });
        } catch (error) {
          console.log('error: ', error)
          if (error.message.indexOf('getAttribute') < 0) {
            setTimeout(() => this.getPostcode(), 1000);
          }
          this.loadingPostCode = false;
          return false;
        }

        // if (
        //   (!['company', 'my-app/company'].includes(this.page) && !document.querySelector(".idpc-input")) ||
        //   typeof AddressFinder == "undefined"
        // ) {
        //   this.loadingPostCode = false;
        //   setTimeout(() => this.getPostcode(), 1000);
        //   return false
        // } else {
        //   this.loadingPostCode = true;
        //   return false
        // }
      }, 1000);
    },
    setForm(data) {
      this.formData[data.id] = data.value;
    },
    setDate(data) {
      this.selectData[data.id] = data.value;
    },
    addImgADD(img, picture) {
      picture.file = img;
    },
    addImgPV(img, picture) {
      picture.pictureV = img;
    },
    addArryPicture() {
      this.pictures.push({ label: "Primera Imagen", pictureV: "", file: null });
    },
    activeInput() {
      if (this.formData.brand_id === -1) {
        this.form[10].visibility = true;
      } else {
        this.form[10].visibility = false;
        this.formData.other_brand = "";
      }
    },
    urlRegex(url) {
      // eslint-disable-next-line
      const regexp = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      let src = url;
      let platform = true;
      if (
        this.selectData?.platform === "GoogleMeet" ||
        this.selectData?.platform?.value === "GoogleMeet"
      ) {
        platform = src.includes("meet.google.com");
      } else if (
        this.selectData?.platform === "zoom" ||
        this.selectData?.platform?.value === "zoom"
      ) {
        platform = src.includes("zoom.us");
      } else if (
        this.selectData?.platform === "MicrosoftTeams" ||
        this.selectData?.platform?.value === "MicrosoftTeams"
      ) {
        platform = src.includes("teams.microsoft.com");
      } else {
        platform = regexp.test(src);
      }
      return platform;
    },
    validateUrl() {
      if (
        this.formData.video_url &&
        this.formData.video_url != "" &&
        !/^.+\.(mp4|x-flv|MP2T|3gpp|quicktime|x-msvideo|x-ms-wmv)/.test(
          this.formData.video_url
        ) &&
        this.formData.video_url.indexOf("playpen") < 0 &&
        this.formData.video_url.indexOf("instagram") < 0 &&
        this.formData.video_url.indexOf("twitter") < 0 &&
        this.formData.video_url.indexOf("youtu") < 0 &&
        this.formData.video_url.indexOf("dai") < 0 &&
        this.formData.video_url.indexOf("vimeo") < 0 &&
        this.formData.video_url.indexOf("coub") < 0 &&
        this.formData.video_url.indexOf("eventbrite") < 0
      ) {
        this.$message.error("Eventbrite url entered is not valid.");
        return false;
      }
      return true;
    },
    updateData() {
      if (!this.formData.title || this.formData.title == "") {
        this.$message.error(this.l.messages.errors.title);
        return false;
      }

      if (this.selectData.event_type && this.selectData.event_type !== "") {
        this.formData.event_type = this.selectData.event_type;

        if (
          this.selectData.event_type == "Online" &&
          (!this.selectData.platform || this.selectData.platform == "")
        ) {
          this.$message.error(this.l.messages.errors.select_plataform);
          return false;
        }
      } else {
        this.$message.error(this.l.messages.errors.event_type);
        return false;
      }

      this.formData.privacy = "public";
      /*if(this.selectData.privacy.value !== ''){
          this.formData.privacy = this.selectData.privacy.value
      }*/

      if (
        !this.selectData.platform?.value ||
        (this.selectData.platform?.value && this.selectData.platform?.value !== "")
      ) {
        this.formData.platform = this.selectData.platform?.value;

        if (
          (!this.formData.url ||
            this.formData.url == "" ||
            this.urlRegex(this.formData.url) === false) &&
          this.selectData.event_type &&
          this.selectData.event_type === "Online"
        ) {
          this.$message.error(
            `You must enter a valid url of the ${this.selectData.platform?.value !== "other"
              ? this.selectData.platform?.value
              : "online platform"
            }!`
          );
          return false;
        }
      } else {
        this.$message.error(this.l.messages.errors.select_plataform);
        return false;
      }

      if (this.selectData.start_at && this.selectData.start_at !== "") {
        this.formData.start_at = moment(
          `${moment(this.formData.start_at).format("YYYY-MM-DD")} ${this.start_at?.value}`
        ).format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        this.$message.error(this.l.messages.errors.start_at);
        return false;
      }

      let finishDate = "";
      if (this.selectData.finish_at && this.selectData.finish_at !== "") {
        finishDate = moment(
          `${moment(this.formData.finish_at).format("YYYY-MM-DD")} ${this.finish_at}`
        ).format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if (
        this.selectData.finish_at &&
        this.selectData.finish_at.value !== "" &&
        finishDate != "Invalid date"
      ) {
        this.formData.finish_at = finishDate;
      } else {
        this.formData.finish_at = null;
      }

      if (!this.validateUrl()) {
        return false;
      }

      this.update(this.formData);
    },
    storeData() {
      if (!this.formData.title || this.formData.title == "") {
        this.$message.error(this.l.messages.errors.title);
        return false;
      }

      if (this.selectData.event_type?.value && this.selectData.event_type?.value !== "") {
        this.formData.event_type = this.selectData.event_type?.value;

        if (
          this.selectData.event_type?.value == "Online" &&
          (!this.selectData.platform ||
            !this.selectData.platform ||
            this.selectData.platform == "")
        ) {
          this.$message.error(this.l.messages.errors.select_plataform);
          return false;
        }

        if (
          this.selectData.event_type?.value == "In_person" &&
          (!this.selectData.location ||
            !this.selectData.location ||
            this.selectData.location == "")
        ) {
          this.$message.error(this.l.messages.errors.location);
          return false;
        }
      } else {
        this.$message.error(this.l.messages.errors.event_type);
        return false;
      }

      this.formData.privacy = "public";
      /*if(this.selectData.privacy !== ''){
          this.formData.privacy = this.selectData.privacy
      }*/

      if (this.selectData.platform?.value !== "") {
        this.formData.platform = this.selectData.platform?.value;

        if (
          (!this.formData.url ||
            this.formData.url == "" ||
            this.urlRegex(this.formData.url) === false) &&
          this.selectData.event_type?.value &&
          this.selectData.event_type?.value === "Online"
        ) {
          this.$message.error(
            `You must enter a valid url of the ${this.selectData.platform?.value !== "other"
              ? this.selectData.platform?.value
              : "online platform"
            }!`
          );
          return false;
        }
      } else {
        this.$message.error(this.l.messages.errors.select_plataform);
        return false;
      }

      if (this.formData.start_at && this.formData.start_at !== "") {
        this.formData.start_at = moment(
          `${moment(this.formData.start_at).format("YYYY-MM-DD")} ${this.start_at?.value}`
        ).format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        this.$message.error(this.l.messages.errors.start_at);
        return false;
      }

      let finishDate = "";
      if (this.formData.finish_at?.value && this.formData.finish_at?.value !== "") {
        finishDate = moment(
          `${moment(this.formData.finish_at?.value).format("YYYY-MM-DD")} ${this.finish_at
          }`
        ).format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if (
        this.selectData.finish_at &&
        this.selectData.finish_at !== "" &&
        finishDate != "Invalid date"
      ) {
        this.formData.finish_at = finishDate;
      } else {
        this.formData.finish_at = null;
      }

      if (!this.validateUrl()) {
        return false;
      }

      this.store(this.formData);
    },
    moment,
  },
  components: {
    vSelect,
    VueEditor,
  },
  async created() {
    if (this.$route.name === "events.create") {
      this.setNavbarTitle(["List of Events", this.l.app.Create]);
    } else {
      const { id } = this.$route.params;
      this.isUpdating = true;
      this.setNavbarTitle(["List of Events", this.l.app.To_update]);
      let res = await this.fetchEvent(id);
      let start = moment.utc(res.start_at).utcOffset("01:00");
      let fihish = moment.utc(res.finish_at).utcOffset("01:00");

      (this.selectData = {
        event_type: res.event_type,
        privacy: "public", //res.privacy,
        platform: res.platform,
        start_at:
          moment(start).format("HH:mm") != "Invalid date"
            ? moment(start).format("HH:mm")
            : "",
        finish_at:
          moment(fihish).format("HH:mm") != "Invalid date"
            ? moment(fihish).format("HH:mm")
            : "",
      }),
        (this.formData = {
          id: res.id,
          platform: res.platform,
          title: res.title,
          type: res.event_type,
          privacy: "public", //res.privacy,
          start_at: moment(start).format("YYYY-MM-DD"),
          tags: [],
          finish_at:
            moment(fihish).format("YYYY-MM-DD") != "Invalid date"
              ? moment(fihish).format("YYYY-MM-DD")
              : "",
          description: res.description,
          url: res.url,
          video_url: res.video_url,
          cpd_minutes: res.cpd_minutes,
        });
      console.log("this.formData", this.formData);
    }
    this.form = [
      {
        label: "title",
        atribute: "title",
        type: "text",
        visibility: () => true,
      },
      {
        label: "type",
        atribute: "event_type",
        type: "select",
        visibility: () => true,
        source: "event_type",
      },
      //{label: 'Privacy', atribute: 'privacy', type: 'select', visibility: true ,source:'privacy'},
      {
        label: "date",
        atribute: "start_at",
        type: "date",
        visibility: () => true,
        min: moment().format("yyyy-MM-DD"),
      },
      {
        label: "date end",
        atribute: "finish_at",
        type: "date",
        visibility: () => this.multipleDays,
        min: moment().format("yyyy-MM-DD"),
      },
      {
        label: "description",
        atribute: "description",
        type: "editor",
        visibility: () => true,
      },
      {
        label: "platform",
        atribute: "platform",
        type: "select",
        visibility: () => this.selectData.event_type.value == "Online",
        source: "platform",
      },
      {
        label: "price",
        atribute: "price",
        type: "number",
        visibility: () => true,
      },
      {
        label: "url",
        atribute: "url",
        type: "text",
        visibility: () => true,
      },
      {
        label: "Eventbrite URL",
        atribute: "video_url",
        type: "text",
        visibility: () => true,
      },
      {
        label: "CPD Points to get with this event",
        atribute: "cpd_minutes",
        type: "text",
        visibility: () => true,
      },
      {
        label: "location",
        atribute: "address",
        type: "find_address",
        visibility: () => this.selectData.event_type.value == "In_person",
      },

      /*
      {label: 'event_type', atribute: 'about', type: 'text', visibility: true},
      {label: 'start_at', atribute: 'start_at', type: 'date', visibility: true},
      {label: 'address', atribute: 'address', type: 'text', visibility: true},
      {label: 'description', atribute: 'description', type: 'text', visibility: true},
      {label: 'address' , atribute: 'country_code', type: 'select', source: 'country', visibility: true},
      {label: 'website url', atribute: 'website_url', type: 'text', visibility: true},
      {label: 'sector', atribute: 'sector', type: 'text', visibility: true},
      {label: 'scheme type', atribute: 'scheme_type', type: 'text', visibility: true},
      {label: 'established_at', atribute: 'established_at', type: 'date', visibility: true},
      {label: 'regulated', atribute: 'regulated', type: 'text', visibility: true},
      {label: 'provider type', atribute: 'provider_type', type: 'text', visibility: true},
      {label: 'insurance details', atribute: 'insurance_details', type: 'text', visibility: true}*/
    ];
    this.loading = false;
  },
  watch: {
    "formData.address"(e) {
      console.log('formData.address: ', this.formData.address)
      if (e !== "") {
        this.getAdrres = true;
      }
      if (e === "#,##,##,##,#") {
        this.getAdrres = false;
      }
    },
    "selectData.event_type.value"(v) {
      console.log('*********** v: ', v)
      if (v === "In_person") {
        this.getPostcode();
      }
    },
    postcode() {
      this.formData.address =
        this.first_line +
        "#,#" +
        this.second_line +
        "#,#" +
        this.third_line +
        "#,#" +
        this.post_town +
        "#,#" +
        this.postcode;
    },

    first_line() {
      this.formData.address =
        this.first_line +
        "#,#" +
        this.second_line +
        "#,#" +
        this.third_line +
        "#,#" +
        this.post_town +
        "#,#" +
        this.postcode;
    },

    second_line() {
      this.formData.address =
        this.first_line +
        "#,#" +
        this.second_line +
        "#,#" +
        this.third_line +
        "#,#" +
        this.post_town +
        "#,#" +
        this.postcode;
    },

    third_line() {
      this.formData.address =
        this.first_line +
        "#,#" +
        this.second_line +
        "#,#" +
        this.third_line +
        "#,#" +
        this.post_town +
        "#,#" +
        this.postcode;
    },

    post_town() {
      this.formData.address =
        this.first_line +
        "#,#" +
        this.second_line +
        "#,#" +
        this.third_line +
        "#,#" +
        this.post_town +
        "#,#" +
        this.postcode;
    },
  }
};
</script>

<style lang="scss">
.conte_img {
  display: table;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.add_img {
  border: 2px dashed rgb(235, 237, 242) !important;
  width: 180px;
  height: 250px;
  display: flex;
  -webkit-box-align: center;
  cursor: pointer;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);

  i {
    color: #ebedf2;
    font-size: 70px;
  }

  &:hover {
    i {
      color: #374afb;
    }
  }
}

.deleteIcon {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

.alingDates {
  display: flex;
  gap: 10px;
  align-items: center;

  >div {
    width: 100%;
  }
}
</style>
