import Vue from 'vue'
import Vuex from 'vuex'
import languages from '@/assets/lang'
import modules from './modules'
import { getCookie, setCookie } from '@/helpers/storage'

Vue.use(Vuex)

export default new Vuex.Store({
    modules,
    state: {
        lang: getCookie(process.env.VUE_APP_USER_PREFERRED_LANGUAGE) || 'en',
        errors: {},
        cancelTokens: [],
    },
    mutations: {
        TRANSLATE: (state, lang) => state.lang = lang,
        CLEAR_ERRORS: state => state.errors = {},
        REMOVE_ERROR: (state, error) => delete state.errors[error],
        SET_ERRORS: (state, errors) => state.errors = errors,
        ADD_CANCEL_TOKEN: (state, token) => state.cancelTokens.push(token),
        CLEAR_CANCEL_TOKENS: (state) => state.cancelTokens = [],
    },
    actions: {
        translate({ commit }, lang = 'es') {
            if (lang) {
                if (lang.length) {
                    setCookie(process.env.VUE_APP_USER_PREFERRED_LANGUAGE, lang)
                    commit('TRANSLATE', lang)
                }
            }
        },
        setErrors({ commit }, errors) {
            commit('SET_ERRORS', errors)
        },
        removeError({ commit }, error) {
            commit('REMOVE_ERROR', error)
        },
        vanishErrors({ commit }) {
            commit('SET_ERRORS', {})
        },
        cancelPendingRequest({ state, commit }) {
            // Cancel all request where a token exists
            state.cancelTokens.forEach(request => {
                if (request.cancel){
                    request.cancel()
                }
            });

            // Reset the cancelTokens store
            commit('CLEAR_CANCEL_TOKENS')
        }
    },
    getters: {
        lang: ({ lang }) => languages[lang],
        currentLang: (state, getters) => getters.lang.app.languages.find(lang => state.lang === lang.iso).name,
        hasError: state => error => state.errors.hasOwnProperty(error),
        hasOneOfErrors: state => (...errors) => !Object.keys(state.errors).some(error => errors.some(_error => error === _error)),
        getError: state => error => state.errors[error] ? state.errors[error][0] : "",
        error: (state, { lang, getError }) => (error, rules = {}) => {
            let gotten = getError(error)
            
            let translated = lang.errors[gotten.split(':')[0]]
            let globalReg = /:attribute|:value|:before|:after|:min|:max|:date|:values|:format|:digits|:other/g
            if (translated) {
                // console.log(gotten.split(':')[0], translated)
                let matchs = translated.match(globalReg)
                for (let match of matchs) {
                    let replacingString = null
                    if (typeof rules[match.replace(':', '')] == 'function') {
                        replacingString = rules[match.replace(':', '')](gotten.split(':')[1])
                    } else {
                        replacingString = rules[match.replace(':', '')]
                    }
                    translated = translated.replace(match, replacingString || "")
                }
            }
            return gotten
        }
    }
})
