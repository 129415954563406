import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        field: {},
        loadings: { index: false, show: false, store: false }
    },
    mutations: {
        SET_PAGINATED_FIELD: (state, pagination) => state.pagination = pagination,
        SET_FIELD: (state, field) => state.field = field,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        fetchMessages({ commit }, page = {} ) {
            commit('TOGGLE_LOADING', 'index')
            this.$repository.messages
                .index(page)
                .then(({ data }) => {
                    commit('SET_PAGINATED_FIELD', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        fetchMessage({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.messages
                .show(id)
                .then(({ data }) => {
                    commit('SET_FIELD', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, data) {
            commit('TOGGLE_LOADING', 'store')
            return this.$repository.messages
                .store(data)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Successful registration`,
                        position: `top-right`
                    })
                    
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        }
    },
    getters: {
    }
}