export default ({ axios, mapQueryString, toFormData, withoutInvalidValues }) => ({
    index: (query = {}) => axios.get(`/users${mapQueryString(query)}`),
    profiles: (query = {}) => axios.get(`/user-profiles${mapQueryString(query)}`),
    show: (id) => axios.get(`/users/${id}`),
    store: (user) => axios.post(`/sign-up`, toFormData(withoutInvalidValues(user))),
    create: (user) => axios.post(`/users`, user),
    createProfile: (user) => axios.post(`/user-profiles`, user),
    update: (id, user) => axios.patch(`/user-profiles/${id}`, user),
    updateImg: (id, datFile) => axios.post(`/user-profiles/${id}/upload-avatar`, datFile),
    updateDataUser: (id, user) => axios.patch(`/users/${id}`, user),
    updateEmail: (id, body) => axios.patch(`/users/${id}/change-email`, body),
    updatePassword: (id, body) => axios.patch(`/users/${id}/change-password`, body),
    updatePicture: (id, body) => axios.post(`/users/${id}/profile-picture`, body),
    delete: (id) => axios.delete(`/users/${id}`),
})