<template>
  <div class="mt-4">
    <h3 class="w-100 block">{{ getTitle() }}</h3>
    <div class="mt-4">
      <sim-table
          :loading="loading"
          :columns="columns"
          :source="this.data">
        <template #td:profile="{ record }">
          <div v-if="record.item.profile !== null" style="display: flex;">
            <sim-img-blog
                :img_url="record.item.profile.avatar_url"
                :custom_class="'u-iten-img'"
                :custom_class2="'PP_circular_gradient'"
                :detailTex="''"/>

            <span class="u-iten-text-user row" style="width: 100%;">
                        <span class="name col-12" v-if="record.item.profile !== undefined && record.item.profile !== null">
                            {{ record.item.profile.name }} {{ record.item.profile.last_name }}
                            <small class="col-12" style="font-weight: normal;">
                                {{ record.item.profile.email ? record.item.profile.email :'' }}
                            </small>
                        </span>
                    </span>
          </div>
          <div v-else class="flex space-x-2 flex-wrap">
            -- Unregistered profile yet --
          </div>
        </template>
        <template #td:paying="{ record }">
          <div v-if="record.item.current_plan !== null">
            {{ 'Paying' }}
          </div>
          <div v-else>
            {{ 'Not Paying' }}
          </div>
        </template>
        <template #td:allStars="{ record }">
          <div v-if="record.item.profile !== null">
            {{ record.item.profile.is_featured ? 'All Stars' : '---' }}
          </div>
          <div v-else>
            {{ '---' }}
          </div>
        </template>
        <template #td:price="{ record }">
          <div v-if="record.item.current_plan !== null">
            {{ record.item.current_plan ? `${record.item.current_plan.price_e2 / 100}${record.item.current_plan.currency.symbol}` : '---' }}
          </div>
          <div v-else>
            {{ '---' }}
          </div>
        </template>
      </sim-table>
      <sim-pagination v-if="data.length > 0 && title === 'registeredUsers'" :data="pagVals" @change="handleChange" />
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: 'usersTable',
  props: {
    title: { required: false, type: String, default: 'Users'},
    dateBegin: { required: false, type: String }
  },
  data () {
    return {
      page: 1,
      data: [],
      pagVals: {},
    }
  },
  computed: {
    ...mapState({
      pagination: state => state.users.pagination,
      loading: state => state.users.loadings.index,
      session: state => state.session.user
    }),
    ...mapGetters({
      hasError: "hasError",
      error: "error",
      l: "lang",
    }),
    columns() {
      return [
        { title: this.l.app.profile, slot: 'profile', key: 'profile', filter: false, sortable: false, alignment: 'right', width: '242px' },
        { title: this.l.app.work_email, key: 'email', filter: false, sortable: false },
        { title: 'status', key: 'paying', slot: 'paying', filter: false },
        { title: 'all stars', key: 'allStars', slot: 'allStars', filter: false },
        { title: 'plan price', key: 'price', slot: 'price', filter: false },
      ]
    },
    showPag () {
      return this.data.length > 0
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: 'users/index',
    }),
    handleChange (e) {
      this.page = parseInt(e)
      this.getUsers()
    },
    getUsers () {
      this.data = []
      switch(this.title) {
        case 'registeredUsers': {
          this.fetchUsers({ page: this.page, limit: 10, with: 'currentPlan', plan: 'premium'})
              .then((val) => {
                this.data = val.data
                this.pagVals = { ...val }
              })
          break
        }
        case 'paying': {
          this.fetchUsers({ limit: 0, with: 'currentPlan', plan: 'premium'})
              .then((val) => {
                this.data = val.filter((val) => {
                  if (val.current_plan) {
                    if (val.current_plan.price_e2 > 0 && val.current_plan.charged_at > this.dateBegin) {
                      return val
                    }
                  }
                  return false
                })
                // this.pagVals = { ...val }
              })
          break
        }
        case 'nonpaying': {
          this.fetchUsers({ limit: 0, with: 'currentPlan', plan: 'premium'})
              .then((val) => {
                this.data = val.filter((val) => {
                  if (val.current_plan) {
                    if (val.current_plan.charged_at < this.dateBegin) {
                      return val
                    }
                  } else {
                    return val
                  }
                  return false
                })
                // this.pagVals = { ...val }
              })
          break
        }
      }
    },
    getTitle () {
      let $1
      switch(this.title) {
        case 'registeredUsers': {
          $1 = this.l.app.dashboard.registered_users
          break
        }
        case 'paying': {
          $1 = this.l.app.dashboard.paying_users
          break
        }
        case 'nonpaying': {
          $1 = this.l.app.dashboard.non_paying_users
          break
        }
      }
      return $1
    }
  },
  watch: {
    title() {
      this.page = 1
      this.getUsers()
    }
  },
  mounted () {
    // this.fetchUsers()
  }
}
</script>

<style scoped>

</style>