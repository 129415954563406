<template>
<div
    :class="[`${prefix}-dropdown`, { 'is-right': right }]"
    v-outside-click="() => closeMenu()">

    <span ref="boton" @click.stop="toggleOpen">
        <slot />
    </span>

    <div ref="menuConet" :class="[`${prefix}-dropdown-menu`, this.fixed && 'fixed_menu']" v-if="isOpen" :style="style">
        <div :class="[`${prefix}-dropdown-menu-header`]" v-if="$slots.header">
            <slot name="header"/>
        </div>
        <slot name="menu" />
        <div :class="[`${prefix}-dropdown-menu-footer`]" v-if="$slots.footer">
            <slot name="footer"/>
        </div>
    </div>
</div>
</template>

<script>
import { prefix } from '@/components/utils'
export default {
    name: `dropdown`,
    props: {
        right: { required: false, type: Boolean, default: false },
        width: { required: false, type: String, default: null },
        offsetTop: { required: false, type: String, default: null },
        fixed: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix,
        isOpen: false,
        style:{}
    }),
    methods: {
        toggleOpen() {
            if(!this.isOpen){
                if(this.fixed){
                    let posicion = this.$refs.boton.getBoundingClientRect();
                    let top= (posicion.top + 20)
                    let mar= '';
                    let porcentaje = top * 100 / screen.availHeight;
                    // console.log(`top componet ${posicion.top}`, `top componet ${screen.availHeight}`);
                    // console.log(top * 100 / document.getElementById('all_app').clientHeight, document.getElementById('all_app').clientHeight);
                    if(porcentaje > 70){
                        mar= '-10%';
                    }
                    //console.log(`top ${posicion.top}`, `right ${posicion.right}`, `bottom ${posicion.bottom}`, `left ${posicion.left}`);
                    this.style = {
                        width: '320px', 
                        zIndex: 1, 
                        position: 'fixed',
                        left: posicion.left + 'px',
                        marginTop: mar,
                        marginLeft: '-268px',
                        top: top +'px'
                    }
                    if(document.querySelector(".fixed_menu") === null){
                        this.isOpen = true;
                    }
                } else {
                    this.style = { width: this.width || 'unset', top: `calc(100% + ${this.offsetTop})` || 'calc(100% + 10px)', zIndex: 1 };
                    this.isOpen = true;
                }
            } else {
                this.isOpen = false
            }
            
        },
        closeMenu(){
            this.isOpen = false
        }
    },
    mounted() {

    }
}
</script>

<style lang="scss">
.sim-table .sim-dropdown-item > .kt-nav__link .kt-nav__link-text{
        font-size: 12px;
        text-align: left;
}
</style>