export const URL = process.env.VUE_APP_NODE_ENV !== 'dev'
    ? process.env.VUE_APP_PRODUCTION_API_URL
    : process.env.VUE_APP_DEVELOPMENT_API_URL

export const Role = {
    all: 9999,
}

export const Routes = {
    loginURL: '/login',
    homeURL: '/users'
}