export default ({ axios, mapQueryString }) => ({
	index: (query = {}) => axios.get(`/media${mapQueryString(query)}`),
	show: (id = {}) => axios.get(`/media/${id}`),
	update: (id, data) => axios.patch(`/media/${id}`, data),
	publish: (id, data) => axios.post(`/media/${id}/publish`, data),
	delete: (id) => axios.delete(`/media/${id}`),
	uploadFeaturedImage: (id, data) => axios.post(`/media/${id}/upload-featured-image`, data),
	fileResources: (id, data) => axios.post(`/file-resources/${id}`, data),
	//store: (data) => axios.post(`/media`, data),
	store: (data) => axios({
		method: "post",
		url: `/media`,
		data: data,
		headers: {
			"Content-Type": ['link'].includes(data.type) ? "application/json" : "multipart/form-data;",
		},
	}),
})