import components from '@/components';
import { prefix } from '@/components/utils';

export default ({ vue: Vue }) => {

    for(let component in components) {
        const current = components[component];
        const name = `${prefix}-${current.name}`;
        // console.log( name );
        delete current.name; // this delete is to avoid console warnings for using a built-in html element name
        Vue.component(name, current)
    }
}