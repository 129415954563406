<template>
  <div>
    <div class="flex justify-end mb-4">
      <sim-button align="right" :to="{ name: 'banners.create' }">
        <i class="material-icons">add</i> {{this.l.app.create_banner}}
      </sim-button>
    </div>
    <sim-table
        :loading="loading.index || loading.update"
        :columns="columns"
        :toolbar-per-page="$route.query.limit"
        :source="banners.data">

      <template #td:url="{ record }">
        <span>{{ record.item.type === 'image' ? record.item.payload.image_url : record.item.payload.iframe_src }}</span>
      </template>
      <template #td:actions="{ record }">
        <div class="flex space-x-2">
          <router-link :to="{ name: 'banners.update', params: { id: record.item.id } }" class="btn btn-label-warning text-uppercase" :title="l.app.Edit">
            <i class="material-icons">edit</i>
          </router-link>
          <label @click="active( record.item )" class="btn btn-label-primary text-uppercase" :title="l.app.Active_Inactive">
            <i class="material-icons">done_all</i>
          </label>
          <label @click="deleteAction(record.item.id)" class="btn btn-label-danger text-uppercase" :title="l.app.Remove">
            <i class="material-icons"> delete</i>
          </label>
        </div>
      </template>
    </sim-table>

    <sim-pagination align="right" :data="banners" @change="searchData({ page: $event })"/>

    <!--    <sim-pagination align="right" :data="pagination" @change="searchData({ page: $event })" />-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Banners',
  data() {
    return {
      query: {
        limit: 15,
        page: 1
      }
    }
  },
  computed: {
    ...mapState({
      banners: state => state.banners.pagination,
      loading: state => state.banners.loadings
    }),
    ...mapGetters({
      l: 'lang'
    }),
    columns () {
      return [
        { title: 'id', key: "id" , sortable: true },
        { title: this.l.app.module, key: "module" , sortable: false },
        { title: this.l.app.types, key: "type" , sortable: true },
        { title: 'URL', slot: "url", sortable: false },
        // { title: 'url', key: 'payload', mutate: ({ key }) => key.type === 'image' ? key.payload.image_url : key.payload.iframe_url, sortable: false },
        { title: this.l.app.enabled, key: 'enabled', mutate: ({ item }) => ( item.enabled ? 'Enabled' : 'Disabled' ) , sortable: true },
        { title: this.l.app.Actions, slot: "actions", "alignment": "right" },
      ]
    }
  },
  methods: {
    ...mapActions({
      list: 'banners/index',
      update: 'banners/update',
      deleteBanner: 'banners/delete'
    }),
    active(item) {
      this.update({ id: item.id, enabled: !item.enabled })
        .then((res) => {
          if (res) {
            this.list(this.query)
          }
        })
    },
    deleteAction(id) {
      if (confirm(this.l.app.banners_messages.confirm_delete)) {
        this.deleteBanner(id)
          .then((res) => {
            if (res) {
              this.list(this.query)
            }
          })
      }
    },
    searchData(_query = {}){
      const query = this.$queryUtils.withoutInvalidValues({ ...this.query, ..._query})
      this.list(query)
          .then(() => {
            this.$router.replace({ query })
                .catch(() => null)
          })
    },
  },
  created() {
    this.query = {...this.query, ...this.$route.query, query: ''}
  },
  mounted() {
    this.list(this.query)
  }

}
</script>

<style scoped>

</style>