import MessageTemplate from './message-template'
import Vue from 'vue'
const MessageConstructor = Vue.extend(MessageTemplate)

const instances = []
let count = 1
const containers = {}

const Message = (options) => {
  let userOnClose = options.onClose
  const position = options.position || 'top-center'
  const hasMask = options.hasMask || false
  const containerKey = position + (hasMask ? '-mask' : '')
  let containerEl = containers[containerKey]

  if (!containerEl) {
    containerEl = containers[containerKey] = document.createElement('div')
    containerEl.className = [
      'm-message-container',
      'is-' + position,
      hasMask ? 'has-mask' : ''
    ].filter((e) => !!e).join(' ')
    document.body.appendChild(containerEl)
  }

  if (options.zIndex) {
    containerEl.style.zIndex = options.zIndex
  }

  const id = count++
  options.onClose = () => {
    Message.close(id, userOnClose)
  }

  const instance = new MessageConstructor({
    el: document.createElement('div'),
    data: options
  })

  instance.id = id
  instance.containerKey = containerKey

  if (position.indexOf('bottom') === 0 && containerEl.firstChild) {
    containerEl.insertBefore(instance.$el, containerEl.firstChild)
  } else {
    containerEl.appendChild(instance.$el)
  }

  instance.show = true

  instances.push(instance)

  return instance
}

Message.close = (id, userOnClose) => {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      const { containerKey, hasMask } = instances[i]

      if (typeof userOnClose === 'function') {
        userOnClose(instances[i])
      }
      instances[i] = null
      instances.splice(i, 1)

      const index = instances.findIndex((e) => e.containerKey === containerKey)

      if (hasMask && index === -1) {
        setTimeout(() => {
          containers[containerKey].remove()
          containers[containerKey] = null
        }, 300)
      }
      break
    }
  }

  setTimeout(() => {

    if (instances.length === 0) {
      for (let type in containers) {
        if (containers[type]) {
          containers[type].remove()
          containers[type] = null
        }
      }
    }
  }, 3000)
}

Message.closeAll = () => {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

const types = [ 'info', 'success', 'error', 'warning', 'loading' ]

types.forEach(type => {
  Message[type] = (options) => {
    options = typeof options === 'string' ? { message: options } : options
    return Message({ ...options, type })
  }
})

export default Message
