<template>
	<div>
		<div class="flex flex-wrap mb-2">
			<label @click="$router.back()" class="btn text-uppercase">
				<i class="material-icons">keyboard_backspace</i>
				{{ l.app.To_return }}
			</label>
		</div>
		<div class="flex flex-wrap">
			<div class="w-full">
				<sim-card>
					<div class="w-full">
						<div class="form-group">
							<label for="name">{{l.app.post_title}}</label>
							<sim-input
								id="title"
								:error="error('title', {attribute: 'title'})"
								v-model="newForo.title"
							/>
						</div>

						<div class="form-group">
							<label for="name">{{l.app.post_category}}</label>
							<v-select
								v-model="newForo.category_id"
								:options="categories"
								:source="categories"
							></v-select>
						</div>

						<div class="form-group">
							<label for="name">{{l.app.content_of_your_post}}</label>
							<vue-editor
								v-model="newForo.description"
								:editorToolbar="customToolbar"
							></vue-editor>
						</div>

						<sim-button class="mr-2" :loading="loading" @click="save()">
							<i class="material-icons">save</i>
							{{ l.app.Save_Changes }}
						</sim-button>

						<sim-button
							@click="$router.push('/forums')"
							outlined
							style="color: #e01f8b !important;"
						>
							<i class="material-icons">clear</i>
							{{ l.app.Cancel }}
						</sim-button>
					</div>
				</sim-card>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
	import moment from "moment";
	import {VueEditor} from "vue2-editor";
	import Vue from 'vue';
	
	export default {
		data: () => ({
			newForo: {
				title: "",
				category_id: "",
				description: "",
			},
			customToolbar: [
				["bold", "italic", "underline"],
				[{list: "ordered"}, {list: "bullet"}],
			],
			categories: [],
		}),
		computed: {
			...mapState({
				pagination: (state) => state.forums.pagination,
				loadings: (state) => state.forums.loadings,
				session: (state) => state.session.user,
			}),
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
		},
		components: {
			VueEditor,
		},
		methods: {
			...mapMutations({
				setNavbarTitle: "SET_NAVBAR_TITLE",
			}),
			...mapActions({
				index: 'forums/index',
				show: "forums/show",
				index_categories: "forums/index_categories",
				update: "forums/update",
				store: "forums/store",
			}),
			async save() {
				let res = await this.index({search: this.newForo.title.replace(" ", "+")})
				if (res && res.data && res.data.length && res.data.findIndex((item) => item.title == this.newForo.title) >= 0) {
					Vue.prototype.$message.error({
                        message: `A record with this name already exists!`,
                        position: `top-right`
                    })
				}else{
					if (this.newForo.hasOwnProperty("id")) {
						this.update({
							...this.newForo,
							category_id: this.newForo.category_id.value
						}).then(() => {
							this.$router.push("/forums");
						});
					} else {
						this.store({
							...this.newForo,
							category_id: this.newForo.category_id.value
						}).then(() => {
							this.$router.push("/forums");
						});
					}
				}
			},
			moment,
			Vue
		},
		async created() {
			let res = await this.index_categories();
			if (res && res.data) {
				this.categories = res.data.map((item)=>{
					return {
						label: item.name,
						value: item.id
					}
				});
			}
			console.log(res);
		},
		watch: {
			search(val) {
				if (val.length > 3) {
					this.searchData();
				}
			},
		},
	};
</script>
