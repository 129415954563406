<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <div class="row kt-pt25">
            <div class="col-12 col-sm-12">
                <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                    <div class="kt-portlet__body svg_list_trainig">
                        <form @submit.prevent="isUpdating ? updateData() : storeData()" @keydown="removeError($event.target.id)">
                            <h3>{{ isUpdating ? l.app.To_update : l.app.Create }}</h3>
                            <div class="flex flex-wrap -mx-2">
                                <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                    <div class="form-group" v-for="(input, key_input) in  form" :key="key_input">
                                        <template v-if="input.visibility">
                                                <label :for="input.atribute">{{input.label}}</label>
                                                <sim-input
                                                    v-if="input.type !== 'select' && 
                                                    input.type !== 'boolean' && 
                                                    input.type !== 'array'"
                                                    :id="input.atribute"
                                                    :type="input.type"
                                                    :error="error(input.atribute, { attribute: input.atribute })"
                                                    v-model="formData[input.atribute]"/>
                                                <template v-else-if="(input.type === 'select')">
                                                    <v-select  
                                                    v-model="selectData[input.atribute]"
                                                    :options="source[input.source]"></v-select>
                                                    <div class="feedback-zone">
                                                        <span>
                                                            <p style="color: #ff1100;" :class="[`sim-input-error-message`]" v-if="error(input.atribute, { attribute: input.atribute })">{{ error(input.atribute, { attribute: input.atribute }) }}</p>
                                                        </span>
                                                    </div>
                                                </template>
                                                <template v-else-if="(input.type === 'boolean')">
                                                    <sim-switch :value="formData[input.atribute]" v-model="formData[input.atribute]" />
                                                </template>
                                                <template v-else-if="(input.type === 'array')">
                                                    <div style="width: 100%;float: left;" v-for="(options, key_options) in  formData[input.atribute]" :key="`arry_${key_options}`">
                                                        <label :for="input.atribute">{{input.label}} {{key_options}}</label>
                                                        <sim-input
                                                            style="width: calc(100% - 60px);float: left;margin-right: 10px;"
                                                            :id="`${input.atribute}_${key_options}`"
                                                            v-model="formData[input.atribute][key_options]"/>
                                                        <sim-button 
                                                            style="float: left;width: 50px;margin: 0;"
                                                            class="mr-2" 
                                                            type="button" 
                                                            @click="formData[input.atribute].splice(key_options, 1)">
                                                            <i class="material-icons">delete</i>
                                                        </sim-button>
                                                    </div>
                                                    <sim-button class="mr-2" style="margin: 10px auto;float: right;" type="button" @click="formData[input.atribute].push('')">
                                                        <i class="material-icons">save</i>
                                                        add new option
                                                    </sim-button>
                                                </template>
                                        </template> 
                                    </div>
                                </div>
                                <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                    
                                </div>
                            </div>

                            <sim-button class="mr-2" type="submit" :loading="loading">
                                <i class="material-icons">save</i>
                                {{ isUpdating ? l.app.Update_changes : l.app.Save_Changes }}
                            </sim-button>

                            <sim-button outlined :to="{ name: 'fields' }">
                                <i class="material-icons">clear</i>
                                {{l.app.Cancel}}
                            </sim-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import 'vue-select/src/scss/vue-select.scss';
import vSelect from 'vue-select';

export default {
    data: () => ({
        picture: null,
        isUpdating: false,
        pictureV: '',
        selectData: {
            field_type: {},
        },
        source: {
            field_type:[
                {label: 'string', code: 'string'},
                {label: 'text', code: 'text'},
                {label: 'integer', code: 'integer'},
                {label: 'decimal', code: 'decimal'},
                {label: 'boolean', code: 'boolean'},
                {label: 'date', code: 'date'},
                {label: 'time', code: 'time'},
                {label: 'datetime', code: 'datetime'},
                {label: 'option', code: 'option'},
                {label: 'file', code: 'file'},
                {label: 'money', code: 'money'}
            ],
        },
        form:[
            {label: 'name', atribute: 'name', type: 'text', visibility: true},
        ],
        pictures:[],
        formData: {
            name: '',
            description: '',
            field_type: '',
            unique: true,
            required: true,
            read_only: true,
            min: 0,
            max: 0,
            options: []
        },
        calculatePoints:{
            type:null,
            category_id:null,
            price_range_id: null,
            brand_id:null,
        },
        loading: true
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        ...mapState({
            lang: state => state.lang,
            errorsS: state => state.errors,
            field: state => state.fields.field,
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            store: 'fields/store',
            removeError: 'removeError',
            fetchField: 'fields/fetchField',
            update: 'fields/update'
        }),

        addImgADD(img, picture) {
            picture.file = img;
        },
        addImgPV(img, picture) {
            picture.pictureV = img;
        },
        addArryPicture(){
            this.pictures.push({label: 'Primera Imagen', pictureV: '', file: null});
        },
        activeInput(){
            if(this.formData.brand_id === -1){
                this.form[10].visibility=true;
            }else{
                this.form[10].visibility=false;
                this.formData.other_brand= '';
            }
        },
        updateData(){
            this.update(this.formData);
        },
        storeData(){
            this.store(this.formData);
        },
    },
    watch: {
        'selectData.field_type'(val){
            //console.log( val);
            this.formData.field_type = val.code;
            let max = 0;
            switch (val.code) {
                case 'string':
                    max = 255;
                    break;
                case 'text':
                    max = 1024;
                    break;
                case 'integer':
                     max = 20000;
                    break;
                case 'decimal':
                    max = 20000;
                    break;
                case 'money':
                    max = 1000000;
                    break;
                default:
                    max= 1;
                    

                    break;
            }
            if(max === null){
                this.formData.min = null;
            } else {
                this.formData.min = 0;
               
            }

            this.form[6].visibility = true;
            this.form[7].visibility = true;
            
            if(val.code === 'option'){
                 this.form[8].visibility = true;
            } else {
                 this.form[8].visibility = false;
            }
            this.formData.max = max;
        },
    },
    components: {
        vSelect
    },
    async created() {
        let id = null;
        if(this.$route.name=== 'fields.create'){
            this.setNavbarTitle(['List of Fields' , this.l.app.Create])
        }else{
            this.isUpdating = true;
            this.setNavbarTitle(['List of Fields', this.l.app.To_update])
            id = this.$route.params.id;
            await this.fetchField(id);
            this.formData = {
                id: id,
                name: this.field.name,
                description: this.field.description,
                field_type: this.field.field_type,
                unique: this.field.unique,
                required: this.field.required,
                read_only: this.field.read_only,
                min: this.field.min,
                max: this.field.max,
                options: this.field.options
            }
            this.selectData.field_type = {
                label: this.field.field_type, 
                code: this.field.field_type
            }
        }
        this.form= [
                {label: 'field type', atribute: 'field_type', type: 'select', source: 'field_type', visibility: true},
                {label: 'name', atribute: 'name', type: 'text', visibility: true},
                {label: 'description', atribute: 'description', type: 'text', visibility: true},
                {label: 'unique', atribute: 'unique', type: 'boolean', visibility: true},
                {label: 'required', atribute: 'required', type: 'boolean', visibility: true},
                {label: 'read only', atribute: 'read_only', type: 'boolean', visibility: true},
                {label: 'min', atribute: 'min', type: 'number', visibility: true},
                {label: 'max', atribute: 'max', type: 'number', visibility: true},
                {label: 'options ', atribute: 'options', type: 'array', visibility: false}
            ]

        this.loading= false;
    }
}
</script>

<style lang="scss">
.conte_img{
    display: table;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}
.add_img{
    border: 2px dashed rgb(235, 237, 242) !important;
    width: 180px;
    height: 250px;
    display: flex;
    -webkit-box-align: center;
    cursor: pointer;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    i {
        color: #ebedf2;
        font-size: 70px;
    }

    &:hover {
        i {
        color: #374afb;
        }
    }
}
.deleteIcon {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 1;
}
</style>