<template>
    <div :class="[`${prefix}-checkbox`]">
        <label :for="_uid">
            <div :class="[`${prefix}-checkbox-square`]" :style="{ border: `2px solid ${color}`, color: color, opacity: value ? 1 : 0.5 }">
                <transition :name="transition">
                    <slot name="icon" v-if="value">
                        <fa-icon icon="check" :color="value ? color : '#000'"/>
                    </slot>
                </transition>
            </div>
            <div style="margin-left: 5px;">
                <slot />
            </div>
            <input type="checkbox" style="display: none" :checked="checked" :id="_uid" :value="value" @input="emit">
        </label>
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `checkbox`,
    props: {
        checked: { required: false, type: Boolean, default: false },
        color: { required: false, type: String, default: '#006CFF' },
        transition: { required: false, type: String, default: 'scale' },
        value: { required: false, type: Boolean },
        name: { required: false, type: String, default: null }
    },
    data: () => ({
        prefix,
    }),
    methods: {
        emit() {
            this.$emit('input', !this.value)
        }
    }
}
</script>