/**
 * This is the main router configuracion,
 * this handles every hook of the vue-router environment.
 */

// import moment from 'moment'
// import { Role, Routes } from '../constants'
// const { loginURL, homeURL } = Routes
// import { refreshToken } from '@/api'
// import { getCookie } from '@/helpers/storage'

const nextFactory = (context, middleware, index) => {
    const subsequentMiddleware = middleware[index]
    if (!subsequentMiddleware) {
        return context.next
    }

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middleware, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }
}

export default ({ router, store }) => {
    router.beforeEach((to, from, next) => {
        store.dispatch('vanishErrors')
        store.dispatch('cancelPendingRequest');
        
        document.title = `${to.meta?.title?.({ router, store, lang: store.getters.lang }) ?? 'App'} - Playpen`
        
        if (to.meta?.middleware) {
            const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
            const context = { to, from, next, router, store }
            const nextMiddleware = nextFactory(context, middleware, 1)
            return middleware[0]({...context, next: nextMiddleware})
        }

        return next()
    })
}

/**
 * implemented at ~/main.js
 */
// export default ({ router, store }) => {
//     router.beforeEach((to, from, next) => {
//         store.dispatch('vanishErrors')
//         // document.title = (lang.browser.title[to.meta.title] || '').replace('#match#', to.params.id || '') || lang.browser.title.default

//         const isAuthenticated = store.getters['session/isAuthenticated']
//         const user = store.state.session.user
//         let baseURL = homeURL

//         /**
//          * defined at ~/router.js
//          */
//         const { requiresAuthentication, allowedFor } = to.meta
//         if (allowedFor.includes(Role.none)) return next(baseURL)
//         if (isAuthenticated && user) {

//             /**
//              * this block verifies everytime the route changes if the current date
//              * is between 10 minutes with the expiration date to refresh the token
//              */
//             /**
//              * Every route must have at least one rule on the meta
//              * attribute to avoid this behavior, this validation
//              * exists to avoid client navigation to unexisting
//              * urls and the execution of the remaining code
//              */
//             if (!Object.keys(to.meta).length) return next(baseURL)

//             if (to.path === '/') return next(baseURL)

//             /**
//              * if the user is logged, it cant go to /login, will be redirected to
//              * [/dashboard, /watchs] based on its type
//              */
//             if (to.path === loginURL) return next(baseURL)

//             /**
//              * if the route accepts any (5) type of Role to be accesed,
//              * then will allow the user to go forward
//              */
//             if (allowedFor.includes(Role.all)) return next()

//             /**
//              * if neither of the logged user cases above executes then we
//              * validates if the route allowed access contains the type of the user
//              * to allow the access
//              */
//             //if (allowedFor.includes(user.type)) return next()

//             if (from.path == loginURL) {
//                 return next(baseURL)
//             }

//             /**
//              * at this point the user does not have access to the incoming route
//              * so we notifies the user that does not have access and will be redirected
//              * to its previous route or [/dashboard, /watchs] based on its type
//              */
//             // message.info(`No tienes permiso para acceder a esta ruta`)

//             return next(from.path)
//         } else {
//             if (requiresAuthentication) return next(loginURL)
//         }
//         next()
//     })
// }
