import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        faq: {},
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_FAQ: (state, pagination) => state.pagination = pagination,
        SET_FAQ: (state, faq) => state.faq = faq,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        fetchFaqs({ commit }, page = {} ) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.faqs
                .index(page)
                .then(({ data }) => {
                    commit('SET_PAGINATED_FAQ', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        fetchFaq({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.faqs
                .show(id)
                .then(({ data }) => {
                    commit('SET_FAQ', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, body) {
            commit('TOGGLE_LOADING', 'store')
            return this.$repository.faqs
                .store(body)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Successful registration`,
                        text: `Successful registration`,
                        description: `Successful registration`,
                        position: `top-right`
                    })
                    
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        },
        update({ commit }, body) {
            commit('TOGGLE_LOADING', 'update')
            return this.$repository.faqs
                .update(body.id, body)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Successful update`,
                        text: `Successful update`,
                        description: `Successful update`,
                        position: `top-right`
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        delete({ commit, state }, { id }) {
            if (confirm('¿Do you want to delete this?')) {
                commit('TOGGLE_LOADING', 'delete')
                return this.$repository.faqs
                    .delete(id)
                    .then(({ data }) => {
                        const index = state.pagination.data.findIndex(administrator => administrator.id === id)
                        if (index !== -1) {
                            commit('DROP_ADMINISTRATOR', index)
                        }

                        return data
                    })
                    .finally(() => commit('TOGGLE_LOADING', 'delete'))
            }
        }
    },
    getters: {
    }
}