<template>
  <div class="c-p">
    <div class="bg-white rounded-lg p-2">
      <p class="text-2xl" style="margin-bottom: 20px;">
        Assign questions to your sector
      </p>
      <div class="flex flex-col space-y-4">
        <div>
          <label for="name" class="block">Nombre</label>
          <sim-input v-model="sector.name" class="mt-2" id="name" />
        </div>
        <div>
          <label for="description" class="block">Descripción</label>
          <sim-input
            v-model="sector.description"
            class="mt-2"
            id="description"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -mx-2 mt-4">
      <div class="w-full md:w-1/2 px-2">
        <div class="bg-white rounded-lg p-2">
          <p class="text-2xl">Form fields</p>
        </div>
        <sim-table
          :loading="loading"
          :columns="columns"
          :source="fieldsPagination.data"
        >
          <template #td:tags="{ }">
            x
          </template>
          <template #td:actions="{ record: { item }  }">
            <div class="flex space-x-2">
              <input type="checkbox" @input="isFieldsSelected(item)" />
            </div>
          </template>
        </sim-table>
        <sim-pagination
          :data="fieldsPagination"
        />
				<!-- @change="fetchFields({page: $event})" -->
      </div>
      <div class="w-full md:w-1/2 px-2">
        <div class="bg-white rounded-lg">
          <p class="text-2xl p-2">Preview</p>
          <ul class="divide-y rounded-2xl border-t">
            <li
              v-for="({field, field_id, position}, key) in sector.sector_fields"
              :key="field_id"
              class="p-4"
            >
              <b class="text-lg">
                id: {{ field_id }} [{{ key + 1 }}] {{ position + 1 }})
                {{ field.name }}</b
              >
              <small class="text-gray-600 block">{{ field.description }}</small>
              <div class="flex mt-2 rounded-lg border">
                <button
                  class="flex-grow p-2 hover:bg-blue-200"
                  @click="moveUp(sector.sector_fields[key], key)"
                  >Subir</button
                >
                <button
                  class="flex-grow p-2 text-red-600 hover:bg-red-600 hover:text-white"
                  @click="remove(key)"
                  >Eliminar</button
                >
                <button
                  class="flex-grow p-2 hover:bg-blue-200"
                  @click="moveDown(sector.sector_fields[key], key)"
                  >Bajar</button
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sub-nav">
      <sim-button @click="save()" class="mr-2" :disabled="loading" :loading="loading">
        <i class="material-icons">save</i>
        {{ isUpdating ? "Update changes" : "Save Changes" }}
      </sim-button>
			<sim-button
				@click="$router.push('/sectors')"
				outlined
				style="color: #e01f8b !important;"
			>
				<i class="material-icons">clear</i>
				{{ l.app.Cancel }}
			</sim-button>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from "vuex";
  import Vue from 'vue';

  export default {
    data: () => ({
      isUpdating: false,
      sector: {
        name: "",
        description: "",
        sector_fields: [],
      },
      query: {
        limit: 10,
        page: 1,
      },
    }),
    computed: {
      ...mapState({
        fieldsPagination: (state) => state.fields.pagination,
        loading: (state) => state.fields.loadings.index,
        session: (state) => state.session.user,
      }),
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
      columns() {
        return [
          {title: "name", key: "name"},
          {title: "field_type", key: "field_type"},
          {title: "Actions", slot: "actions", alignment: "right"},
        ];
      },
    },
    methods: {
      ...mapActions({
				index: "sectors/index",
        fetch: "sectors/show",
        fetchFields: "fields/fetchFields",
        store: "sectors/store",
        update: "sectors/update",
      }),
      isFieldsSelected(field) {
        const foundIndex = this.sector.sector_fields.findIndex(
          ($0) => $0.field_id === field.id
        );
        let position = this.sector.sector_fields.length;
        foundIndex >= 0
          ? this.sector.sector_fields.splice(foundIndex, 1)
          : this.sector.sector_fields.push({
              field_id: field.id,
              position: position,
              field: field,
            });
      },
      remove(index) {
        this.sector.sector_fields.splice(index, 1);
      },
      moveUp(field, index) {
        const currentItemAtPosition = this.sector.sector_fields[index - 1];
        this.sector.sector_fields.splice(
          index - 1,
          2,
          field,
          currentItemAtPosition
        );
      },
      moveDown(field, index) {
        const currentItemAtPosition = this.sector.sector_fields[index + 1];
        this.sector.sector_fields.splice(
          index,
          2,
          currentItemAtPosition,
          field
        );
      },
      getComponent({field_type, options}) {
        const type = ["text", "string"].includes(field_type)
          ? "text"
          : field_type === "date"
          ? "date"
          : "number";
        console.log(field_type);

        // 'string'
        // 'text'
        // 'integer'
        // 'decimal'
        // 'boolean'
        // 'date'
        // 'time'
        // 'datetime'
        // 'option'
        // 'file'
        // 'money'

        switch (field_type) {
          case "text":
          case "string":
          case "date":
          case "number":
          case "integer":
            return {component: "sim-input", type};
          case "datetime":
          case "boolean":
            return {component: "sim-switch"};
          case "option":
            return {
              component: "sim-select",
              options: options.map(($0) => ({label: $0, value: $0})),
            };
        }
      },
      async save() {
				let res = await this.index({search: this.sector.name.replace(" ", "+")});
				if (
					res &&
					res.data &&
					res.data.length &&
					res.data.findIndex((item) => item.name == this.sector.name && item.id != this.sector.id) >= 0
				) {
					Vue.prototype.$message.error({
            message: `A record with this name already exists!`,
            position: `top-right`
          })
				} else {
					this.data = {
						name: this.sector.name,
						description: this.sector.description,
						fields: [],
					};

					if (this.sector && this.sector.sector_fields) {
						this.sector.sector_fields.forEach((element, x) => {
							this.data.fields.push({
								id: element.field_id,
								position: x + 1,
							});
						});
					}

					if (!this.sector.hasOwnProperty("id")) {
						this.store(this.data).then(() => {
							this.$router.push('/sectors')
						});
					} else {
						this.data.id = this.sector.id;
						this.update(this.data).then(() => {
							this.$router.push('/sectors')
						});
					}
				}
      },
      Vue
    },
    mounted() {
      if (this.$route.name === "sectors.edit") {
        this.fetch(this.$route.params.id).then(
          (sector) => (this.sector = sector)
        );
      }
      // this.fetchFields(this.query);
    },
  };
</script>

<style lang="scss">
  .sub-nav {
    position: fixed;
    width: calc(100% - 260px);
    top: 69px;
    right: 0;
    background: #fff;
    padding: 20px;
  }
  .c-p {
    margin-top: 79px;
  }
</style>
