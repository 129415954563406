<template>
    <li :class="['kt-menu__item', !to.name && 'kt-menu__item--submenu', (!to.name && isOpen) &&'kt-menu__item--open']">
        <template v-if="to.name !== null">
        <component active-class="bg-black bg-opacity-30" :is="to ? 'router-link' : 'span'" :to="to" :class="['kt-menu__link kt-menu__toggle',`${prefix}-sidebar-item-label`, to ? 'is-link' : 'is-menu']" @click="to ? () => {} : toggleOpen($event)">
            <slot name="icon"></slot>
            <span class="kt-menu__link-text">
                <slot name="label"></slot>
            </span>
        </component>
        </template>
        <template v-else>
            <component :is="'span'" :class="['kt-menu__link kt-menu__toggle',`${prefix}-sidebar-item-label`, 'is-menu']" @click="toggleOpen($event)">
                <slot name="icon"></slot>
                <span class="kt-menu__link-text">
                    <slot name="label"></slot>
                </span>
                <i v-if="hasSubMenu && !isOpen" class="kt-menu__ver-arrow material-icons">keyboard_arrow_down</i>
                <i v-if="hasSubMenu && isOpen" class="kt-menu__ver-arrow material-icons">keyboard_arrow_up</i>
            </component>
        </template>

        <div v-if="hasSubMenu && isOpen" class="kt-menu__submenu ">
            <span class="kt-menu__arrow"></span>
            <ul class="kt-menu__subnav">
                <slot name="menu" />
            </ul>
        </div>
    </li>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `sidebar-item`,
    props: {
        to: { required: false, type: [String, Object], default: null },
        label: { required: false, type: String, default: '' },
        icon: { required: false, type: String, default: '' },
        disabled: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix,
        isOpen: false
    }),
    computed: {
        hasSubMenu() {
            return this.$slots.hasOwnProperty('menu')
        }
    },
    methods: {
        toggleOpen(event) {
            // this.isOpen = !this.isOpen
            if (this.$parent.accordion) {
                for(let child of this.$parent.$children) {
                    if (child.isOpen && child !== this) {
                        child.isOpen = false
                    }
                }
            }

            this.isOpen = !this.isOpen;

            this.$emit('click', event)
        }
    }
}
</script>