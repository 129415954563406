import { Authenticated } from '../middlewares'

export default [
    {
        path: '/events',
        name: 'events.index',
        component: () => import('../../views/events/index.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/events/create',
        name: 'events.create',
        component: () => import('../../views/events/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'events.index' }), label: ({ lang }) => lang.app.List_of_events, loading: () => false },
                { route: () => ({ name: 'events.create' }), label: ({ lang }) => lang.app.Create, loading: () => false }
            ]
        },
    },
    {
        path: '/events/:id/edit',
        name: 'events.edit',
        component: () => import(/* webpackChunkName: "users.show" */ '../../views/events/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'events.index' }), label: ({ lang }) => lang.app.List_of_events, loading: () => false },
                { route: () => ({ name: 'events.create' }), label: ({ lang }) => lang.app.Edit, loading: () => false }
            ]
        },
    },
]