import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		pagination: { data: [], current_page: 1, total: 0 },
		current: {},
		loadings: { index: false, show: false, store: false, update: false, delete: false,publish:false }
	},
	mutations: {
		SET_PAGINATED_POSTS: (state, pagination) => state.pagination = pagination,
		SET_SECTORS: (state, current) => state.current = current,
		TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
		DROP_ITEM: (state, index) => state.pagination.data.splice(index, 1),
	},
	actions: {
		index({ commit }, page = {}) {
			commit('TOGGLE_LOADING', 'index')
			return this.$repository.posts
				.index(page)
				.then((res) => {
					console.log("SET_PAGINATED_POSTS", res.data)
					commit('SET_PAGINATED_POSTS', res.data)
					return res.data
				})
				.finally(() => commit('TOGGLE_LOADING', 'index'))
		},
		show({ commit }, id) {
			commit('TOGGLE_LOADING', 'show')
			return this.$repository.posts
				.show(id)
				.then(({ data }) => {
					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'show'))
		},
		store({ commit }, body) {
			commit('TOGGLE_LOADING', 'store')
			return this.$repository.posts
				.store(body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful registration`,
						text: `Successful registration`,
						description: `Successful registration`,
						position: `top-right`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'store'))
		},
		update({ commit }, body) {
			commit('TOGGLE_LOADING', 'store')
			return this.$repository.posts
				.update(body.id, body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful update`,
						text: `Successful update`,
						description: `Successful update`,
						position: `top-right`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'store'))
		},
		publish({ commit }, id) {
			if (confirm('¿Do you want to publish this?')) {
				commit('TOGGLE_LOADING', 'publish')
				return this.$repository.posts
					.publish(id)
					.then(({ data }) => {
						Vue.prototype.$message.success({
							message: `Successful publish`,
							text: `Successful publish`,
							description: `Successful publish`,
							position: `top-right`
						})
						return data
					})
					.finally(() => commit('TOGGLE_LOADING', 'publish'))
			}
		},
		delete({ state, commit },  id) {
			if (confirm('¿Do you want to delete this?')) {
				commit('TOGGLE_LOADING', 'delete')
				return this.$repository.posts
					.delete(id)
					.then(({ data }) => {
						const index = state.pagination.data.findIndex(item => item.id === id)
						if (index !== -1) {
							commit('DROP_ITEM', index)
						}
						return data
					})
					.finally(() => commit('TOGGLE_LOADING', 'delete'))

			}
		}
	},
	getters: {
	}
}