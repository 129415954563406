<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <form @submit.prevent="update(user)" @keydown="removeError($event.target.id)" v-if="user">
            <sim-card>
                <h3>{{this.l.app.users}}</h3>
                <div class="w-full md:w-1/2 lg:w-1/2 px-2" v-for="(d, key) in  Object.keys(user)" :key="key">
                    <template>
                        <div class="form-group">
                            <label for="name" :style="`text-transform: capitalize`">{{l.app[d]}}</label>
                            <sim-input
                                :id="d"
                                :type="d != 'work_email' ? showPassword ? 'text' : 'password' : 'text'"
                                v-model="user[d]">

                              <template #suffix-icon>
                                <fa-icon
                                    v-if="d !== 'work_email'" :icon="showPassword ? 'eye-slash' : 'eye'"
                                    @click="showPassword = !showPassword"
                                    color="#4D4F5C"/>
                              </template>
                            </sim-input>
                        </div>
                    </template>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                    <sim-button class="mr-2" @click="store()">
                        <i class="material-icons"></i>
                        {{l.app.Save_Changes}}
                    </sim-button>
                    <sim-button outlined @click="$router.push({ path: '/users' })" style="color: #e01f8b !important;">
                        <i class="material-icons"></i>
                        {{l.app.Cancel}}
                    </sim-button>
                </div>
            </sim-card>
        </form>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue';

export default {
    data: () => ({
        picture: null,
        isUpdating: false,
        biography:'',
        form:[],
        user: {
            work_email: '',
            password: '',
            confirm_password: ''
        },
        showPassword: false,
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
    },
    components: {},
    methods: {
        ...mapActions({
            removeError: 'removeError',
            create: 'users/create'
        }),
        handlePhotoSelection(event) {
            const reader = new FileReader()
            reader.onload = () => {
                this.user.profile_picture = event.target.files[0]
                this.picture = reader.result
            }
            reader.readAsDataURL(event.target.files[0])
        },
        dropPhoto() {
            this.user.profile_picture = null
            this.picture = null
            document.querySelector('#file_input').value = null
        },
        handlePhotoUpdate() {
            if (this.picture) {
                this.updateProfilePicture(this.user.profile_picture)
            }
        },
        store(){
            if(this.regexEmail(this.user.work_email) === false){
                Vue.prototype.$message.error({
                    message: `Invalid email`,
                    position: `top-right`
                })
                return;
            }
            if(this.user.password.length < 8){
                Vue.prototype.$message.error({
                    message: `the password must have at least 8 characters`,
                    position: `top-right`
                })
                return;
            }
            if(this.user.password !== this.user.confirm_password){
                Vue.prototype.$message.error({
                    message: `passwords do not match`,
                    position: `top-right`
                })
                return;
            }
            this.create({ 
                email:this.user.work_email,
                password:this.user.password
            }).then((data)=>{
                console.log("data",data)
                return this.$router.push(`/users`)
            });
            
        },
        regexEmail(email){
            const regexp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let src=email;
            //console.log(regexp.test(src))
            return regexp.test(src)
        },
        remove_featured(){
            //this.update({ id: this.user.profile.id,is_featured: false})
        },
        Vue
    },
    created() {
       this.columns= [
            {label: this.l.app.Email, atribute: 'email', type: 'email', visibility: true},
            {label: this.l.app.password, atribute: 'password', type: 'password', visibility: true},
            {label: this.l.app.confirm_password, atribute: 'confirm_password', type: 'password', visibility: true},
        ]
    }
}
</script>
