<template>
    <div :class="[`${prefix}-divider`, { 'is-vertical': vertical }]"></div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `divider`,
    props: {
        vertical: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix
    })
}
</script>

<style lang="scss" scoped>
$prefix: idc;
.#{$prefix}-divider {
    width: 2px;
    margin: 6px 0;
    background: blue;
    display: block;
}
</style>