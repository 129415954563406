import { Authenticated } from '../middlewares'

export default [
    {
        path: '/banners',
        name: 'banners.index',
        component: () => import('../../views/banners/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'banners.index' }), label: ({ lang }) => lang.app.list_of_banners, loading: () => false }
            ]
        }
    },
    {
        path: '/banners/create',
        name: 'banners.create',
        component: () => import('../../views/banners/manage.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'banners.index' }), label: ({ lang }) => lang.app.list_of_banners, loading: () => false },
                { route: () => ({ name: 'banners.create' }), label: ({ lang }) => lang.app.create_banner, loading: () => false }
            ]
        }
    },
    {
        path: '/banners/:id/update',
        name: 'banners.update',
        component: () => import('../../views/banners/manage.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'banners.index' }), label: ({ lang }) => lang.app.list_of_banners, loading: () => false },
                { route: () => ({ name: 'banners.create' }), label: ({ lang }) => lang.app.update_banner, loading: () => false }
            ]
        }
    }
]