<template>
	<div class="row kt-pt25">
		<div class="col-12 col-sm-12">
			<div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
				<div class="kt-portlet__body svg_list_trainig">
					<div class="row kt-mb-25">
						<div class="col-12 col-sm-7">
							<div class="kt-input-icon kt-input-icon--left col-sm-5">
								<input
									type="text"
									v-model="search"
									v-debounce:400="(search) => searchData({search})"
									class="form-control"
									placeholder="Search..."
								/>
								<span
									@click="searchData"
									class="kt-input-icon__icon kt-input-icon__icon--left"
									style="cursor: pointer;"
								>
									<span> <i class="material-icons">search</i> </span>
								</span>
							</div>
						</div>
						<div class="col-12 col-sm-5" style="text-align: right;">
							<select
								class="mr-2 py-3 px-2 appearance-none font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
								@input="orderBy($event.target.value)">
								<option value="">-- {{ this.l.app.all}} --</option>
								<option value="lower:title,desc">{{ this.l.app.Title}},Desc</option>
								<option value="lower:title,asc">{{ this.l.app.Title}},Asc</option>
								<!--option value="created_at,desc">{{ this.l.app.created_at}},Desc</option>
								<option value="created_at,asc">{{ this.l.app.created_at}},Asc</option-->
								<option value="updated_at,desc">{{ this.l.app.published_at}},Desc</option>
								<option value="updated_at,asc">{{ this.l.app.published_at}},Asc</option>
							</select>
							

							<sim-button
								align="right"
								:to="{name: 'blog.create'}">
								<i class="material-icons"> add</i>
								{{ this.l.app.Create}}
							</sim-button>
						</div>
						
					</div>
					<!-- aqui va la tabla -->
					<template v-if="loading.index == false">
						<div class="row kt-mb-25">
							<div
								class="col-md-3 col-sm-12"
								v-for="item in pagination.data"
								:key="item.id"
								style="padding: 10px;"
							>
								<div class="box_blog_page" style="position: relative;">
									<div style="position: absolute;bottom: 10px;z-index: 8;right: 10px;font-size: 12px;">
										<button v-if="item.status === 'pending'" class="btn btn-label-primary" @click="publish(item.id)" title="Publish">
											<i class="material-icons">done_all</i>
										</button>
										<button class="btn btn-label-danger" @click="deleteB(item.id)" :title="l.app.Remove">
											<i class="material-icons"> delete</i>
										</button>
									</div>
									<router-link
										:to="`/blog/${item.id}`"
										style="float: left; width: 100%;"
									>
										<div
											class="col-12 figure-img"
											:style="
												`background-image: url(${
													item.featured_image_url
														? item.featured_image_url
														: '/img/defaultPostImage.png'
												})`
											"
										>
										</div>
										<div class="col-12 footer" style="float: left;">
											<div class="col-12 title">
												{{ item.title }}
											</div>
											<div class="col-12 row">
												<template>
													<span
														v-if="item && item.company !== null"
														class="tap col-12"
														style="font-weight: bold;text-transform: capitalize;"
														>{{ item.company.name }}</span>
													<span
														v-else
														class="tap col-12"
														style="font-weight: bold;text-transform: capitalize;"
														>{{ item.user_profile.name }}
														{{ item.user_profile.last_name }}</span
													>
												</template>

												<span
													class="date col-12"
													style="line-height: 24px; font-size: 12px;"
												>
													Posted on
													{{
														moment(item.updated_at).format(
															"MMMM DD, YYYY hh:mm a"
														)
													}}
												</span>
											</div>
											<div class="col-12 row">
												<div class="col-9 ">
													<div
														v-if="item.views_count > 0"
														class="col-12"
														style="padding-left: 20px; position: relative;min-height: 24px;"
													>
														<span
															class="material-icons"
															style="line-height: 24px; font-size: 17px; position: absolute; left: 0px; top: 0px;"
															>visibility</span
														>
														<span style="line-height: 24px; font-size: 12px;"
															>{{ item.views_count }} Views</span
														>
													</div>
													<div
														v-else
														class="col-12"
														style="padding-left: 20px; position: relative;min-height: 24px;"
													>
													</div>

													<div
														v-if="item.comments_count > 0"
														class="col-12"
														style="padding-left: 20px; position: relative;min-height: 24px;"
													>
														<span
															class="material-icons"
															style="line-height: 24px; font-size: 17px; position: absolute; left: 0px; top: 0px;"
															>comment</span
														>
														<span style="line-height: 24px; font-size: 12px;"
															>{{ item.comments_count }} Comments</span
														>
													</div>
													<div
														v-else
														class="col-12"
														style="padding-left: 20px; position: relative;min-height: 24px;"
													>
													</div>
												</div>
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
						<template v-if="pagination && pagination.data && pagination.data.length">
							<sim-pagination :data="pagination" @change="changePage($event)" />
						</template>
					</template>
					<p v-else class="text-2xl text-center pt-16 p-8 animate-bounce">{{l.app.loading}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
	import moment from "moment";

	export default {
		data: () => ({
			columns: [],
			limit: 10,
			o_data: {},
			search: "",
			query: {
				limit: 10,
				page: 1,
				with: "userProfile,company",
			},
		}),
		computed: {
			...mapState({
				pagination: (state) => state.post.pagination,
				loading: (state) => state.post.loadings,
				session: (state) => state.session.user,
			}),
			...mapGetters({
				l: "lang",
			}),
		},
		methods: {
			...mapMutations({
				setNavbarTitle: "SET_NAVBAR_TITLE",
			}),
			...mapActions({
				index: "post/index",
				deleteB: "post/delete",
				publishBlog: "post/publish",
			}),
			/*searchData(_query = {}){
				const query = this.$queryUtils.withoutInvalidValues({...this.query, ..._query})
				this.index(query);
			},
			changePage(page) {
				this.query.page = page;
			},
			orderBy(data) {
				console.log("orderBy",data)
				if (this.search !== "") {
					this.index({
						search: this.search,
						with:`userProfile,company`,
						order_by: `${data}`,
						page: 1,
					});
				} else {
					this.index({
						with:`userProfile,company`,
						order_by: `${data}`,
						page: 1,
					});
				}
			},*/

			searchData() {
				const query = this.$queryUtils.withoutInvalidValues({ ...this.query, search: this.search,page: 1});
				this.query = query;
				this.index(query);
			},
			changePage(page) {
				const query = this.$queryUtils.withoutInvalidValues({ ...this.query, page: page});
				this.query = query;
				this.index(query);
				//console.log("this.query",this.query,page)
				//this.query.page = page;
			},
			orderBy(_query) {
				const query = this.$queryUtils.withoutInvalidValues({ ...this.query, order_by:_query,page: 1});
				this.query = query;
				this.index(query);
			},
			active(id) {
				if (confirm("Are you sure to activate this Events?")) {
					this.update({id: id, status: "active"});
				}
			},
			deactivate(id) {
				if (confirm("Are you sure to disable this Events?")) {
					this.update({id: id, status: "inactive"});
				}
			},
			
			async publish(id){
				let response = await this.publishBlog(id);
				if (response) {
					console.log("response",response)
					let index = this.pagination.data.findIndex((item) => item.id == id);
					if (index >= 0) {
						this.pagination.data[index].status = response.status;
					}
				}
			},
			moment,
		},
		mounted() {
			this.index(this.query);
		},
		created() {
			this.setNavbarTitle(["List of Events"]);
			this.columns = this.l.list.columns_event;
		},
		// watch:{
		//     search (val) {
		//         if (val.length > 3 ) {
		//             this.searchData()
		//         }
		//     }
		// }
	};
</script>
