import axios from 'axios'

const withoutInvalidValues = body => {
    Object.entries(body)
        .forEach($0 => {
            const value = $0[1], attribute = $0[0]

            if (value === '' || value === undefined || value === null) {
                delete body[$0[0]]

            } else if (typeof value === 'object') {

                if (!Array.isArray(value)) {
                    withoutInvalidValues(value)
                } else {
                    if (!value.length) {
                        delete body[attribute]
                    }
                }
            }
        })
    return body
}

const toFormData = body => {
    let data = new FormData()
    Object.entries(body)
        .forEach($0 => {
            if (Array.isArray($0[1])) return $0[1].forEach(($1, key) => data.append(`${$0[0]}[${key}]`, $1))

            if ($0[1] === true) return data.append($0[0], 1)

            if ($0[1] === false) return data.append($0[0], 0)

            // if (Object.isObject($0[1])) return toFormData($0[1]) // EXPERIMENTAL

            data.append($0[0], $0[1])
        })
    
    return data
}

const mapQueryString = filters => {
    return Object.entries(filters)
        .filter($0 => $0[1] !== '')
        .map(($0, index) => {
            return `${index === 0 ? '?' : '&'}${$0[0]}=${$0[1]}`
                .replaceAll(/\s+/gm, '') // removes all new lines, tabs and spaces
                .trim()

        }).join('')
}

const getToken = () => localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_STORE_KEY)

const files = require.context('./repository', true, /\.js$/i)
const repositories = files.keys() // gets all directory urls
    .filter($0 => $0 !== './index.js') // exclude this file from the list of urls
    .map($0 => ({ file: files($0).default, info: $0 })) // executes and read each file
    .filter($0 => typeof $0.file === 'function') // verifies that the file exports a function
    .reduce(($0, $1) => {
        return {
            ...$0,
            [$1.info.split('.')[1].replace('/', '')]: $1.file({
                axios,
                mapQueryString,
                withoutInvalidValues,
                toFormData,
                getToken
            })
        }
    }, {})

export const Repository = {
    install: (Vue, options = {}) => {
        Vue.prototype.$repository = repositories
        Vue.prototype.$queryUtils = {
            mapQueryString,
            withoutInvalidValues
        }
  
        if (options.store) {
            options.store.$repository = repositories
        }
    }
}

export default repositories