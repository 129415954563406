<template>
    <div :class="[`${prefix}-dropdown-item`]" @click="$emit('click', $event)">
        <component :is="to ? 'router-link' : 'span'" :to="to" :class="['kt-nav__link', to ? 'is-link' : 'is-menu']">
            <span class="kt-nav__link-icon">
                <slot name="icon"></slot>
            </span>
            <span class="kt-nav__link-text">
                <slot name="label"></slot>
            </span>
        </component>
    </div>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `dropdown-item`,
    props: {
        label: { required: false, type: String, default: '' },
        icon: { required: false, type: String },
        to: { required: false, type: [String, Object], default: null }
    },
    data: () => ({
        prefix
    }),
}
</script>