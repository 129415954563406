import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        event: {},
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_TENDERS: (state, pagination) => state.pagination = pagination,
        SET_SECTORS: (state, event) => state.event = event,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        fetchTenders({ commit }, page = {} ) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.tenders
                .index(page)
                .then(res => {
                    commit('SET_PAGINATED_TENDERS', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        fetchTender({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.tenders
                .show(id)
                .then(res => {
                    commit('SET_SECTORS', res.data)
                    return res
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, body) {
            commit('TOGGLE_LOADING', 'store')
            return this.$repository.tenders
                .store(body)
                .then(res => {
                    Vue.prototype.$message.success({
                        message: `Successful registration`,
                        text: `Successful registration`,
                        description: `Successful registration`,
                        position: `top-right`
                    })
                    return res  
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        },
        update({ commit }, body) {
            commit('TOGGLE_LOADING', 'update')
            return this.$repository.tenders
                .update(body.id, body)
                .then(res => {
                    Vue.prototype.$message.success({
                        message: `Successful update`,
                        text: `Successful update`,
                        description: `Successful update`,
                        position: `top-right`
                    })

                    return res
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        delete({ commit, state }, { id }) {
            if (confirm('¿Do you want to delete this?')) {
                commit('TOGGLE_LOADING', 'delete')
                return this.$repository.tenders
                    .delete(id)
                    .then(res => {
                        const index = state.pagination.data.findIndex(administrator => administrator.id === id)
                        if (index !== -1) {
                            commit('DROP_ADMINISTRATOR', index)
                        }

                        return res
                    })
                    .finally(() => commit('TOGGLE_LOADING', 'delete'))
            }
        }
    },
    getters: {
    }
}