import { Authenticated } from '../middlewares'

export default [
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "soon" */ '../../views/soon.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "soon" */ '../../views/dashboard/index.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/settings-all-app',
        name: 'SettingsAllApp',
        component: () => import(/* webpackChunkName: "soon" */ '../../views/settingsApp/index.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../../views/users/profile.vue'),
        meta: {
            middleware: Authenticated
        },
    },
]
