<template>
	<div class="row col-12">
		<template v-if="!loadings.c_index">
			<div
				v-for="c in pagination.data"
				:key="c.id"
				class="lis row col-12"
				style="margin: 0px;"
			>
				<div class="col-10">
					<sim-input id="title" v-model="c.name" />
				</div>
				<div class="col-2">
					<label
						for=""
						class="btn btn-label-warning text-uppercase"
						@click="save(c)"
						style="margin-right: 20px;"
					>
						<i class="material-icons">edit</i>
					</label>
					<label
						@click="categoriesDelete({id: c.id})"
						class="btn btn-label-danger text-uppercase"
					>
						<i class="material-icons">delete</i>
					</label>
				</div>
			</div>
			<div class="lis row col-12" style="margin: 0px;">
				<div class="col-10">
					<sim-input id="title" v-model="n.name" />
				</div>
				<div class="col-2">
					<label
						for=""
						class="btn btn-label-primary text-uppercase"
						@click="save(n)"
						style="margin-right: 20px;"
					>
						<i class="material-icons">save</i>
					</label>
				</div>
			</div>
			<sim-pagination
				align="right"
				:data="pagination"
				@change="query.page = $event"
			/>
		</template>
		<template v-else>
			<div style="display: table; margin: 0 auto; height: 400px;">
				<div class="lds-ripple"><div></div><div></div></div>
			</div>
		</template>
	</div>
</template>

<script>
	import {mapActions, mapState, mapGetters} from "vuex";
	import moment from "moment";
	import Vue from 'vue';

	export default {
		data: () => ({
			columns: [],
			limit: 10,
			o_data: {},
			search: "",
			modal: true,
			n: {
				name: "",
			},
			query: {
				limit: 10,
				page: 1,
			},
		}),
		computed: {
			...mapState({
				pagination: (state) => state.forums.paginationCategory,
				loadings: (state) => state.forums.loadings,
				session: (state) => state.session.user,
			}),
			...mapGetters({
				l: "lang",
			}),
		},
		methods: {
			...mapActions({
				index: "forums/index_categories",
				categoriesDelete: "forums/categoriesDelete",
				categoriesUpdate: "forums/categoriesUpdate",
				categoriesStore: "forums/categoriesStore",
			}),
			searchData({search = ""} = {}) {
				this.index({...this.query, search});
			},
			orderBy(data) {
				if (this.search !== "") {
					this.index({
						type: 2,
						search: this.search,
						sort_by: `${data.field},${data.order}`,
						page: 1,
					});
				} else {
					this.index({
						type: 2,
						sort_by: `${data.field},${data.order}`,
						page: 1,
					});
				}
			},
			active(id) {
				if (confirm("Are you sure to activate this Events?")) {
					this.update({id: id, status: "active"});
				}
			},
			deactivate(id) {
				if (confirm("Are you sure to disable this Events?")) {
					this.update({id: id, status: "inactive"});
				}
			},
			pageD(d) {
				console.log(d);
			},
			async save(n) {
				let res = await this.index({search: n.name.replace(" ", "+")});
				if (
					res &&
					res.data &&
					res.data.length &&
					(!n.id ||
						(n.id && res.data.findIndex((item) => item.name == n.name && item.id != n.id) >= 0))
				) {
					Vue.prototype.$message.error({
                        message: `A record with this name already exists!`,
                        position: `top-right`
                    })
					
				} else {
					if (n.hasOwnProperty("id")) {
						this.categoriesUpdate(n).then(() => {
							this.index(this.query);
							this.n.name = "";
						});
					} else {
						this.categoriesStore(n).then(() => {
							this.index(this.query);
							this.n.name = "";
						});
					}
				}
			},
			moment,
			Vue
		},
		watch: {
			"query.page"() {
				this.index(this.query);
			},
		},
		async mounted() {
			await this.index(this.query);
		},
	};
</script>
<style lang="scss">
	.lis {
		padding: 10px;
		border-bottom: 1px solid #f4f4f4;
		cursor: pointer;
	}
</style>
