import { render, staticRenderFns } from "./playpenLogo.vue?vue&type=template&id=504e5145&scoped=true&"
import script from "./playpenLogo.vue?vue&type=script&lang=js&"
export * from "./playpenLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504e5145",
  null
  
)

export default component.exports