<template>
    <div>
        <div class="flex justify-end mb-4">
            <sim-button align="right" :to="{ name: 'fields.create' }" :disabled="loading" :loading="loading">
                <i class="material-icons"> add</i>
                Create
            </sim-button>
        </div>
        <!-- aqui va la tabla -->
        <sim-table
            :loading="loading"
            :columns="columns"
            :toolbar-per-page="$route.query.limit"
            @toolbar:search="searchData({ query: $event, page: 1 })"
            @toolbar:per-page="searchData({ limit: $event })"
            :source="pagination.data">
            <template #td:tags="{ }">
                x
            </template>
            <template #td:actions="{ record }">
                <div class="flex space-x-2">
                    <router-link :to="{ name: 'fields.show', params: { id: record.item.id } }" class="btn btn-label-warning text-uppercase">
                        <i class="material-icons">edit</i>
                    </router-link> 
                    <label @click="deleteFields({ id: record.item.id })" class="btn btn-label-danger text-uppercase">
                        <i class="material-icons"> delete</i>
                    </label>
                </div>
            </template>
        </sim-table>
        <sim-pagination align="right" :data="pagination" @change="searchData({ page: $event })" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
    data: () => ({
        limit: 10,
        search:'',
        dataPagination: [],
        query: {
            limit: 10,
            page: 1,
            query: ''
        },
    }),
    computed: {
        ...mapState({
            pagination: state => state.fields.pagination,
            loading: state => state.fields.loadings.index,
            session: state => state.session.user
        }),
        ...mapGetters({
            l: 'lang'
        }),
        columns() {
            return [
                { title: "name", key: "name" },
                { title: "description", key: "description" },
                { title: "field_type", key: "field_type" },
                { title: "Actions",  slot: "actions", "alignment": "right" },
            ]
        }
    },
    methods: {
        ...mapActions({
            fetchFields: 'fields/fetchFields',
            deleteFields: 'fields/delete',
        }),
        searchData(_query = {}) {
            const query = this.$queryUtils.withoutInvalidValues({ ...this.query, ..._query })
            this.fetchFields(query)
                .then(() => {
                    this.$router.replace({ query })
                        .catch(() => null)
                })
        }
    },
    mounted() {
        this.searchData();
    },
    created() {
        this.query = {...this.query, ...this.$route.query, query: ''}
    },
    watch:{
        // search (val) {
        //     if (val.length > 3 ) {
        //         this.searchData()
        //     }
        // },
        pagination(val){
            let aux = [];
            for (let index = 0; index < val.total_pages; index++) {
                aux.push(index + 1);
            }
            this.dataPagination = aux;
        },
        'query.page'(){
            this.fetchFields(this.query)
        }
    }
}
</script>

<style lang="scss">
.sim-pagination {
    width: 100%;
    display: flex;
    list-style: none;

    .page-item {
        border-radius: 4px;
        padding: 5px;
        min-width: 35px;
        min-height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        background: white;
        cursor: pointer;
        
        div {
            text-decoration: none;
        }

        &.active {
            background: #006CFF;
            div {
                color: white;
            }
        }
    }
}
</style>