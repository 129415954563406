<template>
	<div>
		<div class="flex flex-wrap mb-2">
			<label @click="$router.back()" class="btn text-uppercase">
				<i class="material-icons">keyboard_backspace</i>
				{{ l.app.To_return }}
			</label>
		</div>
		<div class="flex flex-wrap">
			<div class="w-full">
				<sim-card>
					<div v-if="!loadings.show" class="w-full">
						<div class="form-group">
							<label for="name">{{ l.app.title_of_your_post}}</label>
							<sim-input
								id="title"
								:error="error('title', {attribute: 'title'})"
								v-model="forums.title"
							/>
						</div>
						<div class="form-group">
							<label for="name">{{ l.app.categories_of_your_post}}</label>
							<v-select
								v-model="forums.category_id"
								:options="categories"
							></v-select>
						</div>
						<div class="form-group">
							<label for="name">{{ l.app.content_of_your_post}}</label>
							<vue-editor
								v-model="forums.description"
								:editorToolbar="customToolbar"
							></vue-editor>
						</div>

						<sim-button class="mr-2" :loading="loading" @click="save()">
							<i class="material-icons">save</i>
							{{ l.app.Save_Changes }}
						</sim-button>
						<sim-button
							@click="$router.push('/forums')"
							outlined
							style="color: #e01f8b !important;"
						>
							<i class="material-icons">clear</i>
							{{ l.app.Cancel }}
						</sim-button>
					</div>
					<div v-else>
						<div style="display: table; margin: 0 auto; height: 400px;">
							<div class="lds-ripple"><div></div><div></div></div>
						</div>
					</div>
				</sim-card>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
	import moment from "moment";
	import {VueEditor} from "vue2-editor";
	import Vue from 'vue';

	export default {
		data: () => ({
			forums: {
				title: "",
				category_id: "",
				description: "",
			},
			customToolbar: [
				["bold", "italic", "underline"],
				[{list: "ordered"}, {list: "bullet"}],
			],
		}),
		components: {
			VueEditor,
		},
		computed: {
			...mapState({
				pagination: (state) => state.forums.pagination,
				loadings: (state) => state.forums.loadings,
				session: (state) => state.session.user,
			}),
			...mapGetters({
				hasError: "hasError",
				error: "error",
				l: "lang",
			}),
		},
		methods: {
			...mapMutations({
				setNavbarTitle: "SET_NAVBAR_TITLE",
			}),
			...mapActions({
				index: "forums/index",
				show: "forums/show",
				update: "forums/update",
				store: "forums/store",
			}),
			searchData() {
				this.index(this.query);
			},
			async save() {
				let res = await this.index({search: this.forums.title});
				if (
					res &&
					res.data &&
					res.data.length &&
					res.data.findIndex((item) => item.title == this.forums.title && item.id != this.forums.id) >= 0
				) {
					Vue.prototype.$message.error({
                        message: `A record with this name already exists!`,
                        position: `top-right`
                    })
                       
				} else {
					if (this.forums.hasOwnProperty("id")) {
						this.update(this.forums).then(() => {
							this.$router.push("/forums");
						});
					} else {
						this.store(this.forums).then(() => {
							this.$router.push("/forums");
						});
					}
				}
			},
			moment,
			VueEditor,
			Vue
		},
		created() {
			this.setNavbarTitle(["List of Events"]);
			const {id} = this.$route.params;
			this.show(id).then((res) => {
				this.forums = res;
			});
		},
		watch: {
			search(val) {
				if (val.length > 3) {
					this.searchData();
				}
			},
		},
	};
</script>
