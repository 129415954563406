<template>
    <div style="height: 100%;" class="d-flex d-flex-column align-items-center justify-content-center">
        <div style="flex-grow: 1;" class="d-flex justify-content-center align-items-center d-flex-column">
             <h4> Coming soon </h4>
             <h2> Working on new features </h2>
        </div>
        <div class="d-flex justify-content-end w-100p">
            <playpen-logo width="400" height="50"/>
        </div>
    </div>
</template>

<script>
import playpenLogo from '@/components/playpenLogo.vue';
export default {
  name: 'home',
  components: {
      playpenLogo
  }
}
</script>
