<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <div class="row kt-pt25">
            <div class="col-12 col-sm-12">
                <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                    <div class="kt-portlet__body svg_list_trainig">
                        <form @submit.prevent="storeData()" @keydown="removeError($event.target.id)">
                            <h3>{{ isUpdating ? l.app.To_update : l.app.Create }}</h3>
                            <div class="flex flex-wrap -mx-2">
                                <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                    <div class="form-group" v-for="(input, key_input) in  form" :key="key_input"> 
                                        <label :for="input.atribute">{{input.label}}</label>
                                        <sim-input
                                            v-if="input.type !== 'select' && input.type !== 'editor'"
                                            :id="input.atribute"
                                            :type="input.type"
                                            :disabled=false
                                            :error="error(input.atribute, { attribute: input.atribute })"
                                            v-model="formData[input.atribute]"/>
                                        <vue-editor v-else-if="input.type === 'editor'" 
                                            :id="input.atribute"
                                            v-model="formData[input.atribute]">
                                        </vue-editor>
                                        <template v-else>
                                            <v-select value="" :disabled=false v-model="selectData[input.atribute]" :options="source[input.source]"></v-select>
                                            <div class="feedback-zone">
                                                <span>
                                                    <p style="color: #ff1100;" :class="[`sim-input-error-message`]" v-if="error(input.atribute, { attribute: input.atribute })">{{ error(input.atribute, { attribute: input.atribute }) }}</p>
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <sim-button class="mr-2" type="submit" :loading="loading">
                                {{ isUpdating ? l.app.To_update : l.app.Create }}
                            </sim-button>

                            <!--sim-button v-if="isUpdating" class="mr-2" type="button" @click="inactiveCompanies()" :loading="loading">
                                <i class="material-icons">unpublished</i>
                                Deactivate
                            </sim-button-->

                            <sim-button outlined :to="'/companies'" style="color: #e01f8b !important;">
                                <i class="material-icons">clear</i>
                                {{l.app.Cancel}}
                            </sim-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import 'vue-select/src/scss/vue-select.scss';
import vSelect from 'vue-select';
import { VueEditor } from "vue2-editor";

export default {
    data: () => ({
        picture: null,
        isUpdating: false,
        pictureV: '',
        selectData: {
            country_code: '',
            regulated: '',
            sector_id:''
        },
        data:{
            status: "active"
        },
        dataR:{
            status: "inactive"
        },
        source: {
            country:[],
            regulated: [
                {
                    "code":true,
                    "label":"Yes business is regulated"
                },    {
                    "code":false,
                    "label":"Not business is regulated"
                }
            ],
            sector_id:[]
        },
        form:[
            {label: 'name', atribute: 'name', type: 'text', visibility: true},
        ],
        pictures:[],
        formData: {},
        receipt: [
            {label: 'Primera Imagen', pictureV: '', file: null}
        ],
        certificate: [
            {label: 'Primera Imagen', pictureV: '', file: null}
        ],
        calculatePoints:{
            type:null,
            category_id:null,
            price_range_id: null,
            brand_id:null,
        },
        loading: true
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        ...mapState({
            lang: state => state.lang,
            errorsS: state => state.errors,
            companies: state => state.companies.company,
            garment: state => state.companies.garment,
            categories: state => state.categories.pagination,
            brands: state => state.brands.pagination,
            priceRanges: state => state.PriceRanges.pagination,
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE',
            setAdministrator: 'companies/SET_ADMINISTRATOR'
        }),
        ...mapActions({
            store: 'companies/store',
            removeError: 'removeError',
            fetchCompany: 'companies/show',
            fetchSectors: 'sectors/index',
            update: 'companies/update'
        }),

        addImgADD(img, picture) {
            picture.file = img;
        },
        addImgPV(img, picture) {
            picture.pictureV = img;
        },
        addArryPicture(){
            this.pictures.push({label: 'Primera Imagen', pictureV: '', file: null});
        },
        activeInput(){
            if(this.formData.brand_id === -1){
                this.form[10].visibility=true;
            }else{
                this.form[10].visibility=false;
                this.formData.other_brand= '';
            }
        },
       
        inactiveCompanies(){
            this.dataR.id= this.companies.id;
            this.update(this.dataR);
        },
        storeData(){
            if(this.selectData.sector_id.value !== ''){
                this.formData.sector_id = this.selectData.sector_id.value
            }
            this.isUpdating ?  this.update(this.formData) :  this.store(this.formData);
        },
        async deactivate(id) {
            if (confirm("Are you sure to disable this Company?")) {
                let response = await this.update({ id: id, status: "inactive" });
                if (response) {
                    let index = this.pagination.data.findIndex((item) => item.id == id);
                    if (index >= 0) {
                        this.pagination.data[index].status = response.status;
                    }
                }
            }
        },
        async getSector() {
            let response = await this.fetchSectors({limit:0});

            let sectors = [
                {
                    value: "",
                    label: "All sectors",
                },
            ]
            if(response){
                response.forEach((element) => {
                    sectors.push({
                        value: element.id,
                        label: element.name,
                    });
                });
            }
           
            this.source.sector_id = sectors;
            console.log("getSector",this.source)
        },
        async getCompany() {
            const id = this.$route.params.id;
            let res = await this.fetchCompany(id)
            if(res){
                this.formData = {
                    id: res.id,
                    name: res.name,
                    about: res.about,
                    sector_id: res.sector_id,
                    website_url: res.website_url,
                    facebook_url: res.website_url,
                    instagram_url: res.instagram_url,
                    linkedin_url: res.linkedin_url,
                    twitter_url: res.twitter_url,
                    contact_email:res.contact_email,
                    contact_phone:res.contact_phone
                }
                this.selectData= {
                    //regulated: this.source.regulated.find(e=> e.code ===  this.companies.regulated),
                    sector_id: this.source.sector_id.find(e=> e.value ==  res.sector_id), 
                }
            }
        },
    },
    components: {
        vSelect,
        VueEditor
    },
    created() {
        if(this.$route.name === 'companies.create'){
            this.setNavbarTitle(['List of Companies' , this.l.app.Create])
        }else{   
            this.isUpdating = true;
            this.setNavbarTitle(['List of Companies', this.l.app.To_update])
            this.getCompany();
        }
        this.source.country= this.l.usps_zipcodes;
        this.form=[
            {label: 'Company name', atribute: 'name', type: 'text', visibility: true},
            {label: 'About the company', atribute: 'about', type: 'editor', visibility: true},
            {label: 'Sector', atribute: 'sector_id', type: 'select', visibility: true ,source:'sector_id'},
            {label: 'Business website', atribute: 'website_url', type: 'text', visibility: true},
            {label: 'Facebook url', atribute: 'facebook_url', type: 'text', visibility: true},
            {label: 'instagram url', atribute: 'instagram_url', type: 'text', visibility: true},
            {label: 'linkedin url', atribute: 'linkedin_url', type: 'text', visibility: true},
            {label: 'twitter url', atribute: 'twitter_url', type: 'text', visibility: true},
            {label: 'Contact email', atribute: 'contact_email', type: 'text', visibility: true},
            {label: 'Contact phone ', atribute: 'contact_phone', type: 'text', visibility: true}
        ]
        this.loading= false;
        this.getSector();
    }
}
</script>

<style lang="scss">
.conte_img{
    display: table;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}
.add_img{
    border: 2px dashed rgb(235, 237, 242) !important;
    width: 180px;
    height: 250px;
    display: flex;
    -webkit-box-align: center;
    cursor: pointer;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    i {
        color: #ebedf2;
        font-size: 70px;
    }

    &:hover {
        i {
        color: #374afb;
        }
    }
}
.deleteIcon {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 1;
}
</style>