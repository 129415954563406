<template>
    <div :class="['search_content_page', open &&'open', position && 'rotate']">
        <div>
            <input type="search" v-model="input"> 
            <i class="material-icons clear" @click="clearSearch()">clear</i>
            <i v-if="!open" class="material-icons" @click="openSearch()">search</i>
            <i v-else class="material-icons" @click="$emit('click', input)">search</i>
        </div>
    </div>
</template>
 
<script>
	export default {
        name: `search`,
		props: {
			// basic input attributes
			disabled: { required: false, type: Boolean, default: false },
			position: { required: false, type: Boolean, default: false },
		},
		data () {
			return {
				input: '',
				hasFocus: false,
				open: false
			}
		},
		methods: {
			setdisabled ( data ) {
				this.disabled = data;
			},
			clearSearch () {
                this.open = false;
                this.input= '';
			},
			openSearch () {
				this.open = true;
			}
		}
	}
</script>

<style lang="scss">

.search_content_page{
    display: block;
    float: left;

  > div {
      width: 40px;
      height: 40px;
      border-radius: 23px;
      border: 1px solid rgba(241, 242, 246, 0.2);
      background: rgba(241, 242, 246, 0.2);
      color: #363636;
      float: left;
      text-align: center;
  }

  .material-icons{
    color: #363636 !important;
    line-height: 40px;
    float: right;
    width: 40px;
    cursor: pointer;
  }

  .isLogin{
    margin-right: 60px;
  }

  input{
    width: 200px;
    height: 38px;
    border-radius: 23px;
    border: none;
    display: none;
    padding: 15px;
    background: transparent;
  }

  .clear{
    display: none;
    opacity: 0.5;
  }

  &.open{
    > div { width: 300px; }

    input,
    .clear{
      display: inline;
    }

    @media (max-width: 640px){
      left: 0px;
      width: 100%;
      > div { width: 100%; }
    }
  }

  &.rotate{
    right: calc(10% + 50px);
  }
}

</style>