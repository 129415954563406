import Layout from './layout/layout.vue';
import Sidebar from './layout/sidebar/Sidebar.vue';
import SidebarLogo from './layout/sidebar/SidebarLogo.vue';
import SidebarItem from './layout/sidebar/SidebarItem.vue';
import Navbar from './layout/navbar/Navbar.vue';
import Container from './layout/container/Container.vue';
import Dropdown from './dropdown/Dropdown.vue';
import DropdownItem from './dropdown/DropdownItem.vue';
import Divider from './divider/Divider.vue';
import Badge from './badge/Badge.vue';
import Button from './button/Button.vue';
import Input from './input/Input.vue';
import ImgCropper from './imgCropper/imgCropper';
import ImgBlog from './blog/imgBlog';
import Card from './card/Card.vue';
import Search from './search/search.vue';
import Modal from './modal/modal.vue';
import Select from './select/Select.vue';
import Checkbox from './checkbox/Checkbox.vue';
import Table from './table/Table.vue';
import Pagination from './pagination/Pagination.vue';
import List from './list/List.vue';
import Switch from './switch/Switch.vue'
import Spinner from './spinner/Spinner';

export default {
    Layout,
    Sidebar,
    SidebarLogo,
    SidebarItem,
    Search,
    Navbar,
    Container,
    Dropdown,
    DropdownItem,
    Divider,
    Badge,
    Button,
    Input,
    ImgCropper,
    ImgBlog,
    Card,
    Select,
    Checkbox,
    Table,
    Modal,
    Pagination,
    List,
    Switch,
    Spinner
}
