<template>
  <div class="row">
    <div class="flex justify-end mb-4 col-12">
<!--      <sim-button align="right" :loading="loading" :to="{ name: 'companies.create' }">-->
<!--        <i class="material-icons">add</i> {{this.l.app.Create}}-->
<!--      </sim-button>-->
    </div>
    <!-- aqui va la tabla -->
    <sim-table
        :loading="loading"
        :columns="columns"
        :toolbar-per-page="$route.query.limit"
        @toolbar:per-page="searchData({ limit: $event })"
        @sort-change="searchData({ order_by: `${$event.key},${$event.direction}` })"
        :source="pagination.data"
    >

      <template #filter:sector>
        <v-select
            label="name"
            :loading="loadings.sectors"
            :reduce="(sector) => sector.id"
            :options="sectors.data"
            @input="searchData({ sector_id: $event })"
            @search="searchSectors({ query: $event, page: 1, limit: 10 })"
            :filterable="false"
        >
          <template #list-footer v-if="sectors.total_pages > 1">
            <li class="pagination">
              <p class="text-center my-2">
                Página {{ sectors.page }} de {{ sectors.total_pages }}
              </p>
              <div class="flex space-x-2 px-2 pt-2">
                <button
                    class="bg-gray-200 flex-grow rounded-md py-2"
                    @click="searchSectors({ page: sectors.page - 1, limit: 10 })"
                    v-if="sectors.page !== 1"
                >
                  Anterior
                </button>
                <button
                    class="bg-gray-200 flex-grow rounded-md py-2"
                    @click="searchSectors({ $event, page: sectors.page + 1, limit: 10 })"
                    v-if="sectors.page < sectors.total_pages"
                >
                  Siguiente
                </button>
              </div>
            </li>
          </template>
        </v-select>
      </template>

<!--      <template #filter:status>-->
<!--        <select-->
<!--            :value="$route.query.status"-->
<!--            class="py-3 px-4 appearance-none w-full font-medium border outline-none border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"-->
<!--            @input="searchData({ status: $event.target.value })"-->
<!--        >-->
<!--          <option value="">&#45;&#45; TODOS &#45;&#45;</option>-->
<!--          <option value="pending">Pendientes</option>-->
<!--          <option value="active">Activos</option>-->
<!--          <option value="inactive">Inactivos</option>-->
<!--        </select>-->
<!--      </template>-->

      <template #td:username="{ record }">
        {{ record.item.author.profile.name + ' ' + record.item.author.profile.last_name }}
      </template>

      <template #td:changes="{ record }">
        <div v-if="record.item.value">
          <div v-for="(value, key) in record.item.value" :key="key" class="w-100 pb-2">
<!--            <span>{{ // getTitle(value, key) }}</span>-->
            <span class="w-100 text-bold">{{ key.replaceAll('id','').replaceAll('_',' ') + ': ' }}</span>
            <div v-if="key === 'socials'">
              <div v-for="(val, k) in value" :key="k" >
                <span class="mx-2 inline-block">{{ k.replaceAll('id','').replaceAll('_',' ') + ': ' }}</span>
                <p class="inline-block" v-html="val" />
              </div>
            </div>
            <p v-html="value" v-else/>
          </div>
        </div>
      </template>

      <template #td:state="{ record }">
        {{ record.item.status }}
      </template>

      <template #td:actions="{ record }">
        <div class="flex space-x-2">
<!--          <template v-if="record.item.status && record.item.status != 'pending'">-->
<!--            <label-->
<!--                v-if="record.item.status != 'active'"-->
<!--                :title="'Activate'"-->
<!--                @click="active(record.item.id)"-->
<!--                class="btn btn-label-primary text-uppercase"-->
<!--            >-->
<!--              <i class="material-icons">done_all</i>-->
<!--            </label>-->
<!--            <label-->
<!--                v-else-->
<!--                :title="l.app.desactivate"-->
<!--                @click="deactivate(record.item.id)"-->
<!--                class="btn btn-label-primary text-uppercase"-->
<!--            >-->
<!--              <i class="material-icons">unpublished</i>-->
<!--            </label>-->
<!--          </template>-->
          <template v-if="record && record.item && record.item.status">
          </template>
          <label
              @click="accept(record.item.id)"
              :title="'Approve'"
              class="btn btn-label-primary text-uppercase"
          >
            <i class="material-icons">check</i>
          </label>
          <label
              @click="refuse(record.item.id)"
              :title="'Refuse'"
              class="btn btn-label-danger text-uppercase"
          >
            <i class="material-icons">close</i>
          </label>
        </div>
      </template>

    </sim-table>

    <sim-pagination
        align="right"
        :data="pagination"
        @change="searchData({ page: $event })"
    />

<!--    <sim-Modal :show="modal" @close="modal = false">-->
<!--      <p class="a-modal-title" slot="header">Actions</p>-->
<!--      <div slot="body">-->

<!--      </div>-->
<!--      <div class="row">-->
<!--        <sim-input class="col-12" id="updatePoint" type="number" v-model="updatePoint"/>-->
<!--      </div>-->
<!--      <div slot="footer" class="w-100">-->
<!--        <button  @click="modal = false" style="float: right;" class="btn btn-default">Save</button>-->
<!--        <button  @click="modal = false" style="float: right;" class="btn btn-default">Cancel</button>-->
<!--      </div>-->
<!--    </sim-Modal>-->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Vue from "vue";
export default {
  data: () => ({
    query: {
      page: 1,
      status: 'pending',
      with: 'author,model'
    },
    sectorsQuery: {
      page: 1,
    },
    sectors: { results: [] },
    loadings: {
      sectors: false,
    },
    modal: false
  }),
  computed: {
    ...mapState({
      pagination: (state) => state.requests.pagination,
      loading: (state) => state.requests.loadings.index,
      session: (state) => state.session.user,
    }),
    ...mapGetters({
      l: "lang",
    }),
    columns() {
      return [
        {
          title: this.l.app.Company_name,
          key: "model.name",
        },
        {
          title: 'username',
          slot: 'username',
        },
        {
          title: 'email',
          key: 'author.profile.email',
        },
        {
          title: 'changes',
          slot: 'changes',
        },
        {
          title: 'status',
          slot: 'state',
          alignment: "right",
          key: "status",
        },
        {
          title: this.l.app.Actions,
          slot: "actions",
          alignment: "right",
          key: "id",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchRequests: "requests/index",
      deleteCompany: "requests/delete",
      update: "requests/update",
    }),
    searchSectors(_query = {}) {
      this.sectorsQuery = { ...this.sectorsQuery, ..._query };
      this.loadings.sectors = !this.loadings.sectors;
      this.$repository.sectors
          .index(this.sectorsQuery)
          .then(({ data }) => (this.sectors = data))
          .finally(() => (this.loadings.sectors = !this.loadings.sectors));
    },
    searchData(_query = {}) {
      this.query = this.$queryUtils.withoutInvalidValues({
        ...this.query,
        ..._query,
      });
      this.fetchRequests(this.query).then(() => {
        this.$router.replace({ ...this.query }).catch(() => null);
      });
    },
    async accept(id) {
      if (confirm("Are you sure to accept this request?")) {
        let response = await this.$repository.companies.requests().accept(id)
        if (response) {
          this.searchData()
          Vue.prototype.$message.success({
            message: `Updated Successfully`,
            position: `top-right`
          })
          // let index = this.pagination.data.findIndex((item) => item.id == id);
          // if (index >= 0) {
          //   this.pagination.data[index].status = response.status;
        }
      }
    },
    async refuse(id) {
      if (confirm("Are you sure to refuse this request?")) {
        let response = await this.$repository.companies.requests().refuse(id)
        if (response) {
          this.searchData()
          Vue.prototype.$message.success({
            message: `Refused Successfully`,
            position: `top-right`
          })
          // let index = this.pagination.data.findIndex((item) => item.id === id);
          // if (index >= 0) {
          //   this.pagination.data[index].status = response.status;
          // }
        }
      }
    },
  },
  mounted() {
    this.searchData();
    // this.searchSectors();
  },
  created() {
    this.query = { ...this.query, ...this.$route.query, query: "" };
  },
};
</script>
<style scoped>
.a-modal-body {
  min-height: 100px !important;
}
</style>
