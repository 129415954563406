<template>
	<div :class="[`${prefix}-spinner`]" v-if="type === 'regular'">
		<div v-for="n in 8" :key="n" :style="{ width: `${dotWidth}px`, height: `${dotWidth}px` }"></div>
	</div>
	<div v-else :class="`${prefix}-ellipsis`" :style="{ top: top }">
		<div v-for="n in 3" :key="n" :style="{ width: `${dotWidth}px`, height: `${dotWidth}px`, background: dotColor }"></div>
	</div>
</template>

<script>
import { prefix } from '@/components/utils'
export default {
	name: 'spinner',
	props: {
		type: { required: false, type: String, default: 'circle', validator: (prop) => ['circle', 'ellipsis'].includes(prop) },
		dotWidth: { required: false, type: [String, Number], default: 5 },
        dotColor: { required: false, type: String, default: 'white' },
        top: { required: false, type: String, default: null }
	},
	data: () => ({
		prefix
	})
}
</script>

<style lang="scss" scoped>
$prefix: sim;
.#{$prefix}-spinner-regular {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	div {
		animation: #{$prefix}-spinner-regular 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 40px 40px;

		&:after {
			content: " ";
			display: block;
			position: absolute;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: #fff;
			margin: -4px 0 0 -4px;
		}

		&:nth-child(1) {
			animation-delay: -0.036s;
		}

		&:nth-child(1):after {
			top: 63px;
			left: 63px;
		}

		&:nth-child(2) {
				animation-delay: -0.072s;
		}

		&:nth-child(2):after {
			top: 68px;
			left: 56px;
		}

		&:nth-child(3) {
			animation-delay: -0.108s;
		}

		&:nth-child(3):after {
			top: 71px;
			left: 48px;
		}

		&:nth-child(4) {
			animation-delay: -0.144s;
		}

		&:nth-child(4):after {
			top: 72px;
			left: 40px;
		}

		&:nth-child(5) {
			animation-delay: -0.18s;
		}

		&:nth-child(5):after {
			top: 71px;
			left: 32px;
		}

		&:nth-child(6) {
			animation-delay: -0.216s;
		}

		&:nth-child(6):after {
			top: 68px;
			left: 24px;
		}

		&:nth-child(7) {
			animation-delay: -0.252s;
		}

		&:nth-child(7):after {
			top: 63px;
			left: 17px;
		}

		&:nth-child(8) {
			animation-delay: -0.288s;
		}

		&:nth-child(8):after {
			top: 56px;
			left: 12px;
		}
	}
}

@keyframes #{$prefix}-spinner-regular {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.#{$prefix}-ellipsis {
	display: inline-block;
	position: relative;
	top: -7px;
    left: -22px;
}
.#{$prefix}-ellipsis div {
	position: absolute;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.#{$prefix}-ellipsis div:nth-child(1) {
	left: 0px;
	animation: #{$prefix}-ellipsis1 0.6s infinite;
}
.#{$prefix}-ellipsis div:nth-child(2) {
	left: 0px;
	animation: #{$prefix}-ellipsis2 0.6s infinite;
}
.#{$prefix}-ellipsis div:nth-child(3) {
	left: 8px;
	animation: #{$prefix}-ellipsis3 0.6s infinite;
}

@keyframes #{$prefix}-ellipsis1 {
	0% { transform: scale(0); }
	100% { transform: scale(1); }
}

@keyframes #{$prefix}-ellipsis2 {
	0% { transform: translate(0, 0); }
	100% { transform: translate(8px, 0); }
}

@keyframes #{$prefix}-ellipsis3 {
	0% { transform: scale(1); }
	100% { transform: scale(0); }
}
</style>
