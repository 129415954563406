<template>
	<div class="a-modal-wrapper" v-if="show">
		<div class="a-modal-overlay" @click="overlayDismissable ? close() : () => ({})"></div>
		<div id="modal-don" :style="[type === 'a-modal' && {top:`calc(50% - ${p})`, opacity: o}, !confirmModal && `min-height: 300px`]" :class="type">

			<div class="a-modal-header">
				<button class="close" @click="close"><i class="material-icons">close</i></button>
				<slot name="header"></slot>
			</div>

			<div class="a-modal-body" v-if="!confirmModal">
				<slot></slot>
			</div>

			<div class="a-modal-footer" v-if="$slots.footer">
				<slot name="footer"></slot>
			</div>

		</div>

	</div>
</template>

<script>

	export default {
		name: "Modal",
		props: {
			overlayDismissable: { required: false, type: Boolean, default: true },
			show: { required: true, type: Boolean, default: false },
			type: { required: false, type: String, default: "a-modal"  },
      confirmModal: { required: false, type: Boolean, default: false }
		},
		methods: {
			close () {
				this.$emit('close');
			}
        },
        data () {
			return {
                p: '0px',
                o: '0'
			}
		},
        created(){
            
            
        },
        watch:{
            show( val ) {
                if( val ){
                    setTimeout(() => {
                        var modalDon = document.getElementById("modal-don"); 
                        this.p = (modalDon.clientHeight/2) + 'px';
                        this.o = '1';
                    }, 100);

                }else{
                    this.p = '0px';
                    this.o = '0';
                }
            }
        }
	}
</script>

<style lang="scss">

.a-modal-wrapper {
  position: fixed;
  top: 0;
  lefT: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000000;
  background: rgba(106, 112, 133, 0.30196078431372547);

  .a-modal-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .a-modal {
    background: #fff;
    border-radius: 5px;
    opacity: 0;
    z-index: 10000;
    top: 0px;
    position: absolute;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.205);
    max-height: 100%;
    overflow-y: auto;
    transition: all 250ms ease 0s;

    @media (min-width: 500px) {
      width: 40%;
      min-width: 500px;
      left: 30%;
    }

    @media (max-width: 500px) {
          width: 100% !important;;
          left: 0px !important;;
          max-width: 100%;
    }

    &-header, &-body, &-footer {
      padding: 20px;
      position: relative;
    }

    &-header {
      border-bottom: 1px solid rgba(247, 248, 251, 0.52);
    }

    &-footer {
      border-top: 1px solid rgba(247, 248, 251, 0.52);
    }

    &-body{
      max-height: 600px;
      overflow-y: auto;
      min-height: 300px;
    }

    &.view {
      width: calc(100% - 100px);
      height: calc(100% - 50px);
      left: 50px;
      top: 25px;
      opacity: 1;
      overflow: hidden;
      .a-modal-body{
        max-height: calc(100% - 68px) !important;
        height: 100%;
      }
      .b_e{
        height: calc(100% + 40px);
        display: block;
        margin: -20px;
        width: calc(100% + 40px);
        padding: 20px;
      }
    }
    &.good {
      height: 100%;
      position: fixed;
      width: 500px;
      left: calc(50% - 250px);

      .product-page-modal{
        float: left;
        width: calc(100% + 40px);
        margin: -20px;
        overflow: hidden;
        height: calc(100% + 40px);
      }

      .body-good{
        max-height: calc(100% - 108px) !important;
        height: 100%;
        overflow-y: auto;
        
        @media (max-width: 480px){
          padding: 0 20px;
        }
        @media (min-width: 480px){
          padding-right: 70px;
          padding-left: 70px;
        }
      }
      
      .a-modal-header{
        border-bottom: none;
        position: absolute;
        width: 48px;
        right: 0;
        padding: 0;
        z-index: 1;
      }

      .footer_btn{
        padding: 24px;
        box-sizing: border-box;
        background: #F1F2F6;
        .quantity-selector{
          width: 160px;
          height: 48px;
          float: left;
          border-radius: 24px;
        }

        .btn.btn-lg{
          float: right;
          width: calc(100% - 171px);
          font-weight: 300;
          .material-icons{
            position: absolute;
            left: 24px;
            top: 10px;
          }
        }
      }

      .a-modal-body{
        max-height: 100% !important;
        height: 100%;
      }
    }
  }
  .a-modal-title {
      font-size: 18px;
      font-weight: 600;
      margin: 0px;
      color: rgba(54, 54, 54, 0.57);
    }

  .a-modal-header .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 48px !important;
    height: 48px;
    border: medium none;
    border-radius: 100%;
    font-size: 30px;
    line-height: 49px;
    text-align: center;
    &:after {
      left: 0px;
      top: 0px;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale3d(0, 0, 0);
      transition: all 250ms ease 0s;
      backface-visibility: hidden;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 0;
      border-radius: 50%;
      opacity: 0;
    }
    &:hover::after {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }
}
</style>