import { Authenticated } from '../middlewares'

export default [
    {
        path: '/tenders',
        name: 'tenders.index',
        component: () => import('../../views/tenders/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'tenders.index' }), label: ({ lang }) => lang.app.list_of_tenders, loading: () => false }
            ]
        }
    },
]