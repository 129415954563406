<template>
<div>
    <div class="kt-avatar-blog" id="kt_apps_user_add_avatar" style="margin-bottom: 0px;">
        <label class="kt-avatar-blog__upload" data-toggle="kt-tooltip" title="" data-original-title="Change avatar" >
          <div v-if="img_url !== null" :class="custom_class" :style="`background-image: url(${img_url})`"></div>
          <div v-else :class="custom_class2"></div>
          <input type="file" @change="onChangeImagen" name="kt_apps_user_add_user_avatar">
        </label>
        <span class="kt-avatar-blog__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
            <i class="fa fa-times"></i>
        </span>
    </div>

    <sim-Modal :show="modal" @close="modal = false" >
        <p class="a-modal-title" slot="header">Add Image</p>
        <div class="w-100" style="height: 100%;">
            <cropper
                ref="cropper"
                class="c-100"
                :src="src"
                alt="Source Image"
                :min-container-width="256"
                :min-container-height="256"
                :img-style="{ 'width': '256px', 'height': '100%' }"
            />
        </div>
        <div slot="footer" class="w-100">
            <button  @click="cropImage" v-if="src != ''" style="float: right;" class="btn btn-default">Save</button>
        </div>
    </sim-Modal>
</div>
</template>

<script>
import Vue from 'vue';
import cropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

const fixBinary = (bin) => {
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  
}
export default {
    name: `img-blog`,
    props: {
        img_url: { required: false, type: String, default: '' },
        custom_class: { required: false, type: String, default: 'kt-avatar-blog__holder' },
        custom_class2: { required: false, type: String, default: 'PP_gradient' },
        
        detailTex: { required: false, type: String, default: 'Preview image' },
        aspectRatio: { required: false, type: Number, default: 1/1 },
    }, 
    data(){
        return {
          modal: false,
          src:'',
        }
    },

    mounted () {
    },

    created() {
    },

    components: {
        cropper
    },

    methods: {
        onChangeImagen(e) {
            e.preventDefault();
            let files;
            var extensiones_permitidas = new Array("image/png", "image/jpeg", "image/jpg"); 
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            
            if(files.length){
                if(files[0].size < 12235775){
                var permitida = false; 
                for (var i = 0; i < extensiones_permitidas.length; i++) { 
                    if (extensiones_permitidas[i] == files[0].type) { 
                        permitida = true; 
                        break; 
                    }
                } 
                
                if (permitida) {
                    const reader = new FileReader();
                    reader.onload = () => {
                    this.src = reader.result;
                    this.modal= true;
                    };
                    reader.readAsDataURL(files[0]);
                }else{
                  Vue.prototype.$message.success({
                      text: `The image format is wrong`,
                      position: `top-right`
                  })
                }
                } else {
                  Vue.prototype.$message.success({
                      text: `The weight of the image exceeds what is allowed`,
                      position: `top-right`
                  })
                }
            }
        },
        cropImage() {
            let img_b64 = this.$refs.cropper.getCroppedCanvas({
            width: 848,
            fillColor: '#fff',
            imageSmoothingQuality: 'low',
            }).toDataURL('image/jpeg');
            this.modal= false;
            let jpeg = img_b64.split(',')[1];
            let binary = fixBinary(window.atob(jpeg));// <-- Usamos la fn "fixBinary"
            let the_file = new Blob([binary], {type: 'image/jpeg'});// <-- Sacamos el encode
            let imagen_firma = new File([the_file], 'imagen_firma.jpeg', { type: 'image/jpeg' });
            this.$emit('editImg', imagen_firma);
            this.$emit('editImgVie', img_b64);
        },
        Vue
    }
}
</script>
<style lang="scss">
  .PP_gradient{
    background: rgb(27,112,183);
    background: linear-gradient(97deg, rgba(27,112,183,1) 0%, rgba(229,29,138,1) 100%);
    color: white;
    width: 296px;
    height: 368px;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    border-radius: 3px;
  }
  .PP_circular_gradient{
      background: rgb(27,112,183);
      background: linear-gradient(97deg, rgba(27,112,183,1) 0%, rgba(229,29,138,1) 100%);
      border-radius: 100%;
      color: white;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 42px;
      font-size: 18px;
  }
.kt-avatar-blog {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
    
    .kt_textc_data{
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        color: #000;
        padding: 0px 20px;
        width: 100%;
    }
    .kt-avatar-blog__holder {
        width: 160.71px;
        height: 100px;
        background: #e1dff8;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-size: cover;
        float: left;
    }
    .kt-avatar-blog__holder2 {
        width: 256px;
        height: 328px;
        background: #e1dff8;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-size: cover;
        float: left;
    }

    .kt-avatar-blog__upload {
        cursor: pointer;
        display: table;
        padding: 20px;

        input {
            width: 0 !important;
            height: 0 !important;
            overflow: hidden;
            opacity: 0;
        }
    }

  .kt-avatar-blog__cancel {
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: auto;
    right: -10px;
    bottom: -5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    display: none;

    i {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      color: #a7abc3;
      font-size: 0.9rem;
    }

    &:hover i {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      color: #374afb;
    }
  }

  &.kt-avatar-blog--changed .kt-avatar-blog__cancel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &.kt-avatar-blog--brand .kt-avatar-blog__upload {
    i {
      color: #374afb;
    }

    &:hover {
      background-color: #374afb;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar-blog--light .kt-avatar-blog__upload {
    i {
      color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;

      i {
        color: #282a3c;
      }
    }
  }

  &.kt-avatar-blog--dark .kt-avatar-blog__upload {
    i {
      color: #282a3c;
    }

    &:hover {
      background-color: #282a3c;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar-blog--primary .kt-avatar-blog__upload {
    i {
      color: #5867dd;
    }

    &:hover {
      background-color: #5867dd;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar-blog--success .kt-avatar-blog__upload {
    i {
      color: #1dc9b7;
    }

    &:hover {
      background-color: #1dc9b7;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar-blog--info .kt-avatar-blog__upload {
    i {
      color: #5578eb;
    }

    &:hover {
      background-color: #5578eb;

      i {
        color: #ffffff;
      }
    }
  }

  &.kt-avatar-blog--warning .kt-avatar-blog__upload {
    i {
      color: #ffb822;
    }

    &:hover {
      background-color: #ffb822;

      i {
        color: #111111;
      }
    }
  }

  &.kt-avatar-blog--danger .kt-avatar-blog__upload {
    i {
      color: #fd397a;
    }

    &:hover {
      background-color: #fd397a;

      i {
        color: #ffffff;
      }
    }
  }

}

.c-100{
    float: left;
    width: 100%;
    height: 100%;
}
</style>