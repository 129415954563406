<template>
    <span :class="[`${prefix}-badge`, { 'has-dot': dot }]">
        <span :class="[`${prefix}-badge-dot`]" v-if="dot"></span>
        <slot></slot>
    </span>
</template>

<script>
import { prefix } from '@/components/utils';
export default {
    name: `badge`,
    props: {
        dot: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        prefix
    })
}
</script>