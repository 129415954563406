import { Authenticated } from '../middlewares'

export default [
    {
        path: '/media',
        name: 'media.index',
        component: () => import('../../views/media/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'media.index' }), label: ({ lang }) => lang.app.media_and_press, loading: () => false },
            ]
        }
    },
    {
        path: '/media/create',
        name: 'media.create',
        component: () => import('../../views/media/manager.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'media.index' }), label: ({ lang }) => lang.app.media_and_press, loading: () => false },
                { route: () => ({ name: 'jobs.create' }), label: ({ lang }) => lang.app.Create, loading: () => false }
            ]
        }
    },
    {
        path: '/media/:id',
        name: 'media.show',
        component: () => import(/* webpackChunkName: "users.show" */ '../../views/media/manager.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'media.index' }), label: ({ lang }) => lang.app.media_and_press, loading: () => false },
                { route: () => ({ name: 'jobs.show' }), label: ({ lang }) => lang.app.Edit, loading: () => false }
            ]
            
        },
        
    },
]