import { Authenticated } from '../middlewares'

export default [
	{
        path: '/companies',
        name: 'companies.index',
        component: () => import('../../views/companies/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'companies.index' }), label: ({ lang }) => lang.app.list_of_companies, loading: () => false }
            ]
        }
    },
    {
        path: '/companies/create',
        name: 'companies.create',
        component: () => import('../../views/companies/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'companies.index' }), label: ({ lang }) => lang.app.list_of_companies, loading: () => false },
                { route: () => ({ name: 'companies.create' }), label: ({ lang }) => lang.app.Create, loading: () => false }
            ]
        },
    },
    {
        path: '/companies/:id',
        name: 'companies.show',
        component: () => import('../../views/companies/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'companies.index' }), label: ({ lang }) => lang.app.list_of_companies, loading: () => false },
                { route: ({ route }) => ({ name: 'companies.show', params: route.params }), label: ({ store: { state: { companies } } }) => companies.current?.name ?? 'No encontrado', loading: ({ store: { state: { companies } } }) => companies.loadings.show }
            ]
        },
    },
    {
        path: '/companies/:id/edit',
        name: 'companies.edit',
        component: () => import('../../views/companies/create.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'companies.index' }), label: ({ lang }) => lang.app.list_of_companies, loading: () => false },
                { route: ({ route }) => ({ name: 'companies.show', params: route.params }), label: ({ store: { state: { companies } } }) => companies.current?.name ?? 'No encontrado', loading: ({ store: { state: { companies } } }) => companies.loadings.show },
                { route: ({ route }) => ({ name: 'companies.show', params: route.params }), label: () => 'Edit', loading: () => false }
            ]
        },
    },
    {
        path: '/requests',
        name: 'requests.index',
        component: () => import('../../views/requests/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'companies.index' }), label: () => 'Requests' , loading: () => false },
            ]
        },
    }
]
