import { Authenticated } from '../middlewares'

export default [
    {
        path: '/fields',
        name: 'fields.index',
        component: () => import('../../views/fields/index.vue'),
        meta: {
            middleware: Authenticated,
            breadcrumbs: [
                { route: () => ({ name: 'fields.index' }), label: () => 'List of fields', loading: () => false }
            ]
        }
    },
    {
        path: '/fields/make',
        name: 'fields.create',
        component: () => import('../../views/fields/create.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/fields/:id',
        name: 'fields.show',
        component: () => import('../../views/fields/create.vue'),
        meta: {
            middleware: Authenticated
        }
    }
]