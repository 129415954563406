var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'button',{tag:"component",class:[
    (_vm.prefix + "-button"),
    ("is-" + _vm.scheme),
    { 'icon': _vm.icon },
    { 'flat': _vm.flat },
    { 'block': _vm.block },
    { 'is-link': _vm.link },
    { 'outlined': _vm.outlined },
    { 'disabled': _vm.disabled }
],attrs:{"to":_vm.to,"type":_vm.submit ? 'submit' : 'button',"title":_vm.label,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.icon)?[_c('fa-icon',{attrs:{"icon":_vm.icon,"color":_vm.iconColor}})]:[(_vm.loading)?_c('div',{class:_vm.getSpinnerClass}):_vm._e(),_vm._t("default",[_vm._v(_vm._s(_vm.label))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }