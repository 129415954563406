import { Authenticated } from '../middlewares'

export default [
    {
        path: '/forums',
        name: 'forums.index',
        component: () => import('../../views/forums/index.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/forums/create',
        name: 'forums.create',
        component: () => import('../../views/forums/create.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/forums/:id',
        name: 'forums.show',
        component: () => import(/* webpackChunkName: "users.show" */ '../../views/forums/manager.vue'),
        meta: {
            middleware: Authenticated
        },
    }
]