<template>
  <div>
    <h3 class="text-xl mb-4">
      {{ l.app.new_message }}
    </h3>
    <sim-card>
      <div class="space-y-4">
        <div class="flex flex-col">
          <label for="target_type">
            {{ l.app.send_to }}:
          </label>
          <v-select
            id="target_type"
            :options="messageTypes"
            :reduce="option => option.value"
            @input="clearError('target_type')"
            v-model="form.target_type" />
          <small class="text-red-500" v-if="hasError('target_type')">
            {{ getError('target_type') }}
          </small>
        </div>

        <div class="flex flex-col" v-if="form.target_type === 'input_ids'">
          <label for="target_ids">
            Users
          </label>
          <v-select
            id="target_ids"
            filterable
            searchable
            :close-on-select="false"
            @search="search"
            :selectable="option => !form.target_ids.includes(option.value)"
            :loading="loadings.users"
            :options="users"
            :reduce="option => option.value"
            multiple
            @input="clearError('target_ids')"
            v-model="form.target_ids" />
          <small class="text-red-500" v-if="hasError('target_ids')">
          {{ getError('target_ids') }}
          </small>
        </div>

        <div class="flex flex-col">
          <label for="subject">{{ l.app.subject }}</label>
          <input
              id="subject"
              type="text"
              v-model="form.subject" />
        </div>

        <div class="flex flex-col">
          <label for="message">
            {{ l.app.message }}
          </label>
          <vue-editor @input="clearError('message')" id="message" v-model="form.message"></vue-editor>
          <div class="flex justify-between">
            <small class="text-red-500">
              {{ hasError('message') ? getError('message') : '' }}
            </small>
            <small class="text-gray-700">
              {{form.message.length}}/8192
            </small>
          </div>
        </div>

        <div class="flex justify-end">
          <sim-button @click="store" :disabled="!form.subject || !form.target_type">
            {{ l.app.Send }} 
          </sim-button>
        </div>
      </div>
    </sim-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { VueEditor } from "vue2-editor";

export default {
  name: "create",
  data: () => ({
    users: [],
    customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ],
    form: {
      message: '',
      subject: '',
      target_type: '',
      target_ids: []
    },
    target_ids: [],
    loadings: {
      users: false
    }
  }),
  methods: {
    ...mapActions({
      clearError: 'removeError'
    }),
    // test(e) {
    //   console.log(e)
    //   this.form.subject = 'aaaaaaa'
    // },
    store () {
      this.$repository.messages
        .create({...this.form, target_ids: this.getTargetIds})
        .then(() => {
          this.$message.success({
            title: 'Messages Sent',
            message: 'Messages sent successfully',
            position: 'top-right'
          })

          this.form = {
            message: '',
            subject: '',
            target_type: '',
            target_ids: []
          }
        })
    },
    search(search = '') {
      this.loadings.users = !this.loadings.users
      this.$repository.users
        .index({ limit: 20, order_by: 'email,asc', search })
        .then(({ data: usersp }) => {

          let users = usersp.data;

          // Gets all current selected users
          let current_users = this.users.filter(user => this.getTargetIds.includes(user.value))

          // merge current selected users with incoming users
          current_users = [...current_users, ...users.map(user => ({
            label: user.email,
            value: user.id
          }))]

          // take only unique users (the above step could cause duplicates)
          current_users = current_users.filter((current, index, array) => {
            return array.findIndex(sub => sub.value === current.value) === index
          })

          this.users = current_users
        })
        .finally(() => this.loadings.users = !this.loadings.users)
    }
  },
  components: {
      VueEditor
  },
  computed: {
    ...mapGetters({
        hasError: "hasError",
        error: "error",
        l: "lang",
      }),
    getTargetIds() {
      if (this.form.target_type === 'input_ids') {
        return this.form.target_ids
      }

      return []
    },
    messageTypes () {
      return [
        {
          "label": this.l.app.select_an_option,
          "value": "",
        },
        {
          "label": this.l.app.to_all_users,
          "value": "all",
        },
        {
          "label": this.l.app.to_paying_users,
          "value": "premium",
        },
        {
          "label": this.l.app.to_nonpaying_users,
          "value": "public",
        },
        // {
        //   "label": this.l.app.only_to_users_with_public,
        //   "value": "public",
        // },
        /*{
          "label": this.l.app.only_to_users_with_standard,
          "value": "standard",
        },*/
        // {
        //   "label": this.l.app.only_to_users_with_premium,
        //   "value": "premium",
        // },
        {
          "label": this.l.app.to_all_non_admin_users,
          "value": "user",
        },
        {
          "label": this.l.app.to_all_admin_users,
          "value": "admin",
        },
         {
          "label": this.l.app.to_all_star_users,
          "value": "featured_users",
        },
        {
          "label": this.l.app.to_selected_users,
          "value": "input_ids",
          // "input_ids": [2,3,4],
        }
      ]
    }
  },
  mounted () {
   // this.search()
  }
}
</script>
<style scoped>
.sim-input {
  border-color: #cacaca !important;
}
#subject {
  border-radius: 4px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  border: 1px solid #cacaca;
  height: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 11px 16px;
  width: 100%;
  font-size: 14px;
}
</style>