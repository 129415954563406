import { getObject, storeObject, removeObject } from '@/helpers/storage'
import router from '@/router'
import Vue from 'vue'
import moment from 'moment';
export default {
    namespaced: true,
    state: {
        user: getObject('session') ? getObject('session') : {},
        loading: false
    },
    mutations: {
        SET_LOADING: state => state.loading = !state.loading,
        SET_USER: (state, user) => state.user = user
    },
    actions: {
        login({ commit, dispatch }, user) {
            commit('SET_LOADING')
            user.email = user.email.trim();
            user.password = user.password.trim();
            return this.$repository.session
                .login(user).then(res => {
                    dispatch('configure', res)
                    return res
                })
                .finally(() => commit('SET_LOADING'))
        },
        logout({ dispatch }) {
            this.$repository.session
                .logout()
                .finally(() => dispatch('vanish'))
        },
        passwordReset({ commit }, data) {
            if (data) {
                commit('SET_LOADING')
                this.$repository.session
                    .passwordReset(data)
                    // .then(() => {
                    //     Vue.prototype.$message.success({
                    //         message: `Se le ha enviado un email a ${data.email}, por favor revise su bandeja de entrada`,
                    //         position: `top-right`
                    //     })
                    // })
                    .catch((err) => {
                       console.log("err",err)
                    }).finally(() => commit('SET_LOADING'))
            } else {
                // Vue.prototype.$swal({
                //     toast: true,
                //     type: 'warning',
                //     position: 'top-right',
                //     title: `Debe introducir un email`
                // })
            }
        },
        async passwordResetConfirm({ commit }, data) {
            // if (data) {
                commit('SET_LOADING');
                return await this.$repository.session
                    .passwordResetConfirm({token: data.token, email: data.email, password: data.password, site:'admin'})
                    .then((response) => {
                        console.log('from store', response)
                        // Vue.prototype.$message.success({
                        //     message: `Cambio de contraseña exitoso!`,
                        //     position: `top-right`
                        // })
						return response
                    })
                    .catch((err) => {
                        console.log("err",err)
                        return false
                    }).finally(() => commit('SET_LOADING'))
            // }
            // else {
                // Vue.prototype.$swal({
                //     toast: true,
                //     type: 'warning',
                //     position: 'top-right',
                //     title: `Debe introducir un email`
                // })
            // }
            // return false
        },
        passwordConfirm({ commit }, user) {
            commit('SET_LOADING')
            this.$repository.session
                .passwordConfirm(user).then(() => {
                    // Vue.prototype.$swal({
                    //     toast: true,
                    //     type: 'success',
                    //     title: `Su contraseña ha sido actualizada ;)`
                    // })

                    router.push({ name: 'login' })
                })
                .finally(() => commit('SET_LOADING'))
        },
        currentSession(){
            this.$repository.session
                .currentSession()
                .then(() => {

                })
        },
        renewSession(){
            return this.$repository.session
                .renewSession()
                .then((res) => {
                    let token = res.data.token
                    storeObject('secionInit', moment())
                    storeObject('secionEnd', moment().add(50, 'm'))
                    storeObject(process.env.VUE_APP_USER_TOKEN_NAME, token)
                    return res
                })
        },
        confirmAccount({ commit }, guest) {
            commit('SET_LOADING')
            this.$repository.session
                .confirmAccount(guest)
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Su cuenta ha sido confirmada`,
                        text: `Su cuenta ha sido confirmada`,
                        description: `Su cuenta ha sido confirmada`,
                        position: `top-right`
                    })
                })
                .finally(() => commit('SET_LOADING'))
        },

        vanish({ commit }) {
            commit('SET_USER', {})
            removeObject('session')
            removeObject(process.env.VUE_APP_USER_TOKEN_NAME)
            removeObject(process.env.VUE_APP_USER_TOKEN_EXPIRATION_DATE)
            router.push({ name: 'empty' })
            location.reload();
        },
        configure(_, response) {
            console.log(response.data.role)
            if(response.data.role === 'admin') {
                let token = response.data.access_token.token
                let expiration = new Date(response.data.access_token.expires_in);
                storeObject('secionInit', moment())
                storeObject('secionEnd', moment().add(50, 'm'))
                storeObject(process.env.VUE_APP_USER_TOKEN_NAME, token, expiration.toUTCString())
                storeObject(process.env.VUE_APP_USER_TOKEN_EXPIRATION_DATE, expiration, expiration.toUTCString())
                storeObject('session', response.data);
                location.reload();
            } else {
                // global.prototype.$swal({
                //     toast: true,
                //     type: 'fail',
                //     message: 'the user is not a admin',
                //     title: 'Not a admin user'
                // })
                Vue.prototype.$message.error({
                    title: 'Error, the user is not an admin user',
                    position: `top-right`
                })
            }
            //commit('SET_USER', response.data)
            //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        setNewToken(_, token) {
            storeObject(process.env.VUE_APP_USER_TOKEN_NAME, token)
        },
        updateUserEmail({commit}, {id, body}) {
            commit('SET_LOADING')
            return this.$repository.users
                .updateEmail(id, body)
                .then(res => { 
                    //console.log( res );
                    return res
                })
                .finally(() => commit('SET_LOADING'));
        },
        updateUserPassword({commit}, {id, body}) {
            commit('SET_LOADING')
            return this.$repository.users
                .updatePassword(id, body).then(() => { })
                .finally(() => commit('SET_LOADING'));
        },
        updateUserPicture({ commit }, { id, body }) {
            commit('SET_LOADING')
            return this.$repository.users
                .updatePicture(id, body)
                .then((res) => { 
                    storeObject('session', res.data);
                    commit('SET_USER', res.data)
                })
                .finally(() => commit('SET_LOADING'));
        }
    },
    getters: {
        isAuthenticated: ({ user }) => getObject(process.env.VUE_APP_USER_TOKEN_NAME) && Object.keys(user).length > 0,
        isAdmin:({ user }) => user.role === 'admin'
    }
}