export default ({ axios, mapQueryString, withoutInvalidValues, toFormData }) => ({
    index: (query = {}) => axios.get(`/companies${mapQueryString(query)}`),
    listCompanies: (query = {}) => axios.get(`/companies/list${mapQueryString(query)}`),
    show: (id) => axios.get(`/companies/${id}`),
    store: (data) => axios.post(`/companies`, toFormData(withoutInvalidValues(data))),
    update: (id, data) => axios.patch(`/companies/${id}`, data),
    delete: (id) => axios.delete(`/companies/${id}`),

    requests: () => ({
        index: (query = {}) => axios.get(`/model-requests${mapQueryString(query)}`),
        show: (id) => axios.get(`/model-requests/${id}`),
        accept: (id) => axios.post(`/model-requests/${id}/accept`),
        refuse: (id) => axios.post(`/model-requests/${id}/refuse`),
        update: (id, body = {}) => axios.patch(`/model-requests/${id}`, body),
        destroy: (id) => axios.delete(`/model-requests/${id}`),
    })
})