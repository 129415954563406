import { Authenticated } from '../middlewares'

export default [
    {
        path: '/blog',
        name: 'blog.index',
        component: () => import('../../views/blog/index.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/blog/create',
        name: 'blog.create',
        component: () => import('../../views/blog/manager.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/blog/create',
        name: 'blog.edit',
        component: () => import('../../views/blog/manager.vue'),
        meta: {
            middleware: Authenticated
        }
    },
    {
        path: '/blog/:id',
        name: 'blog.show',
        component: () => import(/* webpackChunkName: "users.show" */ '../../views/blog/manager.vue'),
        meta: {
            middleware: Authenticated
        },
    },
]