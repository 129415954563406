<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div class="kt-portlet__body svg_list_trainig">
                    <div class="row kt-mb-25">
                        <div class="col-12 col-sm-8">
                            <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" v-debounce:400="(search) => searchData({ search })" class="form-control" :placeholder="`${this.l.app.Search}`">
                                <span @click="searchData" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4" style="text-align: right;">
                            <sim-button align="right" @click="modal= true" style="margin-right: 27px; padding: 10px 20px;" :disabled="loading" :loading="loading">
                                {{ this.l.app.manage_categories}}
                            </sim-button>

                            <sim-button align="right" :to="{ name: 'forums.create' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                {{ this.l.app.Create}}
                            </sim-button>
                        </div>
                    </div>
                    <!-- aqui va la tabla -->
                    <div v-if="!loadings.index" class="row col-md-12 col-sm-12 kt-mb-25" style="padding: 0px 12px;margin: 0;">
                        <div class="col-md-12 col-sm-12 row"  v-for="item in pagination.data" :key="item.id">
                            <div class="col-md-12 col-sm-12 row box-forums-ln">
                                <router-link :to="`/forums/${item.id}`" class="col c-ln-title">
                                    <span class="name-f">{{item.title}}</span>
                                    <span class="u-name">{{item.user_profile.name}} {{item.user_profile.last_name}}</span>
                                </router-link>
                                <router-link :to="`/forums/${item.id}`" class="col c-ln-date">
                                    <span class="date">{{moment(item.updated_at).format("MMMM DD, YYYY hh:mm a")}}</span>
                                </router-link>
                                <router-link :to="`/forums/${item.id}`" class="col c-ln-data">
                                    <span v-if="item.views_count" class="nun">{{item.views_count}}</span>
                                    <span v-else class="nun">0</span>
                                    <span class="info">views</span>
                                </router-link>
                                <router-link :to="`/forums/${item.id}`" class="col c-ln-data">
                                    <span v-if="item.comments_count" class="nun">{{item.comments_count}}</span>
                                    <span v-else class="nun">0</span>
                                    <span class="info">replies</span>
                                </router-link>
                                <div class="col c-ln-data">
                                    <label @click="deleteEvent({id : item.id }).then(() => index(query))" title="Delete" class="btn btn-label-danger text-uppercase">
                                        <i class="material-icons">delete</i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div style="display: table; margin: 0 auto; height: 400px;">
                            <div class="lds-ripple"><div></div><div></div></div>
                        </div>
                    </div>
                    <sim-pagination align="right" :data="pagination" @change="index({ page: $event, ...query })" />
                </div>
            </div>
        </div>
        <sim-Modal :show="modal" @close="modal = false" type="a-modal view">
            <h3 slot="header">
                <b class="col-12 title-detail-transaction" style="font-size: 28px; margin-bottom: 20px;">
                    {{ this.l.app.category_list_forum}}
                </b>
            </h3>
            <category />
        </sim-Modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import category from './category.vue';

export default {
    data: () => ({
        columns: [],
        limit: 10,
        o_data:{},
        search:'',
        loading: false,
        modal: false,
        query: {
            limit: 10,
            page:1,
            with:'userProfile'
        },
    }),
    computed: {
        ...mapState({
            pagination: state => state.forums.pagination,
            loadings: state => state.forums.loadings,
            session: state => state.session.user
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    components:{
        category
    },
    methods: {
        ...mapActions({
            index: 'forums/index',
            deleteEvent: 'forums/delete'
        }),
        searchData({ search = '' } = {}){
            this.index({...this.query, search})
        },
        orderBy(data){
            if( this.search !== ''){
                this.index({type:2, search: this.search, sort_by: `${data.field},${data.order}`, page:1});
            } else{
                this.index({type:2, sort_by: `${data.field},${data.order}`, page:1});
            }
        },
        active( id ){
            if (confirm('Are you sure to activate this Events?')) {
                this.update({id: id, status: 'active'});
            } 
        },
        deactivate( id ){
            if (confirm('Are you sure to disable this Events?')) {
                this.update({id: id, status: 'inactive'});
            }
        },
        moment
    },
    async mounted() {
        await this.index(this.query);
    }
}
</script>
