<template>
  <div>
    <div class="flex flex-wrap mb-2">
      <label @click="$router.back()" class="btn text-uppercase">
        <i class="material-icons">keyboard_backspace</i>
        {{l.app.To_return}}
      </label>
    </div>
    <p v-if="loading" class="text-2xl text-center pt-16 p-8 animate-bounce">{{ l.app.loading }}</p>
    <div v-else class="flex flex-wrap">
      <div class="w-full">
        <sim-card>
          <form @submit.prevent="isUpdating ? updateBanner() : create()" >
            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
              <div class="form-group">
                <label for="name">Modulo</label>
<!--                <sim-input-->
<!--                    id="title"-->
<!--                    v-model="currentBanner.module"-->
<!--                    required-->
<!--                />-->
                <select
                    class="input-style"
                    id="type"
                    v-model="currentBanner.module"
                    :disabled="isUpdating"
                    required
                >
                  <option value="home">Home</option>
                  <option value="login">Login</option>
                  <option value="feed">Feed</option>
                  <option value="blog">Blogs</option>
                  <option value="events">Events</option>
                  <option value="jobs">Jobs</option>
                  <option value="companies">Companies</option>
                  <option value="users">Members</option>
                  <option value="D&I">D&I</option>
                  <option value="media">Media & Press</option>
                  <option value="my-completed-cpd">My completed cpd</option>
                </select>
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    class="input-style"
                    id="type"
                    v-model="currentBanner.type"
                    :disabled="isUpdating"
                    required
                >
                  <option value="image" selected>Image</option>
                  <option value="iframe">Iframe</option>
                </select>
              </div>

              <div v-if="currentBanner.type === 'image'">
                <!--              <sim-img-blog :img_url="currentBanner.image" @editImg="addImg($event, picture)" @editImgVie="addImgPV($event, picture)" />-->
                <div class="form-group">
                  <label for="imageSrc">Image</label>
                  <input
                      type="file"
                      @change="onChangeFile($event)"
                      ref="inputFile"
                      accept="image/png, image/jpeg, image/gif"
                      class="form-control add-files"
                      :required="currentBanner.type === 'image' && !isUpdating"
                      id=""
                  />
                </div>

                <div class="form-group">
                  <label for="imageSrc">Image redirection URL</label>
                  <sim-input
                      id="imageSrc"
                      v-model="currentBanner.dst_url"
                      :required="currentBanner.type === 'image'"
                  >
                  </sim-input>
                </div>
              </div>

              <div v-if="currentBanner.type === 'iframe'" class="form-group">
                <label for="iframe">iframe src</label>
                <sim-input
                    id="iframe"
                    v-model="currentBanner.iframe_src"
                    :required="currentBanner.type === 'iframe'"
                >
                </sim-input>
              </div>

<!--              <div class="form-group">-->
<!--                <label for="in_order">In order</label>-->
<!--                <sim-input-->
<!--                    id="in_order"-->
<!--                    type="Number"-->
<!--                    min="0"-->
<!--                    max="65535"-->
<!--                    v-model="currentBanner.in_order"/>-->
<!--              </div>-->

<!--              <div class="form-group">-->
<!--                <div class="checkbox" style="margin-top: 9px; position: relative">-->
<!--                  <label>-->
<!--                    <input type="checkbox" v-model="currentBanner.enabled" />-->
<!--                    <i class="input-helper"></i>-->
<!--                    Enabled-->
<!--                  </label>-->
<!--                </div>-->
<!--              </div>-->
            </div>

            <sim-button type="submit" class="mr-2"
                        :loading="isUpdating ? loadings.update : loadings.store"
                        :disabled="isUpdating ? loadings.update : loadings.store">
              <i class="material-icons">save</i>
              {{ l.app.Save_Changes }}
            </sim-button>
            <sim-button
                @click="$router.push('/banners')"
                outlined
                style="color: #e01f8b !important"
            >
              <i class="material-icons">clear</i>
              {{ l.app.Cancel }}
            </sim-button>
          </form>
        </sim-card>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Vue from "vue";

export default {
  name: 'Manage',
  data() {
    return {
      currentBanner: {
        enabled: true
      },
      picture: null,
      loading: false,
      isUpdating: null
    }
  },
  computed: {
    ...mapState({
      banner: state => state.banners.current,
      loadings: state => state.banners.loadings
    }),
    ...mapGetters({
      l: 'lang'
    })
  },
  methods: {
    ...mapActions({
      show: 'banners/show',
      store: 'banners/store',
      update: 'banners/update'
    }),
    onChangeFile(e) {
      console.log(e.target.files[0])
      this.currentBanner.image = e.target.files[0]
    },
    create() {
      this.store(this.currentBanner)
        .then(() => {
          Vue.prototype.$message.success({
            message: this.l.app.banners_messages.create,
            text: this.l.app.banners_messages.create,
            description:this.l.app.banners_messages.create,
            position: `top-right`
          })
          this.$router.push('/banners')
        }).catch((err) => {
          console.log(err.response)
        })
    },
    updateBanner() {
      this.update({ id: this.$route.params.id, ...this.currentBanner})
        .then(() => {
          Vue.prototype.$message.success({
            message: this.l.app.banners_messages.update,
            text: this.l.app.banners_messages.update,
            description: this.l.app.banners_messages.update,
            position: `top-right`
          })
          this.$router.push('/banners')
        }).catch((err) => {
        console.log(err.response)
      })
    }
  },
  created() {
    this.loading = true
    const { id } = this.$route.params;
    this.isUpdating = !!id
    if (this.isUpdating) {
      this.show(id)
        .then((res) => {
          this.currentBanner = {
            module: res.module,
            in_order: res.in_order,
            enabled: res.enabled,
            type: res.type,
            iframe_src: res.payload.iframe_src,
            dst_url: res.payload.dst_url
          }
        }).finally(() => this.loading = false)
    } else {
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>