import { Authenticated } from '../middlewares'

export default [
  // {
  //   path: '/messages',
  //   name: 'messages.index',
  //   component: () => import('../../views/messages/index.vue'),
  //   meta: {
  //     middleware: Authenticated
  //   }
  // },
  {
    path: '/messages',
    name: 'messages.index',
    component: () => import('../../views/messages/create.vue'),
    meta: {
      middleware: Authenticated
    }
  },
]