<template>

    <div>
        <div class="flex justify-end mb-4">
            <sim-button align="right" :to="{ name: 'jobs.create' }">
                <i class="material-icons">add</i> {{this.l.app.create_jobs}}
            </sim-button>
        </div>
        <sim-table
            :loading="loading"
            :columns="columns"
            :toolbar-per-page="$route.query.limit"
            @toolbar:search="searchData({ search: $event, page: 1 })"
            @toolbar:per-page="searchData({ limit: $event,page: query.page })"
            @sort-change="searchData({ order_by: `${$event.key},${$event.direction}` })"
            :source="pagination.data">

            
            <template #td:description="{ record }">
                <span v-html="record.item.description"></span>
            </template>
            <template #td:actions="{ record }">
                <div class="flex space-x-2">
                    <router-link :to="{ name: 'jobs.edit', params: { id: record.item.id } }" class="btn btn-label-warning text-uppercase" :title="l.app.Edit">
                        <i class="material-icons">edit</i>
                    </router-link>
                    <label @click="active( record.item.id )" class="btn btn-label-primary text-uppercase" :title="l.app.Active_Inactive">
                        <i class="material-icons">done_all</i>
                    </label>
                    <label @click="deactivate( record.item.id )" class="btn btn-label-primary text-uppercase" :title="l.app.unpublish">
                        <i class="material-icons">unpublished</i>
                    </label>
                    <label @click="deleteUser({ id: record.item.id, back: false })" class="btn btn-label-danger text-uppercase" :title="l.app.Remove">
                        <i class="material-icons"> delete</i>
                    </label>
                </div>
            </template>
        </sim-table>

        <sim-pagination align="right" :data="pagination" @change="searchData({ page: $event })" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
    data: () => ({
        limit: 10,
        search:'',
        query: {
            limit: 10,
            page:1
        },
    }),
    computed: {
        ...mapState({
            pagination: state => state.jobs.pagination,
            loading: state => state.jobs.loadings.index,
            session: state => state.session.user
        }),
        ...mapGetters({
            l: 'lang'
        }),
        columns() {
            return [
                { title: this.l.app.Title, key: "title" ,sortable: true},
                { title: "status", key: "status" ,sortable: true},
                { title: this.l.app.Description, slot: "description", mutate: ({ key }, { truncate }) => truncate(key, 160), titledContent: true ,sortable: true},
                { title: this.l.app.Required_skills, key: "required_skills", mutate: ({ key }, { truncate }) => truncate(key, 160), titledContent: true ,sortable: true},
                { title: this.l.app.How_to_apply, key: "how_to_apply", mutate: ({ key }, { truncate }) => truncate(key, 160), titledContent: true ,sortable: true},
                { title: this.l.app.Contact_person, key: "contact_person" ,sortable: true},
                { title: this.l.app.Contact_info, key: "contact_info" ,sortable: true},
                { title: this.l.app.Actions, "slot": "actions", "alignment": "right" },
            ]
        }
    },
    methods: {
        ...mapActions({
            fetchJobs: 'jobs/fetchJobs',
            deleteUser: 'jobs/delete',
            update: 'jobs/update'
        }),
        searchData(_query = {}) {
            
            console.log(this.query,_query)
            const query = this.$queryUtils.withoutInvalidValues({ ...this.query, ..._query })
            this.fetchJobs(query)
                .then(() => {
                    this.$router.replace({ query })
                        .catch(() => null)
                })
        },
        active( id ){
            if (confirm('Are you sure to activate this Jobs?')) {
                this.update({id: id, status: 'active'});
            } 
        },
        deactivate( id ){
            if (confirm('Are you sure to disable this Jobs?')) {
                this.update({id: id, status: 'inactive'});
            }
        }
    },
    mounted() {
        this.searchData();
    },
    created() {
        this.query = {...this.query, ...this.$route.query, query: ''}
    }
}
</script>
