/**
 * this file is an auto-importer.
 * automatically search though this directory and
 * takes every file excluding this one where there
 * are an object being exported and adds it to the
 * {modules} object to be exported
 */

const modules = {}
const files = require.context('./', true, /\.js$/i)


files.keys().forEach((path) => {
    const module = path.split('/').map(e => e.split('.')[0]).filter(e => e)[0]

    try {
        const file = files(path).default
        
        if (module !== 'index' && typeof file === 'object') {
            modules[module] = file
        }
    } catch (error) {
        console.log(`%c (Vuex Store) failed to append "${module}" module at path: ${path}`, 'color: green; font-weight: bold;')
    }
})

export default modules