<template>
    <authenticated-layout v-if="isAuthenticated" />
    <guest-layout v-else />
</template>

<script>

import { mapGetters } from 'vuex'
import AuthenticatedLayout from '@/layouts/authenticated'
import GuestLayout from '@/layouts/guest'
export default {
    components: {
        AuthenticatedLayout,
        GuestLayout
    },
    computed: mapGetters({
        isAuthenticated: 'session/isAuthenticated'
    })
};
</script>
