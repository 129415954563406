import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        event: {},
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_EVENTS: (state, pagination) => state.pagination = pagination,
        SET_EVENT: (state, event) => state.event = event,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        fetchJobs({ commit }, page = {} ) {
            commit('TOGGLE_LOADING', 'index')
            return this.$repository.jobs
                .index(page)
                .then(({ data }) => {
                    commit('SET_PAGINATED_EVENTS', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        fetchJob({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.jobs
                .show(id)
                .then(({ data }) => {
                    commit('SET_EVENT', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, body) {
            commit('TOGGLE_LOADING', 'store')
            return this.$repository.jobs
                .store(body)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el administrador con éxito`,
                        text: `Se ha creado el administrador con éxito`,
                        description: `Se ha creado el administrador con éxito`,
                        position: `top-right`
                    })

                    router.push({ name: 'events' })
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        },
        update({ commit }, body) {
            commit('TOGGLE_LOADING', 'update')
            return this.$repository.jobs
                .update(body.id, body/*{status: body.status}*/)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `The user has been updated successfully`,
                        text: `The user has been updated successfully`,
                        description: `The user has been updated successfully`,
                        position: `top-right`
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        delete({ commit, state }, { id, back = false }) {
            if (confirm('Do you want to delete?')) {
                commit('TOGGLE_LOADING', 'delete')
                return this.$repository.jobs
                    .delete(id)
                    .then(({ data }) => {
                        if (back) {
                            router.push({ name: 'events' })
                        } else {
                            const index = state.pagination.data.findIndex(administrator => administrator.id === id)
                            if (index !== -1) {
                                commit('DROP_ADMINISTRATOR', index)
                            }
                        }

                        return data
                    })
                    .finally(() => commit('TOGGLE_LOADING', 'delete'))
            }
        }
    },
    getters: {
        eventCanDo: (state, getters, rootState, rootGetters) => action => {
            return rootGetters["session/isAuthenticated"]
            && rootState.session.event.permissions.hasOwnProperty(action)
            && rootState.session.event.permissions[action]
        }
    }
}