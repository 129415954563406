import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		pagination: { data: [], current_page: 1, total: 0 },
		paginationCategory: { data: [], current_page: 1, total: 0 },
		current: {},
		loadings: {
			index: false,
			show: false,
			store: false,
			update: false,
			delete: false,
			c_index: false,
			c_store: false,
			c_update: false,
			c_delete: false
		}
	},
	mutations: {
		SET_PAGINATED_FORUMS: (state, pagination) => state.pagination = pagination,
		SET_PAGINATED_CATEGORY: (state, paginationCategory) => state.paginationCategory = paginationCategory,
		SET_SECTORS: (state, current) => state.current = current,
		TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
		DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
	},
	actions: {
		index({ commit }, page = {}) {
			commit('TOGGLE_LOADING', 'index')
			return this.$repository.forums
				.index(page)
				.then((res) => {
					commit('SET_PAGINATED_FORUMS', res.data)
					return res.data
				})
				.finally(() => commit('TOGGLE_LOADING', 'index'))
		},
		index_categories({ commit }, page = {}) {
			commit('TOGGLE_LOADING', 'c_index')
			return this.$repository.forums
				.categoriesIndex(page)
				.then((res) => {
					if(page && !page.search){
						commit('SET_PAGINATED_CATEGORY', res.data)
					}
					return res.data
				}).finally(() => commit('TOGGLE_LOADING', 'c_index'))
		},
		show({ commit }, id) {
			commit('TOGGLE_LOADING', 'show')
			return this.$repository.forums
				.show(id)
				.then(({ data }) => {
					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'show'))
		},
		store({ commit }, body) {
			commit('TOGGLE_LOADING', 'store')
			return this.$repository.forums
				.store(body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful registration`,
						text: `Successful registration`,
						description: `Successful registration`,
						position: `top-right`,
						url: `/forums`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'store'))
		},
		update({ commit }, body) {
			commit('TOGGLE_LOADING', 'update')
			return this.$repository.forums
				.update(body.id, body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful update`,
						text: `Successful update`,
						description: `Successful update`,
						position: `top-right`,
						url: `/forums`
					})

					return data
				})
				.finally(() => commit('TOGGLE_LOADING', 'update'))
		},
		delete({ dispatch, commit }, { id }) {
			if (confirm('¿Do you want to delete this?')) {
				commit('TOGGLE_LOADING', 'delete')
				return this.$repository.forums
					.delete(id)
					.then(({ data }) => {
						dispatch('fetchSectors', {})
						return data
					})
					.finally(() => commit('TOGGLE_LOADING', 'delete'))

			}
		},
		categoriesUpdate({ commit }, body) {
			commit('TOGGLE_LOADING', 'c_update')
			return this.$repository.forums
				.categoriesUpdate(body.id, body)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful update`,
						text: `Successful update`,
						description: `Successful update`,
						position: `top-right`
					})
					return data
				}).finally(() => commit('TOGGLE_LOADING', 'c_update'))
		},
		categoriesStore({ commit }, d) {
			commit('TOGGLE_LOADING', 'c_store')
			return this.$repository.forums
				.categoriesStore(d)
				.then(({ data }) => {
					Vue.prototype.$message.success({
						message: `Successful registration`,
						text: `Successful registration`,
						description: `Successful registration`,
						position: `top-right`
					})
					return data
				}).finally(() => commit('TOGGLE_LOADING', 'c_store'))
		},
		categoriesDelete({ commit, dispatch }, { id }) {
			if (confirm('¿Do you want to delete this?')) {
				commit('TOGGLE_LOADING', 'c_delete')
				return this.$repository.forums
					.categoriesDelete(id)
					.then(() => {
						dispatch('index_categories', {})
					}).finally(() => commit('TOGGLE_LOADING', 'c_delete'))
			}
		},
	},
	getters: {
	}
}