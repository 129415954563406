import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: { data: [], current_page: 1 },
        field: {},
        loadings: { index: false, show: false, store: false, update: false, delete: false }
    },
    mutations: {
        SET_PAGINATED_FIELD: (state, pagination) => state.pagination = pagination,
        SET_FIELD: (state, field) => state.field = field,
        TOGGLE_LOADING: (state, loading) => state.loadings[loading] = !state.loadings[loading],
        DROP_ADMINISTRATOR: (state, index) => state.pagination.data.splice(index, 1),
    },
    actions: {
        fetchFields({ commit }, page = {} ) {
            commit('TOGGLE_LOADING', 'index')
            this.$repository.fields
                .index(page)
                .then(({ data }) => {
                    commit('SET_PAGINATED_FIELD', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'index'))
        },
        fetchField({ commit }, id) {
            commit('TOGGLE_LOADING', 'show')
            return this.$repository.fields
                .show(id)
                .then(({ data }) => {
                    commit('SET_FIELD', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'show'))
        },
        store({ commit }, data) {
            commit('TOGGLE_LOADING', 'store')
            return this.$repository.fields
                .store(data)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Successful registration`,
                        text: `Successful registration`,
                        description: `Successful registration`,
                        position: `top-right`
                    })
                    
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'store'))
        },
        update({ commit }, new_Field) {
            commit('TOGGLE_LOADING', 'update')
            return this.$repository.fields
                .update(new_Field.id, new_Field)
                .then(({ data }) => {
                    Vue.prototype.$message.success({
                        message: `Successful update`,
                        text: `Successful update`,
                        description: `Successful update`,
                        position: `top-right`
                    })

                    return data
                })
                .finally(() => commit('TOGGLE_LOADING', 'update'))
        },
        delete({ dispatch, commit }, { id }) {
            if (confirm('¿Do you want to delete this?')) {
                commit('TOGGLE_LOADING', 'delete')
                return this.$repository.fields
                    .delete(id)
                    .then(({ data }) => {
                        dispatch('fetchFields', {})
                        // fetchFields({})
                        // .then(({ data }) => commit('SET_PAGINATED_FIELD', res.data))
                        // .catch(() => '')
                        return data
                    })
                    .finally(() => commit('TOGGLE_LOADING', 'delete'))
            }
        }
    },
    getters: {
    }
}